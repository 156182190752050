import React, { Fragment, ChangeEvent } from 'react';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { ScaleLoader } from 'react-spinners';
import { Box, Switch, Card, TextField } from '@material-ui/core';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import { ITranslation, IReferential, IReferentialConfig } from '../../../interfaces/IReferential';
import Autocomplete, { RenderInputParams } from '@material-ui/lab/Autocomplete';
import MaterialTable, { Icons, EditComponentProps, Column } from 'material-table';
import Referentials from '../../../helpers/Referentials.json';
import { withSnackbar, ProviderContext } from 'notistack';
import { connect } from 'react-redux';
import { ApplicationState, AppState } from '../../../store';
import { forwardRef } from 'react';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { Label } from 'reactstrap';
import moment from 'moment';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import { Language } from '../../../interfaces/Organization';
import * as R from 'ramda';
import { ReferentialCode } from '../../../helpers/Constants';
import CityAppReferentials from './CityAppReferentials';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddCircleTwoToneIcon {...props} ref={ref} color="primary" />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
} as Icons;

type ReferentialProps = { appState: AppState } & ProviderContext;

interface IReferentialState {
  dependentReferencials: IReferential[];
  referentials: IReferential[];
  filteredReferentials: IReferential[];
  isLoading: boolean;
  selectedReferential: IReferentialConfig | null;
  selectedDependentReferential: IReferential | null;
  dependentReferentialConfig: IReferentialConfig | null;
  languages: Language[];
  organizationTypeReferential: boolean;
}

class AppReferentials extends React.PureComponent<ReferentialProps, IReferentialState> {
  private translatorService!: ITranslatorService;
  private appReferentialService!: IReferentialService;

  static contextType = ApplicationContext;

  state = {
    referentials: [],
    isLoading: false,
    selectedReferential: null,
    languages: [] as Language[],
    dependentReferencials: [],
    dependentReferentialConfig: null,
    selectedDependentReferential: null,
    filteredReferentials: [],
    organizationTypeReferential: false
  } as IReferentialState;

  componentDidMount = () => {
    this.setState({
      languages: this.activeLanguages()
    });
  };

  onTagsChange = (event: React.ChangeEvent<any>, value: IReferentialConfig | null) => {
    if (value?.code === ReferentialCode.City) {
      this.setState(
        {
          selectedReferential: value!,
          dependentReferencials: [],
          dependentReferentialConfig: null,
          selectedDependentReferential: null
        }
      );
      return;
    }
    this.setState(
      {
        isLoading: true,
        dependentReferencials: [],
        dependentReferentialConfig: null,
        selectedDependentReferential: null,
        organizationTypeReferential: value?.code === ReferentialCode.OrganizationType ? true : false
      },
      () => {
        this.loadReferentials(value);
      }
    );
  };

  handleDependentReferencial = (newValue: IReferential | null) => {
    if (R.isNil(newValue)) {
      this.setState({
        ...this.state,
        selectedDependentReferential: newValue,
        filteredReferentials: this.state.referentials
      });
      return;
    }

    const referentials = this.state.referentials.filter((ref) => ref.dependencyId === newValue.id);
    this.setState({
      ...this.state,
      selectedDependentReferential: newValue,
      filteredReferentials: referentials
    });
  };

  loadDependentReferentials = async (dependency: string) => {
    const dependencyConfig = Referentials.referential.find(
      (r) => r.code === dependency
    ) as IReferentialConfig;
    const dependentReferentialList = await this.appReferentialService.GetFromDB(
      dependencyConfig.getUrl
    );
    return { dependentReferentialList, dependencyConfig };
  };

  loadReferentials = async (value: IReferentialConfig | null) => {
    let referentialList: IReferential[] = [];
    let dependentReferencials: IReferential[] = [];
    let dependentReferentialConfig: IReferentialConfig | null = null;
    if (value !== null && value.getUrl !== '') {
      try {
        referentialList = await this.appReferentialService.GetFromDB(value.getUrl);

        if (!R.isNil(value.dependency)) {
          const { dependentReferentialList, dependencyConfig } =
            await this.loadDependentReferentials(value.dependency);
          dependentReferencials = dependentReferentialList.filter(x => x.isActive === true);
          dependentReferentialConfig = dependencyConfig;

          this.setState({
            filteredReferentials: referentialList.filter(
              (ref) =>
                ref.dependencyId === this.state.selectedDependentReferential?.id ??
                dependentReferencials[0].id
            ),
            dependentReferencials: dependentReferencials,
            dependentReferentialConfig: dependentReferentialConfig,
            selectedDependentReferential:
              this.state.selectedDependentReferential ?? dependentReferencials[0]
          });
        }

        referentialList.forEach((refItem) => {
          this.state.languages.forEach((lang) => {
            const translation = refItem.translations.find((item) => item.language === lang.code);
            if (R.isNil(translation)) {
              refItem.translations.push({
                id: 0,
                name: '',
                language: lang.code,
                referentialTypeId: refItem.id
              } as ITranslation);
            }
          });
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }

    console.log(this.state);
    console.log(this.state.selectedDependentReferential ?? dependentReferencials[0]);
    console.log(referentialList.filter(
      (ref) =>
        ref.dependencyId === this.state.selectedDependentReferential?.id ??
        dependentReferencials[0].id
    ));
    this.setState({
      referentials: referentialList,
      isLoading: false,
      selectedReferential: value!,
      selectedDependentReferential: this.state.selectedDependentReferential ?? dependentReferencials[0],
      filteredReferentials: referentialList.filter(
        (ref) =>
          ref.dependencyId === this.state.selectedDependentReferential?.id ??
          dependentReferencials[0].id
      )
    });
  };

  handleIsActiveChange = async (event: ChangeEvent<HTMLInputElement>, rowData: any) => {
    const id = rowData.id;
    const newReferentials = [...this.state.filteredReferentials];
    const updatedReferential = newReferentials.find((ref) => ref.id === id);
    if (updatedReferential !== undefined) {
      updatedReferential.isActive = event.target.checked;

      try {
        await this.appReferentialService.Update(
          updatedReferential,
          this.state.selectedReferential!.updateUrl
        );
        this.setState({
          referentials: newReferentials
        });
        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };

  activeLanguages = () => {
    const activeLanguages =
      this.props.appState.appUser!.organization!.gtOrganization!.languages!.filter((language) => {
        if (language.isActive === true) {
          return language;
        }
      });
    activeLanguages.forEach((language) => {
      language.displayName =
        language.displayName.charAt(0).toUpperCase() + language.displayName.slice(1);
    });
    return activeLanguages;
  };

  handleLocListChange = (event: any, gridProps: any, code: string | null) => {
    let newLocList = [] as ITranslation[];
    if (gridProps.rowData.translations) {
      newLocList = gridProps.rowData.translations.map((loc: any) => {
        if (loc.language === code) {
          loc.name = event.target.value;
        }
        return loc;
      });
    }

    gridProps.onRowDataChange({
      ...gridProps.rowData,
      translations: newLocList
    });
  };

  getColumns = () => {
    if (this.state.organizationTypeReferential) {
      return [
        {
          field: 'code',
          title: this.translatorService.Tranlate('LIST_OF_VALUES_COD_HEADER', 'Cod'),
          searchable: true
          // initialEditValue: Guid.create().toString()
        } as Column<any>,
        {
          field: 'translations',
          title: this.translatorService.Tranlate('LIST_OF_VALUES_NAME_HEADER', 'Nume'),
          searchable: true,
          render: (rowData: any) => {
            return rowData.translations.map((val: ITranslation, index: number) => {
              const language = this.state.languages.find((language: any) => {
                return language.code === val.language;
              });
              return language ? (
                <div key={index} className="pt-2">
                  <TextField
                    id="standard-basic"
                    label={language.displayName}
                    fullWidth
                    value={val.name}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true
                    }}
                    size="small"
                  ></TextField>{' '}
                </div>
              ) : null;
            });
          },
          customFilterAndSearch: (value, rowData) => {
            let matchFilter = false;
            rowData.translations.map((val: any) => {
              const findMatch = val.name.toLowerCase().indexOf(value.toLowerCase()) != -1;
              if (findMatch) {
                matchFilter = true;
              }
            });
            return matchFilter;
          },
          editComponent: (props: any) => {
            if (!R.isNil(props.value)) {
              return props.value.map((val: ITranslation, index: number) => {
                const language = this.state.languages.find(
                  (language: any) => language.code === val.language
                );

                return language ? (
                  <div key={index} className="pt-2">
                    <TextField
                      id="standard-basic"
                      size="small"
                      name={'name_' + index}
                      fullWidth
                      value={val.name}
                      label={language.displayName}
                      onChange={(e) => this.handleLocListChange(e, props, val.language)}
                    ></TextField>
                  </div>
                ) : null;
              });
            } else {
              //add
              const newLocList = [] as ITranslation[];
              for (let i = 0; i < this.state.languages!.length; i++) {
                const locListItem = {} as ITranslation;
                locListItem.language = this.state.languages[i].code;
                locListItem.name = '';
                newLocList.push(locListItem);
              }

              props.onChange(newLocList);

              return null;
            }
          }
        } as Column<any>,
        {
          field: 'dateModification',
          title: this.translatorService.Tranlate('LIST_OF_VALUES_DATE_HEADER', 'Data'),
          defaultSort: 'desc',
          searchable: true,
          render: (rowData: any) => {
            return moment
              .utc(rowData.dateModification)
              .local()
              .format(this.props.appState.longDateFormat);
          },
          editComponent: (props: EditComponentProps<any>) => {
            return <Label>{moment(new Date()).format(this.props.appState.longDateFormat)}</Label>;
          }
        } as Column<any>,
        {
          field: 'takeOrganizationTariff',
          title: this.translatorService.Tranlate('LIST_OF_VALUES_TAKE_ORGANIZATION_TARIFF_HEADER', 'Tarif Organizatie'),
          render: (rowData: any) => {
            return (
              <Switch
                checked={rowData.takeOrganizationTariff}
                value={rowData.takeOrganizationTariff}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            );
          },

          editComponent: (props: EditComponentProps<any>) => {
            return (
              <Switch
                checked={props.value || false}
                value={props.value || false}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                onChange={(e) => props.onChange(e.target.checked)}
              />
            );
          }
        },
        {
          field: 'hideAlternative',
          title: this.translatorService.Tranlate('LIST_OF_VALUES_HIDE_ALTERNATIVE_HEADER', 'Ascunde Alternative'),
          render: (rowData: any) => {
            return (
              <Switch
                checked={rowData.hideAlternative}
                value={rowData.hideAlternative}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            );
          },

          editComponent: (props: EditComponentProps<any>) => {
            return (
              <Switch
                checked={props.value || false}
                value={props.value || false}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                onChange={(e) => props.onChange(e.target.checked)}
              />
            );
          }
        },
        {
          field: 'hideInsurerCalculation',
          title: this.translatorService.Tranlate('LIST_OF_VALUES_HIDE_INSURER_TARIFF_HEADER', 'Ascunde calculatie OE cu tarife asigurator'),
          render: (rowData: any) => {
            return (
              <Switch
                checked={rowData.hideInsurerCalculation}
                value={rowData.hideInsurerCalculation}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            );
          },

          editComponent: (props: EditComponentProps<any>) => {
            return (
              <Switch
                checked={props.value || false}
                value={props.value || false}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                onChange={(e) => props.onChange(e.target.checked)}
              />
            );
          }
        },
        {
          field: 'hideOrderPrices',
          title: this.translatorService.Tranlate('LIST_OF_VALUES_HIDE_ORDER_PRICES_HEADER', 'Ascunde Preturi Comanda'),
          render: (rowData: any) => {
            return (
              <Switch
                checked={rowData.hideOrderPrices}
                value={rowData.hideOrderPrices}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            );
          },

          editComponent: (props: EditComponentProps<any>) => {
            return (
              <Switch
                checked={props.value || false}
                value={props.value || false}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                onChange={(e) => props.onChange(e.target.checked)}
              />
            );
          }
        },
        {
          field: 'hideOtherAttachments',
          title: this.translatorService.Tranlate('LIST_OF_VALUES_HIDE_OTHER_ATTACHMENTS_HEADER', 'Ascunde Altele'),
          render: (rowData: any) => {
            return (
              <Switch
                checked={rowData.hideOtherAttachments}
                value={rowData.hideOtherAttachments}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            );
          },

          editComponent: (props: EditComponentProps<any>) => {
            return (
              <Switch
                checked={props.value || false}
                value={props.value || false}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                onChange={(e) => props.onChange(e.target.checked)}
              />
            );
          }
        },
        {
          field: 'isActive',
          title: this.translatorService.Tranlate('LIST_OF_VALUES_ISACTIVE_HEADER', 'Stare'),
          render: (rowData: any) => {
            return (
              <Switch
                checked={rowData.isActive}
                onChange={(e) => this.handleIsActiveChange(e, rowData)}
                value={rowData.isActive}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            );
          },

          editComponent: (props: EditComponentProps<any>) => {
            return (
              <Switch
                checked={props.value || false}
                value={props.value || false}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                onChange={(e) => props.onChange(e.target.checked)}
              />
            );
          }
        }    
      ];
    } else {
      return [
        {
          field: 'code',
          title: this.translatorService.Tranlate('LIST_OF_VALUES_COD_HEADER', 'Cod'),
          searchable: true
          // initialEditValue: Guid.create().toString()
        } as Column<any>,
        {
          field: 'translations',
          title: this.translatorService.Tranlate('LIST_OF_VALUES_NAME_HEADER', 'Nume'),
          searchable: true,
          render: (rowData: any) => {
            return rowData.translations.map((val: ITranslation, index: number) => {
              const language = this.state.languages.find((language: any) => {
                return language.code === val.language;
              });
              return language ? (
                <div key={index} className="pt-2">
                  <TextField
                    id="standard-basic"
                    label={language.displayName}
                    fullWidth
                    value={val.name}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true
                    }}
                    size="small"
                  ></TextField>{' '}
                </div>
              ) : null;
            });
          },
          customFilterAndSearch: (value, rowData) => {
            let matchFilter = false;
            rowData.translations.map((val: any) => {
              const findMatch = val.name.toLowerCase().indexOf(value.toLowerCase()) != -1;
              if (findMatch) {
                matchFilter = true;
              }
            });
            return matchFilter;
          },
          editComponent: (props: any) => {
            if (!R.isNil(props.value)) {
              return props.value.map((val: ITranslation, index: number) => {
                const language = this.state.languages.find(
                  (language: any) => language.code === val.language
                );

                return language ? (
                  <div key={index} className="pt-2">
                    <TextField
                      id="standard-basic"
                      size="small"
                      name={'name_' + index}
                      fullWidth
                      value={val.name}
                      label={language.displayName}
                      onChange={(e) => this.handleLocListChange(e, props, val.language)}
                    ></TextField>
                  </div>
                ) : null;
              });
            } else {
              //add
              const newLocList = [] as ITranslation[];
              for (let i = 0; i < this.state.languages!.length; i++) {
                const locListItem = {} as ITranslation;
                locListItem.language = this.state.languages[i].code;
                locListItem.name = '';
                newLocList.push(locListItem);
              }

              props.onChange(newLocList);

              return null;
            }
          }
        } as Column<any>,
        {
          field: 'dateModification',
          title: this.translatorService.Tranlate('LIST_OF_VALUES_DATE_HEADER', 'Data'),
          defaultSort: 'desc',
          searchable: true,
          render: (rowData: any) => {
            return moment
              .utc(rowData.dateModification)
              .local()
              .format(this.props.appState.longDateFormat);
          },
          editComponent: (props: EditComponentProps<any>) => {
            return <Label>{moment(new Date()).format(this.props.appState.longDateFormat)}</Label>;
          }
        } as Column<any>,
        {
          field: 'isActive',
          title: this.translatorService.Tranlate('LIST_OF_VALUES_ISACTIVE_HEADER', 'Stare'),
          render: (rowData: any) => {
            return (
              <Switch
                checked={rowData.isActive}
                onChange={(e) => this.handleIsActiveChange(e, rowData)}
                value={rowData.isActive}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            );
          },

          editComponent: (props: EditComponentProps<any>) => {
            return (
              <Switch
                checked={props.value || false}
                value={props.value || false}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                onChange={(e) => props.onChange(e.target.checked)}
              />
            );
          }
        }
      ];
    }
  };
  public getLocalization = () => {
    return {
      header: { actions: '' },
      toolbar: {
        searchPlaceholder: this.translatorService.Tranlate('SEARCH', 'Cauta'),
        searchTooltip: this.translatorService.Tranlate('SEARCH', 'Cauta')
      },
      body: {
        editTooltip: this.translatorService.Tranlate('EDIT', 'Editeaza'),
        addTooltip: this.translatorService.Tranlate('Add', 'Adauga'),
        emptyDataSourceMessage: this.translatorService.Tranlate(
          'NO_RECORDS_TO_DISPLAY',
          'Nu exista date de afisat'
        ),
        editRow: {
          cancelTooltip: this.translatorService.Tranlate('CANCEL', 'Anuleaza'),
          saveTooltip: this.translatorService.Tranlate('SAVE', 'Salveaza')
        }
      },
      pagination: {
        labelRowsSelect: this.translatorService.Tranlate('ROWS', 'inregistrari'),
        firstTooltip: this.translatorService.Tranlate('FIRST_PAGE', 'Prima pagina'),
        previousTooltip: this.translatorService.Tranlate('PREVIOUS_PAGE', 'Pagina precedenta'),
        nextTooltip: this.translatorService.Tranlate('NEXT_PAGE', 'Pagina urmatoare'),
        lastTooltip: this.translatorService.Tranlate('LAST_PAGE', 'Ultima pagina'),
        labelDisplayedRows:
          '{from}-{to} ' + this.translatorService.Tranlate('OF', 'din') + ' {count}'
      }
    };
  };
  public renderTypeList = () => {
    const ListsOFValueTypes = Referentials.referential;

    return (
      <Fragment>
        <Card className="card-box p-4 mb-4">
          <div className="d-flex justify-content-between w-100">
            <Autocomplete
              id="combo-box-demo"
              options={ListsOFValueTypes}
              getOptionLabel={(option) => option.name}
              style={{ width: '100%' }}
              onChange={this.onTagsChange}
              renderInput={(params: RenderInputParams) => (
                <TextField
                  fullWidth
                  {...params}
                  label={this.translatorService.Tranlate(
                    'LIST_OF_VALUES_TYPE',
                    'Tip lista de valori'
                  )}
                  variant="outlined"
                />
              )}
            />
          </div>
        </Card>

        {this.state.dependentReferentialConfig && (
          <Card className="card-box p-4 mb-4">
            <Autocomplete
              id="dependencies"
              options={this.state.dependentReferencials}
              disableClearable
              value={this.state.selectedDependentReferential}
              onChange={(e: any, newValue: IReferential | null) =>
                this.handleDependentReferencial(newValue)
              }
              getOptionLabel={(option) => option.displayName || ''}
              renderInput={(params: RenderInputParams) => (
                <TextField
                  fullWidth
                  {...params}
                  // inputProps={{ ...params.inputProps, readOnly: true }}
                  label={this.translatorService.Tranlate(
                    'LIST_OF_DEPENDENT_VALUES',
                    'Tip lista de valori'
                  )}
                  variant="outlined"
                />
              )}
            />
          </Card>
        )}
      </Fragment>
    );
  };

  onRowUpdate = (newData: IReferential, oldData?: IReferential): Promise<void> =>
    new Promise(async (resolve, reject) => {
      if (!this.validateRef(newData)) {
        reject();
        return;
      }

      if (oldData) {
        this.setState((prevState: IReferentialState) => {
          const newReferentials = !R.isEmpty(this.state.filteredReferentials)
            ? [...this.state.filteredReferentials]
            : [...this.state.referentials];
          newReferentials[newReferentials.indexOf(oldData)] = newData;

          return { ...prevState, referentials: newReferentials };
        });

        try {
          await this.appReferentialService.Update(
            newData,
            this.state.selectedReferential!.updateUrl
          );
          await this.loadReferentials(this.state.selectedReferential);
          resolve();
          this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
            variant: 'success'
          });
        } catch (error) {
          this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
            variant: 'error'
          });
          reject();
        }
      }
    });

  validateRef = (referential: IReferential): boolean => {
    if (referential.code === '' || referential.code === undefined || referential.code === null) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate('LIST_OF_VALUES_ERROR_CODE_EMPTY', 'Eroare'),
        { variant: 'error' }
      );
      return false;
    }
    const referentials = !R.isEmpty(this.state.filteredReferentials)
      ? this.state.filteredReferentials
      : this.state.referentials;
    let resultRef = referentials.find((item) => item.code === referential.code);
    if (referential.id !== undefined) {
      resultRef = referentials.find(
        (item) => item.code === referential.code && item.id !== referential.id
      );
    }
    if (resultRef !== undefined) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate('LIST_OF_VALUES_ERROR_CODE_UNIQUE', 'Eroare'),
        { variant: 'error' }
      );
      return false;
    }
    if (referential.translations === undefined) {
      this.props.enqueueSnackbar(
        this.translatorService.Tranlate('LIST_OF_VALUES_ERROR_NAME', 'Eroare'),
        { variant: 'error' }
      );
      return false;
    }
    for (const item of referential.translations) {
      if (item.name === '' || item.name === undefined || item.name === null) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate('LIST_OF_VALUES_ERROR_NAME', 'Eroare'),
          { variant: 'error' }
        );
        return false;
      }
    }

    return true;
  };

  onRowAdd = (newData: IReferential): Promise<void> =>
    new Promise(async (resolve, reject) => {
      if (!this.validateRef(newData)) {
        reject();
        return;
      }
      try {
        newData.name = newData.translations[0].name;
        newData.gtId = this.props.appState.appUser!.gtId!;
        if (!R.isNil(this.state.selectedDependentReferential)) {
          newData.dependencyId = this.state.selectedDependentReferential.id;
        }
        await this.appReferentialService.Add(newData, this.state.selectedReferential!.addUrl);
        await this.loadReferentials(this.state.selectedReferential);
        resolve();
        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
        reject();
      }
    });

  public renderTable = () => {
    if (this.state.selectedReferential === null) {
      return;
    }

    return (
      <MaterialTable
        icons={tableIcons}
        title={this.translatorService.Tranlate('LIST_OF_VALUES', 'Lista de valori')}
        columns={this.getColumns()}
        data={
          this.state.filteredReferentials
        }
        options={{
          actionsColumnIndex: -1,
          addRowPosition: 'first'
        }}
        localization={this.getLocalization()}
        editable={{
          onRowAdd: (newData: IReferential) => this.onRowAdd(newData),
          onRowUpdate: (newData: any, oldData?: any) => this.onRowUpdate(newData, oldData)
        }}
      />
    );
  };
  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.appReferentialService = (this.context as AppContext).referentialService;

    return (
      <Box m={1} p={1}>
        {this.renderTypeList()}
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? 
          (this.state.selectedReferential !== null && this.state.selectedReferential.code === ReferentialCode.City ? (
            <CityAppReferentials></CityAppReferentials>
          )
          : this.renderTable()
          )  : null}
      </Box>
    );
  }
}

export default connect((state: ApplicationState) => ({ appState: state.app }))(
  withSnackbar(AppReferentials as any)
);
