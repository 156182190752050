import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { ScaleLoader } from 'react-spinners';
import _ from 'lodash';
import { ApplicationState, CaseSettingsActionCreators, VehicleActionCreators } from '../../store';
import { MavoVehicleActionCreators } from '../../store/actions/MavoVehicle';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import MomentUtils from '@date-io/moment';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { MavoVehicleDetailsProps } from '../cases/vehicleDetails/MavoVehicleDetailsProps';
import { IMavoVehicleOptionsContainerProps } from '../cases/vehicleDetails/IMavoVehicleOptionsContainerProps';
import MaterialTable, { Column } from 'material-table';
import { MaterialTableIcons, getLocalization } from '../_shared/TableSettings';
import { Paper } from '@material-ui/core';
import { MavoVehicleOption } from '../../interfaces/Vehicle';
import { IVehicleService } from '../../services/Interfaces/IVehicleService';
import { MavoVehicleStatusCodeEnum, disabledMavoVehicleStatuses, disabledNewMavoVehicleStatuses } from '../../helpers/Constants';

interface IMavoVehicleOptionsContainerState {
  isLoading: boolean;
  executing: boolean;
  isOpen: boolean;
  dialogVisible: boolean;
}


class LocalizedUtils extends MomentUtils {
  // dateFormat = "DD MM YYYY";
}
class MavoVehicleOptionsContainer extends React.PureComponent<
  IMavoVehicleOptionsContainerProps,
  IMavoVehicleOptionsContainerState
> {
  private translatorService!: ITranslatorService;
  private vehicleService!: IVehicleService;

  static contextType = ApplicationContext;
  static displayName = 'MavoVehicleOptionsContainer';

  state = {
    isLoading: false,
    executing: false,
    isValuationOpen: false,
    isOpen: false,
    dialogVisible: false
  } as IMavoVehicleOptionsContainerState;

  public componentDidMount() {
    this.setState(
      {
        isLoading: false
      }
    );
  }

  getMavoOptionsColumns = () => {
    return [
      {
        field: 'name',
        title: this.translatorService.Tranlate('CAR_DETAILS_CAR_STANDARD_OPTION', 'Nume'),
        searchable: true
      } as Column<any>
    ];
  };

  standardOnRowAdd = (newData: MavoVehicleOption): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      try {
        if (this.props.mavoVehicleState.mavoVehicle.id === 0) {
          this.props.setMavoVehicleObject({
            ...this.props.mavoVehicleState.mavoVehicle,
            vehicleOptions: [
              ...this.props.mavoVehicleState.mavoVehicle.vehicleOptions || [],
              {
                id: 0,
                vehicleId: this.props.mavoVehicleState.mavoVehicle.id,
                name: newData.name
              }
            ]
          });
        } else {
          newData.vehicleId = this.props.mavoVehicleState.mavoVehicle.id;
          const newOption = await this.vehicleService.AddMavoVehicleOption(newData);
          this.props.setMavoVehicleObject({
            ...this.props.mavoVehicleState.mavoVehicle,
            vehicleOptions: [
              ...this.props.mavoVehicleState.mavoVehicle.vehicleOptions || [],
              newOption
            ]
          });
        }

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
        resolve();
      } catch (error) {
        console.log(error);
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
        reject();
      }
    });
  };

  standardOnRowUpdate = (newData: any, oldData?: any): Promise<void> =>
    new Promise(async (resolve, reject) => {
      if (oldData) {
        try {
          if (this.props.mavoVehicleState.mavoVehicle.id === 0) {
            setTimeout(() => {
              const dataUpdate = [...this.props.mavoVehicleState.mavoVehicle.vehicleOptions];
              const index = oldData.tableData.id;
              dataUpdate[index] = newData;
              this.props.setMavoVehicleObject({
                ...this.props.mavoVehicleState.mavoVehicle,
                vehicleOptions: [...dataUpdate]
              });

              this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
                variant: 'success'
              });
              resolve();
            }, 200);
          } else {
            const id = oldData.id;
            const mavoVehicleOptions = [...this.props.mavoVehicleState.mavoVehicle.vehicleOptions];

            await this.vehicleService.UpdateMavoVehicleOption(newData);

            const index = mavoVehicleOptions.findIndex((item) => item.id === id);
            mavoVehicleOptions[index] = newData;
            this.props.setMavoVehicleObject({
              ...this.props.mavoVehicleState.mavoVehicle,
              vehicleOptions: [...mavoVehicleOptions]
            });

            this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
              variant: 'success'
            });
            resolve();
          }
        } catch (error) {
          this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
            variant: 'error'
          });
          reject();
        }
      }
  });

  standardOnRowDelete = (oldData: any): Promise<void> =>
    new Promise(async (resolve, reject) => {
      if (oldData) {
        try {
          if (this.props.mavoVehicleState.mavoVehicle.id === 0){
            setTimeout(() => {
              const dataDelete = [...this.props.mavoVehicleState.mavoVehicle.vehicleOptions];
              const index = oldData.tableData.id;
              dataDelete.splice(index, 1);
              this.props.setMavoVehicleObject({
                ...this.props.mavoVehicleState.mavoVehicle,
                vehicleOptions: [...dataDelete]
              });
              this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
                variant: 'success'
              });
              resolve();
            }, 200);
          } else {
            const id = oldData.id;
            const mavoVehicleOptions = [...this.props.mavoVehicleState.mavoVehicle.vehicleOptions];

            await this.vehicleService.RemoveMavoVehicleOption(oldData.id);

            const index = mavoVehicleOptions.findIndex((item) => item.id === id);
            mavoVehicleOptions.splice(index, 1);
            this.props.setMavoVehicleObject({
              ...this.props.mavoVehicleState.mavoVehicle,
              vehicleOptions: [...mavoVehicleOptions]
            });

            this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
              variant: 'success'
            });
            resolve();
          }
        } catch (error) {
          this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
            variant: 'error'
          });
          reject();
        }
      }
  });


  isDisabled = (): boolean => {
    //1.2	Actualizare/adaugare
    if (this.props.mavoVehicleState.mavoVehicle.id === 0) {
      //Nu pot fi modificate informatii vehicul din tab-urile «Informatii generale», «Stare vehicul», 
      //«Despre vehicul (Dotari)» si «Date STOC» daca vehiculul este in una din starile : «Licitatie», «Comanda», «Aviz», «Factura»
      return  disabledNewMavoVehicleStatuses.includes(this.props.mavoVehicleState.mavoVehicle.currentStatus!.status!.code ?? '') ? true : false;
    } else  {
      //daca vehiculul este in stare “Licitatie”, se pot actualiza doar informatiile «Stare vehicul», «Despre vehicul (Dotari)» si «Poze» 
      if (this.props.mavoVehicleState.mavoVehicle.currentStatus!.status!.code === MavoVehicleStatusCodeEnum.LICITATIE.toString()) {
        return false;
      }
      //daca vehiculul este in orice alta stare («Comanda», «Aviz», «Factura»), nu se pot actualiza nici un fel de informatii referitoare la acest vehicul
      return  disabledMavoVehicleStatuses.includes(this.props.mavoVehicleState.mavoVehicle.currentStatus!.status!.code ?? '') ? true : false;
    }
  };
  
  public renderMavoVehicleOptionsTable = () => {
    return (
      <MaterialTable
        icons={MaterialTableIcons}
        title={''}
        columns={this.getMavoOptionsColumns()}
        data={this.props.mavoVehicleState.mavoVehicle.vehicleOptions || []}
        options={{
          actionsColumnIndex: -1,
          addRowPosition: 'first'
        }}
        localization={getLocalization(this.translatorService)}
        editable={{
          onRowAdd: this.isDisabled() || this.props.disabled ? undefined
            : (newData: any) => this.standardOnRowAdd(newData),
          onRowUpdate: (newData: any, oldData?: any) => this.standardOnRowUpdate(newData, oldData),
          onRowDelete: (oldData: any) => this.standardOnRowDelete(oldData),
          isEditHidden: (rowData: any) => this.isDisabled() || this.props.disabled,
          isDeleteHidden: (rowData: any) => this.isDisabled() || this.props.disabled
        }}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />
        }}
      />
    );
  };


  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.vehicleService = (this.context as AppContext).vehicleService;
    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        <div className="standard-car-options">
          {!this.state.isLoading ? this.renderMavoVehicleOptionsTable() : null}
          </div>
      </Fragment>
    );
  }
}

export default connect(
  (
    state: ApplicationState,
    ownProps: MavoVehicleDetailsProps
  ) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app,
    vehicleState: state.vehicle,
    mavoVehicle: state.mavoVehicle,
    ...ownProps
  }),
  { ...CaseSettingsActionCreators, ...VehicleActionCreators, ...MavoVehicleActionCreators }
)(withSnackbar(MavoVehicleOptionsContainer as any));
