import { AppContext } from '../../context/Contexts';
import { IReferential } from '../../interfaces/IReferential';
import { MavoVehicle, MavoVehicleStatusHistory, Vehicle, VehicleStatusHistory } from '../../interfaces/Vehicle';
import { AppThunkAction } from '.././';
import * as ActionTypes from './ActionTypes';

interface SetMavoVehicleAction {
  type: typeof ActionTypes.MAVO_VEHICLE_SET_VEHICLE;
  mavoVehicle: MavoVehicle | null;
}
interface SetMavoVehicleStatusAction {
  type: typeof ActionTypes.MAVO_VEHICLE_SET_VEHICLE_STATUS;
  status: MavoVehicleStatusHistory;
}

export type MavoVehicleAction = SetMavoVehicleAction | SetMavoVehicleStatusAction;

export const MavoVehicleActionCreators = {
  setMavoVehicle:
    (vehicleId: number): AppThunkAction<MavoVehicleAction> =>
    async (dispatch, getState, context) => {
      const vehicleService = (context as AppContext).vehicleService;

      const vehicle = await vehicleService.GetMavoVehicle(vehicleId);

      dispatch({ type: ActionTypes.MAVO_VEHICLE_SET_VEHICLE, mavoVehicle: vehicle as unknown as MavoVehicle });
    },

  setMavoVehicleObject:
    (vehicle: MavoVehicle | null): AppThunkAction<MavoVehicleAction> =>
    async (dispatch, getState, context) => {
      dispatch({ type: ActionTypes.MAVO_VEHICLE_SET_VEHICLE, mavoVehicle: vehicle as unknown as MavoVehicle });
    },

  changeMavoVehicleStatus:
    (statusHis: MavoVehicleStatusHistory): AppThunkAction<MavoVehicleAction> =>
    async (dispatch, getState, context) => {
      dispatch({ type: ActionTypes.MAVO_VEHICLE_SET_VEHICLE_STATUS, status: statusHis });
    }
};
