import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ApplicationState, CaseSettingsActionCreators } from '../../../store';
import { withSnackbar } from 'notistack';
import {
  Button,
  Grid,
  FormControlLabel,
  Switch,
  InputAdornment,
  IconButton,
  Paper,
  Dialog,
  DialogContent,
  Modal,
  DialogActions,
  DialogContentText,
  Divider,
  Box
} from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../../context/Contexts';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { ScaleLoader } from 'react-spinners';
import { WorkflowSectionStepForm } from '../../../interfaces/Workflow';
import { ICaseService } from '../../../services/Interfaces/ICaseService';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import {
  ParametreCode,
  VehicleAdvertOperationTypes,
  RefConstants,
  CurrencyCode,
  ReportTypeCode,
  Constants,
  AdvertOptionCodeEnum,
  VehicleAttachmentTypeCodeOrder,
  excludedAdvertisers
} from '../../../helpers/Constants';
import { AppUser } from '../../../interfaces/AppUser';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IReferential } from '../../../interfaces/IReferential';
import { FormHelpers } from '../../../helpers/forms/FormHelpers';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { IVehicleService } from '../../../services/Interfaces/IVehicleService';
import {
  AddDetailsPublishRcr,
  PublishAdvertRcr,
  VehicleAdvert,
  VehicleOption
} from '../../../interfaces/Vehicle';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import * as R from 'ramda';
import { FormCode } from '../../../helpers/forms/FormCode';
import {
  ICaseDetailsCaseTabVehicleAdvertState,
  ICaseVehicleAge
} from './ICaseDetailsCaseTabVehicleAdvertState';
import { ExternalCaseDetailsCaseTabVwehicleAdvertProps } from './ExternalCaseDetailsCaseTabVwehicleAdvertProps';
import { ICaseDetailsCaseTabVehicleAdvertProps } from './ICaseDetailsCaseTabVehicleAdvertProps';
import { IAppUserService } from '../../../services/Interfaces/IAppUserService';
import { Utils } from '../../../helpers/Utils';
import { Clear as ClearIcon } from '@material-ui/icons';
import { ReportParameter } from '../../../interfaces/Reports';
import FileSaver from 'file-saver';
import { IReportsService } from '../../../services/Interfaces/IReportsService';
import MaterialTable, { Column } from 'material-table';
import {
  getAdvertLocalization,
  getLocalization,
  MaterialTableIcons
} from '../../_shared/TableSettings';
import _ from 'lodash';
import { CaseAttachmentDto } from '../../../interfaces/Case';

const minNumberOfCharactersAdvertDetails = 35;
const maxNumberOfCharactersAdvertDetails = 6000;

class CaseDetailsCaseTabVehicleAdvertComponent extends React.PureComponent<
  ICaseDetailsCaseTabVehicleAdvertProps,
  ICaseDetailsCaseTabVehicleAdvertState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private vehicleService!: IVehicleService;
  private appReferentialService!: IReferentialService;
  private appUserService!: IAppUserService;
  private reportService!: IReportsService;

  static contextType = ApplicationContext;

  state = {
    isLoading: true,
    hasRights: false,
    executing: false,
    isUserHidden: false,
    advertiserOperations: [] as any[],
    valuationPrice: 0,
    vatValue: 0,
    vehicleAdvertHistory: [],
    vehicleAdvertData: {
      id: 0,
      caseId: this.props.caseSettingsState.case!.id,
      vehicleId: this.props.caseSettingsState.case?.vehicleId,
      price: 0,
      priceWithVat: 0,
      hasInvoice: false,
      leasingConcession: false,
      individual: true,
      privateBusiness: false,
      authorizedDealer: false,
      hasFinanceEligibility: false,
      manufacturerWarrantyDate: null,
      isRegistered: false,
      isFirstOwner: false,
      hasAccident: false,
      hasServiceBook: false,
      hasParticlesFilter: false,
      hasStandardImages: true,
      hasOptionalImages: false,
      vehicleAdvertOptions: [] as any,
      currency: this.props.caseSettingsState.case!.caseParameters.find(
        (p) => p.parameter!.code === ParametreCode.CURRENCY
      )!.value
    } as VehicleAdvert,
    makeTypes: [] as IReferential[],
    modelTypes: [] as IReferential[],
    fuelTypes: [] as IReferential[],
    transmissionTypes: [] as IReferential[],
    gearBoxTypes: [] as IReferential[],
    pollutionNormTypes: [] as IReferential[],
    carBodyTypes: [] as IReferential[],
    regionsTypes: [] as IReferential[],
    originCountriesTypes: [] as IReferential[],
    citiesTypes: [] as IReferential[],
    districtsTypes: [] as IReferential[],
    paintTypes: [] as IReferential[],
    advertisers: [] as IReferential[],
    vehicleOptions: [] as VehicleOption[],
    colorTypes: [] as IReferential[],
    isOpen: false,
    dialogVisible: false,
    guaranteeTypes: [] as IReferential[]
  } as ICaseDetailsCaseTabVehicleAdvertState;

  inputTextValidator = React.createRef<TextValidator>();

  public componentDidMount() {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (R.isNil(caseSettings) || Number.isNaN(caseId)) {
      return;
    }

    // let caseParametersList = this.props.caseSettingsState.case!.caseParameters;
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadCaseVehicleAdvert();
      }
    );

    ValidatorForm.addValidationRule('minLength', (value) => {
      if (!value) {
        return true;
      }

      if (value.length >= minNumberOfCharactersAdvertDetails) {
        return true;
      }

      return false;
    });

    ValidatorForm.addValidationRule('maxLength', (value) => {
      if (!value) {
        return true;
      }

      if (value.length <= maxNumberOfCharactersAdvertDetails) {
        return true;
      }

      return false;
    });
  }

  loadCaseVehicleAdvert = async () => {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (R.isNil(caseSettings) || Number.isNaN(caseId)) {
      return;
    }
    if (R.isNil(this.props.vehicleState.vehicle)) {
      this.setState({ isLoading: false });
      return;
    }

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      this.props.caseSettingsState.case!.caseStatus.caseStatusId
    );

    // const guaranteeTypeRef = Referentials.find(item => item.code === ReferentialCode.GuaranteeType);
    // const offerTypeRef = Referentials.find(item => item.code === ReferentialCode.OfferTye);

    // const offerTypeList =  await this.appReferentialService.Get(offerTypeRef!.baseUrl);

    // const [guaranteeTypeList] = await Promise.all([
    //         this.appReferentialService.Get(guaranteeTypeRef!.baseUrl)
    // ]);

    const {
      vehicleMakeRef,
      bodyTypeRef,
      tractionTypeRef,
      transmissionTypeRef,
      fuelTypeRef,
      paintTypeRef,
      pollutionNormRef,
      modelRef,
      colorRef,
      regionRef,
      cityRef,
      advertiserRef,
      districtRef,
      countryOriginRef,
      guaranteeTypeRef
    } = RefConstants;

    const [
      vehicleMakeList,
      bodyTypeList,
      tractionTypeList,
      transmissionTypeList,
      fuelTypeList,
      paintTypeList,
      pollutionNormList,
      regions,
      cities,
      advertisers,
      models,
      colors,
      advertDistricts,
      originCountries,
      guaranteeTypeList
    ] = await Promise.all([
      this.appReferentialService.Get(vehicleMakeRef!.baseUrl),
      this.appReferentialService.Get(bodyTypeRef!.baseUrl),
      this.appReferentialService.Get(tractionTypeRef!.baseUrl),
      this.appReferentialService.Get(transmissionTypeRef!.baseUrl),
      this.appReferentialService.Get(fuelTypeRef!.baseUrl),
      this.appReferentialService.Get(paintTypeRef!.baseUrl),
      this.appReferentialService.Get(pollutionNormRef!.baseUrl),
      this.appReferentialService.Get(regionRef!.baseUrl),
      this.appReferentialService.Get(cityRef!.baseUrl),
      this.appReferentialService.Get(advertiserRef!.baseUrl),
      this.appReferentialService.Get(modelRef!.baseUrl),
      this.appReferentialService.Get(colorRef!.baseUrl),
      this.appReferentialService.Get(districtRef!.baseUrl),
      this.appReferentialService.Get(countryOriginRef!.baseUrl),
      this.appReferentialService.Get(guaranteeTypeRef!.baseUrl)
    ]);

    const caseParameters = this.props.caseSettingsState.case!.caseParameters;
    const vatParameter = caseParameters.filter(
      (parameter) => parameter.parameter!.code === ParametreCode.TVA
    );

    const vehicleAdvert = await this.vehicleService.GetVehicleAdvert(
      this.props.caseSettingsState.case!.vehicleId,
      caseId
    );

    const vehicleAdvertHistory = await this.getVehicleAdvertHistoryAmongWithUsers();
    let initialOptions: any;
    let vehicleInfo: any;

    console.log('props', this.props);
    const vehicle = await this.vehicleService.GetVehicle(
      this.props.caseSettingsState.case!.vehicleId as any
    );
    this.props.vehicleState.vehicle = _.cloneDeep(vehicle);
    if (R.isNil(vehicleAdvert)) {
      vehicleInfo = this.props.vehicleState.vehicle;
      initialOptions = this.props.vehicleState.vehicle!.vehicleOptions;
    } else {
      vehicleAdvert.individual = !vehicleAdvert.privateBusiness;

      const result = this.props.vehicleState.vehicle!.vehicleOptions.filter((o1) =>
        vehicleAdvert!.vehicleAdvertOptions.some((o2) => o1.id === o2.vehicleOptionId)
      );

      const intermediaryArr =
        result &&
        (result as any).map((item: any) => ({
          ...item,
          checked: true,
          tableData: {
            checked: true
          }
        }));
      initialOptions =
        intermediaryArr &&
        this.props.vehicleState.vehicle!.vehicleOptions.map((item) => ({
          ...item,
          ...intermediaryArr.find(({ id }: any) => id === item.id)
        }));
    }

    const vatValue = parseFloat(vatParameter[0]!.value);

    const valuationPrice = this.getValuationPrice();

    this.setState({
      isLoading: false,
      hasRights: hasRights,
      isUserHidden: !hasRights,
      makeTypes: vehicleMakeList,
      carBodyTypes: bodyTypeList,
      transmissionTypes: tractionTypeList,
      gearBoxTypes: transmissionTypeList,
      pollutionNormTypes: pollutionNormList,
      fuelTypes: fuelTypeList,
      paintTypes: paintTypeList,
      regionsTypes: regions,
      originCountriesTypes: originCountries,
      citiesTypes: cities as any[] as IReferential[],
      advertisers: this.props.caseSettingsState.caseSettings?.workflow?.hideRenewAdvert ? advertisers.filter((x) => !excludedAdvertisers.includes(x.code ?? "")) : advertisers,
      vehicleOptions: initialOptions,
      modelTypes: models,
      colorTypes: colors,
      valuationPrice,
      vatValue: this.getVatParameterValue(),
      districtsTypes: advertDistricts,
      guaranteeTypes: guaranteeTypeList,
      vehicleAdvertHistory: vehicleAdvertHistory,
      vehicleAdvertData: vehicleAdvert
        ? {
            ...vehicleAdvert,
            countryOrigin:
              originCountries.find(({ id }) => id === vehicleAdvert?.countryOriginId) || null
          }
        : {
            ...this.state.vehicleAdvertData,
            vat: vatValue,
            price: 0,
            vin: vehicleInfo?.vin,
            firstRegistrationDate: new Date(vehicleInfo!.firstRegistrationDate),
            makeDate: new Date(vehicleInfo!.makeDate || vehicleInfo!.firstRegistrationDate),
            engineSize: vehicleInfo!.engineSize,
            color: vehicleInfo!.color,
            make: vehicleInfo!.make,
            version: vehicleInfo!.version,
            fuelType: vehicleInfo!.fuelType,
            transmissionType: vehicleInfo!.transmissionType,
            pollutionNorm: vehicleInfo!.pollutionNorm,
            bodyType: vehicleInfo!.bodyType,
            kilometersNr: vehicleInfo!.kilometersNr,
            horsePower: vehicleInfo!.horsePower,
            power: vehicleInfo!.power,
            tractionType: vehicleInfo!.tractionType,
            model: vehicleInfo!.model!,
            nrOfDoors: vehicleInfo!.nrOfDoors,
            paintType: vehicleInfo!.paintType,
            co2Emission: vehicleInfo!.co2,
            region: regions.find((x) => x.displayName === 'Bucuresti - Ilfov') || null,
            city: cities.find((x) => x.displayName === 'Bucuresti') || null,
            district: advertDistricts.find((x) => x.displayName === 'Sectorul 1') || null
          }
    });
  };

  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            },
            currentTarget: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={2}
      />
    );
  };

  getValuationPrice = () => {
    const caseValuation = this.props.caseSettingsState.case?.caseValuation;
    const valuationValue = !R.isNil(caseValuation) ? caseValuation.offerPrice : 0;

    return Utils.ConvertCurrency(
      this.props.appState.currencyRates,
      CurrencyCode.EUR,
      this.state.vehicleAdvertData.currency,
      valuationValue
    );
  };

  getVatParameterValue = () => {
    const caseParameters = this.props.caseSettingsState.case!.caseParameters;
    const vatParameter = caseParameters.find(
      (parameter) => parameter.parameter!.code === ParametreCode.TVA
    );

    return vatParameter ? parseFloat(vatParameter.value) : 0;
  };

  getPriceWithoutVAT = (price: number, vat: number) => {
    const caseHasNoVAT = this.props.vehicleState?.vehicle?.purchaseVatRate === 0 || false;
    const purchasePrice = (this.props.vehicleState?.vehicle?.purchasePrice as number) || 0;

    const vatValue = !caseHasNoVAT
      ? this.getReverseVatValue(price, vat)
      : Utils.getMarginVat(price, +purchasePrice, vat);

    return Number.parseFloat((price - vatValue).toFixed(2));
  };

  getReverseVatValue = (price: number, vat: number): number => {
    return Number.parseFloat(((price / (1 + vat / 100) - price) * -1).toFixed(2));
  };

  private async getVehicleAdvertHistoryAmongWithUsers() {
    const vehicleAdvertHistory = await this.vehicleService.GetVehicleAdvertHistory(
      this.props.caseSettingsState.case!.vehicleId,
      this.props.caseSettingsState.case!.id
    );
    const users = await this.appUserService.GetUsersInfo(
      Array.from(new Set(vehicleAdvertHistory.map((item) => item.createdBy)))
    );
    vehicleAdvertHistory.forEach((item) => {
      const user = users.find((u) => u.id === item.createdBy);
      item.createdByUser = R.isNil(user) ? null : user;
    });
    return vehicleAdvertHistory;
  }

  public getErrorDetails(errorDetails: Map<string, Map<string, string>>): any {
    const text: string[] = [];
    for (const [advertiser, details] of Object.entries(errorDetails)) {
      if (!R.isNil(details)) {
        text.push(`${advertiser}:`);
        for (const [key, value] of Object.entries(details)) {
          {
            text.push(`${key}:${value}`);
          }
        }
      }
    }

    return (
      <div>
        {' '}
        {text.map((t, index) => (
          <div key={index}>{t}</div>
        ))}{' '}
      </div>
    );
  }

  getDocForm = (): WorkflowSectionStepForm | undefined => {
    let form: WorkflowSectionStepForm | undefined = undefined;

    for (const section of this.props.caseSettingsState.caseSettings!.workflow!.workflowSections!) {
      if (form !== undefined) break;
      for (const step of section.workflowSectionSteps) {
        form = step.workflowSectionStepForms.find((f) => f.form.code === FormCode.CASE_ATTACHMENTS);
        if (form !== undefined) {
          break;
        }
      }
    }
    return form;
  };

  getCaseAttachements = async () => {
    const form = this.getDocForm();
    if (form === undefined) {
      return [];
    }
    const workflowDocumentsTypes = form.workflowDocumentTypes;
    const [attachmentTypes] = await Promise.all([
      this.appReferentialService.Get(RefConstants.attachementTypeRef!.baseUrl)
    ]);

    workflowDocumentsTypes.forEach((wfDoc) => {
      const documentType = attachmentTypes.find((item) => item.id === wfDoc.documentTypeId);
      wfDoc.documentType = R.isNil(documentType) ? null : documentType;
    });

    return await this.caseService.GetCaseAttachments(
      this.props.caseSettingsState.case!.id,
      workflowDocumentsTypes.map((item) => item.documentType!.code!)
    );
  };

  /* Get year and days diff between two dates */
  dateDiffYears = (date1: Date, date2: Date): ICaseVehicleAge => {
    const diff = new Date(date2).getTime() - new Date(date1).getTime();
    const years = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));
    const days = Math.floor(diff / (1000 * 60 * 60 * 24)) - years * 365;
    const age = {
      years: years,
      days: days
    };
    console.log(
      'endDate: ',
      date2,
      'startDate: ',
      date1,
      'numberOfDays: ',
      Math.floor(diff / (1000 * 60 * 60 * 24))
    );
    console.log('age', age);
    return age;
  };

  checkMakeYearAndKilometersNr = (operationType: string) => {
    let makeYearAndKilometersNrValid = true;
    const selectedAdvertiserCodes = [...this.state.advertiserOperations]
      .filter((item) => item.operationType === operationType && item.checked === true)
      .map((item) => item.advertiserCode);
    if (selectedAdvertiserCodes.includes('REN')) {
      const kilometersNr = this.state.vehicleAdvertData.kilometersNr;
      const today = new Date(moment().format('YYYY-MM-DD'));
      const vehicleAge = this.dateDiffYears(
        this.state.vehicleAdvertData.firstRegistrationDate,
        today
      );
      if (kilometersNr > 120000 || vehicleAge.years >= 6) {
        makeYearAndKilometersNrValid = false;
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate(
            'PUBLISH_RENAULT_SELECTION_ADVERT',
            'Autovehiculul pe care doriti sa-l publicati pe site-ul Renault Selection nu indeplineste criteriile necesare pentru publicare , o vechime mai mica de  6 ani si/sau mai putin de 120.000 km'
          ),
          {
            variant: 'error',
            autoHideDuration: 5000
          }
        );
      }
    }
    return makeYearAndKilometersNrValid;
  };

  publishAdvert = (operationType: string) => async (e: any) => {
    try {
      const makeYearAndKilometersNrValid = this.checkMakeYearAndKilometersNr(operationType);
      if (!makeYearAndKilometersNrValid) {
        return;
      }

      this.setState({ executing: true });
      const selectedAdvertiserCodes = [...this.state.advertiserOperations]
        .filter((item) => item.operationType === operationType && item.checked === true)
        .map((item) => item.advertiserCode);

      const caseAttachments = await this.getCaseAttachements();
      const attachments = caseAttachments.map((a) => {
        return {
          id: a.id,
          caseId: a.caseId,
          attachmentTypeId: a.attachmentTypeId,
          attachmentTypeCode: a.attachmentType?.code,
          fileName: a.fileName,
          originalFileName: a.originalFileName,
          files: [a.file],
          caseRequestId: a.caseRequestId,
          paymentId: a.paymentId,
          caseClientId: a.caseClientId,
          isSelection: a.isSelection,
          fileUrl: a.url
        };
      }).sort((a: CaseAttachmentDto, b: CaseAttachmentDto) => {
        const indexA = VehicleAttachmentTypeCodeOrder.indexOf(a!.attachmentTypeCode ?? '');
        const indexB = VehicleAttachmentTypeCodeOrder.indexOf(b!.attachmentTypeCode ?? '');
        return indexA - indexB;
      });
      console.log('attachments', attachments);
      const result = await this.vehicleService.PublishAdvert(
        this.state.vehicleAdvertData.id,
        selectedAdvertiserCodes,
        attachments
      );

      const { success, details } = result;
      if (success) {
        const vehicleAdvertHistory = await this.getVehicleAdvertHistoryAmongWithUsers();
        this.setState({
          vehicleAdvertHistory: vehicleAdvertHistory
        });
      }
      success
        ? this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
            variant: 'success'
          })
        : this.props.enqueueSnackbar(this.getErrorDetails(details), {
            variant: 'error',
            autoHideDuration: 5000
          });

      // Send published adverts to RCR only if the vehicle is imported
      if (this.props.vehicleState.vehicle?.isImported) {
        const originalPrice = Number(this.state.vehicleAdvertData.priceWithVat);
        const priceForEmployee = originalPrice - originalPrice * 0.05;
        const hasDecimal = priceForEmployee % 1 !== 0;
        const formattedPrice = hasDecimal
          ? Number(priceForEmployee.toFixed(1))
          : Number(priceForEmployee);
        const adDetails = [] as AddDetailsPublishRcr[];

        result.advertPublishedResults.map((item) => {
          adDetails.push({
            AdvertiserCode: item.advertiser,
            AdUrl: item.url,
            Price: formattedPrice
          });
        });

        const requestData = {
          ConnectId: this.state.vehicleAdvertData.vehicleId,
          AdDetails: adDetails
        } as PublishAdvertRcr;

        const sendAdvertsToRcrResult = await this.vehicleService.SendPublishedAdvertsToRcr(
          requestData
        );

        sendAdvertsToRcrResult.success
          ? this.props.enqueueSnackbar(
              this.translatorService.Tranlate(
                'SUCCES_MSG_SEND_ADVERTS_TO_RCR',
                'Operatiunea publicarii anuntului catre angajati a fost realizata cu succes'
              ),
              {
                variant: 'success'
              }
            )
          : this.props.enqueueSnackbar(
              this.translatorService.Tranlate(
                'ERROR_MSG_SEND_ADVERTS_TO_RCR',
                'Operatiunea publicarii anuntului catre angajati nu a putut fi realizata'
              ),
              {
                variant: 'error',
                autoHideDuration: 5000
              }
            );
      }
    } catch (ex) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    } finally {
      this.setState({
        executing: false
      });
    }
  };

  deleteAdvert = (operationType: string) => async (e: any) => {
    try {
      this.setState({ executing: true });
      const selectedAdvertiserCodes = [...this.state.advertiserOperations]
        .filter((item) => item.operationType === operationType && item.checked === true)
        .map((item) => item.advertiserCode);

      const result = await this.vehicleService.DeleteAdvert(
        this.state.vehicleAdvertData.id,
        selectedAdvertiserCodes
      );
      const { success, details } = result;

      if (success) {
        const vehicleAdvertHistory = await this.getVehicleAdvertHistoryAmongWithUsers();
        this.setState({ vehicleAdvertHistory: vehicleAdvertHistory });
      }

      success
        ? this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
            variant: 'success'
          })
        : this.props.enqueueSnackbar(this.getErrorDetails(details), {
            variant: 'error',
            autoHideDuration: 5000
          });
    } catch (ex) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    } finally {
      this.setState({
        executing: false
      });
    }
  };

  checkConfirmChanges = async () => {
    if (!this.state.vehicleAdvertData.vehicleAdvertOptions.length) {
      this.setState({ isOpen: true, dialogVisible: true });
    } else {
      this.submitData();
    }
  };

  submitData = async () => {
    try {
      this.setState({ executing: true, isOpen: false, dialogVisible: false });

      const data: VehicleAdvert = {
        ...this.state.vehicleAdvertData,
        vatAmount: this.state.vehicleAdvertData.priceWithVat - this.state.vehicleAdvertData.price
      };

      const result = await this.vehicleService.SaveVehicleAdvert(data);

      const vehicleAdvertHistory = await this.getVehicleAdvertHistoryAmongWithUsers();
      this.setState({
        vehicleAdvertData: {
          ...data,
          id: result.id
        },
        vehicleAdvertHistory: vehicleAdvertHistory
      });

      await this.submitForm(
        this.props.caseSettingsState.case!.id,
        this.props.workflowForm.id,
        null
      );

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (ex) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    } finally {
      this.setState({
        executing: false
      });
    }
  };

  submitForm = async (caseId: number, caseSectionStepFormId: number, appUser: AppUser | null) => {
    let newStatus = await this.caseService.SubmitForm(caseId, caseSectionStepFormId, appUser);

    if (newStatus === null) {
      newStatus = this.props.caseSettingsState.case!.caseStatus;
    }

    this.props.SetCaseStatus(newStatus);
    if (appUser !== null) {
      this.props.AddPartner(appUser!.hoId === null ? appUser!.organizationId : appUser!.hoId);
    }

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      newStatus.caseStatusId
    );
    this.setState({ hasRights: hasRights });
  };

  getVATValue = (price: number, vat: number): number => {
    return Number.parseFloat((price * (vat / 100)).toFixed(2));
  };

  getPriceWithVAT = (price: number, vat: number) => {
    const vatValue = Number.parseFloat((price * (vat / 100)).toFixed(2));
    return price + vatValue;
  };

  handleSwitchValue = (fieldName: string) => (e: any) => {
    this.setState({
      vehicleAdvertData: {
        ...this.state.vehicleAdvertData,
        [fieldName]: e.currentTarget.checked
      }
    });
  };

  handleIsActiveIndividual = (e: any) => {
    this.setState({
      vehicleAdvertData: {
        ...this.state.vehicleAdvertData,
        individual: e.currentTarget.checked,
        privateBusiness: !e.currentTarget.checked
      }
    });
  };

  handleIsActiveBusiness = (e: any) => {
    this.setState({
      vehicleAdvertData: {
        ...this.state.vehicleAdvertData,
        privateBusiness: e.currentTarget.checked,
        individual: !e.currentTarget.checked
      }
    });
  };

  getVATFromAdvertPrice = () => {
    const caseHasNoVAT = this.props.vehicleState?.vehicle?.purchaseVatRate === 0 || false;
    const purchasePrice = (this.props.vehicleState?.vehicle?.purchasePrice as number) || 0;

    const advertPrice = this.state.vehicleAdvertData.priceWithVat;
    const caseParameters = this.props.caseSettingsState.case!.caseParameters;
    const vatParameter = caseParameters.filter(
      (parameter) => parameter.parameter!.code === ParametreCode.TVA
    );
    const vat = parseFloat(vatParameter[0]!.value);

    if (!caseHasNoVAT) {
      const offerPriceWithoutVAT = advertPrice / (1 + vat / 100);

      return Number.parseFloat((offerPriceWithoutVAT * (vat / 100)).toFixed(2));
    }

    return Utils.getMarginVat(advertPrice, purchasePrice, vat);
  };

  handleMakeTypeChange = async (newValue: IReferential | null) => {
    this.setState({
      vehicleAdvertData: {
        ...this.state.vehicleAdvertData,
        make: newValue,
        makeId: newValue === null ? null : newValue.id
      }
    });
  };

  handleModelTypeChange = (newValue: IReferential | null) => {
    this.setState({
      vehicleAdvertData: {
        ...this.state.vehicleAdvertData,
        model: newValue,
        modelId: newValue === null ? null : newValue.id
      }
    });
  };

  handleFuelTypeChange = (newValue: IReferential | null) => {
    this.setState({
      vehicleAdvertData: {
        ...this.state.vehicleAdvertData,
        fuelType: newValue,
        fuelTypeId: newValue === null ? null : newValue.id
      }
    });
  };

  handleTransmissionTypeChange = (newValue: IReferential | null) => {
    this.setState({
      vehicleAdvertData: {
        ...this.state.vehicleAdvertData,
        tractionType: newValue,
        tractionTypeId: newValue === null ? null : newValue.id
      }
    });
  };

  handleGearBoxTypeChange = (newValue: IReferential | null) => {
    this.setState({
      vehicleAdvertData: {
        ...this.state.vehicleAdvertData,
        transmissionType: newValue,
        transmissionTypeId: newValue === null ? null : newValue.id
      }
    });
  };

  handlePollutionNormTypeChange = (newValue: IReferential | null) => {
    this.setState({
      vehicleAdvertData: {
        ...this.state.vehicleAdvertData,
        pollutionNorm: newValue,
        pollutionNormId: newValue === null ? null : newValue.id
      }
    });
  };

  handleCarBodyTypeChange = (newValue: IReferential | null) => {
    this.setState({
      vehicleAdvertData: {
        ...this.state.vehicleAdvertData,
        bodyType: newValue,
        bodyTypeId: newValue === null ? null : newValue.id
      }
    });
  };

  handleGuaranteeTypeChange = (newValue: IReferential | null) => {
    this.setState({
      vehicleAdvertData: {
        ...this.state.vehicleAdvertData,
        guaranteeType: newValue,
        guaranteeTypeId: newValue === null ? null : newValue.id
      }
    });
  };

  handleAdvertRegionTypeChange = (newValue: IReferential | null) => {
    this.setState({
      vehicleAdvertData: {
        ...this.state.vehicleAdvertData,
        region: newValue,
        regionId: newValue === null ? null : newValue.id,
        city: null,
        cityId: null,
        district: null,
        districtId: null
      }
    });
  };

  handleAdvertOriginCountryTypeChange = (newValue: IReferential | null) => {
    this.setState({
      vehicleAdvertData: {
        ...this.state.vehicleAdvertData,
        countryOrigin: newValue,
        countryOriginId: newValue === null ? null : newValue.id
      }
    });
  };

  handleAdvertDistrictTypeChange = (newValue: IReferential | null) => {
    this.setState({
      vehicleAdvertData: {
        ...this.state.vehicleAdvertData,
        district: newValue,
        districtId: newValue === null ? null : newValue.id
      }
    });
  };

  handleAdvertCityTypeChange = (newValue: IReferential | null) => {
    this.setState({
      vehicleAdvertData: {
        ...this.state.vehicleAdvertData,
        city: newValue,
        cityId: newValue === null ? null : newValue.id,
        district: null,
        districtId: null
      }
    });
  };

  handlePaintTypeChange = (newValue: IReferential | null) => {
    this.setState({
      vehicleAdvertData: {
        ...this.state.vehicleAdvertData,
        paintType: newValue,
        paintTypeId: newValue === null ? null : newValue.id
      }
    });
  };

  handleColorTypeChange = (newValue: IReferential | null) => {
    this.setState({
      vehicleAdvertData: {
        ...this.state.vehicleAdvertData,
        color: newValue,
        colorId: newValue === null ? null : newValue.id
      }
    });
  };

  handleManufacturerWarrantyDateChange = (date: MaterialUiPickersDate | null) => {
    if (date === null || !date.isValid) {
      return;
    }

    this.setState({
      vehicleAdvertData: {
        ...this.state.vehicleAdvertData,
        manufacturerWarrantyDate: new Date(date.format('YYYY-MM-DD'))
      }
    });
  };

  handleManufacturerWarrantyDateClear = () => {
    this.setState({
      vehicleAdvertData: {
        ...this.state.vehicleAdvertData,
        manufacturerWarrantyDate: null
      }
    });
  };

  handleManufacturedYearDateChange = (date: MaterialUiPickersDate | null) => {
    if (date === null || !date.isValid) {
      return;
    }

    this.setState({
      vehicleAdvertData: {
        ...this.state.vehicleAdvertData,
        makeDate: new Date(date.format('YYYY-MM-DD'))
      }
    });
  };

  handleFirstRegistrationDateChange = (date: MaterialUiPickersDate | null) => {
    if (date === null || !date.isValid) {
      return;
    }

    this.setState({
      vehicleAdvertData: {
        ...this.state.vehicleAdvertData,
        firstRegistrationDate: new Date(date.format('YYYY-MM-DD'))
      }
    });
  };

  handleToggleOptions = (id: number) => (event: any) => {
    const idx = this.state.vehicleOptions.findIndex((item) => item.id == id);
    const newOptions = [...this.state.vehicleOptions];
    newOptions[idx] = {
      ...newOptions[idx],
      checked: event.target.checked,
      tableData: {
        checked: event.target.checked
      }
    } as any;
    const vehicleAdvertOptions = newOptions
      .filter((item: any) => item.checked === true)
      .map((item) => ({
        vehicleAdvertId: this.state.vehicleAdvertData.id,
        vehicleOptionId: item.id,
        gtid: this.props.appState.appUser!.gtId
      })) as any;

    this.setState({
      vehicleOptions: newOptions,
      vehicleAdvertData: {
        ...this.state.vehicleAdvertData,
        vehicleAdvertOptions: vehicleAdvertOptions
      }
    });
  };

  onToogleAdvertiser = (e: any, operationType: string, advertiserCode: string) => {
    const advertiserOperations = [...this.state.advertiserOperations];
    const idx = advertiserOperations.findIndex(
      (item) => item.operationType === operationType && item.advertiserCode === advertiserCode
    );
    idx < 0
      ? advertiserOperations.push({
          operationType: operationType,
          advertiserCode: advertiserCode,
          checked: e.currentTarget.checked
        })
      : (advertiserOperations[idx].checked = e.currentTarget.checked);

    this.setState({ advertiserOperations: advertiserOperations });
  };

  getAdvertReportWord = async (vehicleAdvertId: number, vehicleId: number, caseId: number) => {
    const reportParameters = [
      {
        name: 'vehicleId',
        value: vehicleId.toString()
      } as ReportParameter,
      {
        name: 'caseId',
        value: caseId.toString()
      } as ReportParameter,
      {
        name: 'vehicleAdvertId',
        value: vehicleAdvertId.toString()
      } as ReportParameter,
      {
        name: 'organizationId',
        value: this.props.appState.appUser!.organization?.id.toString()
      } as ReportParameter,

      {
        name: 'apiUrl',
        value: Constants.apiRoot
      } as ReportParameter,
      {
        name: 'organizationOwnerId',
        value: this.props.caseSettingsState.case?.organizationOwnerId.toString()
      } as ReportParameter
    ];
    const fileBlob = await this.reportService.GetReport(
      ReportTypeCode.ADVERT_REPORT,
      reportParameters
    );
    FileSaver.saveAs(fileBlob, 'Afis Pret.docx');
  };

  showOfferTimeline = () => {
    const caseHasNoVAT = this.props.vehicleState?.vehicle?.purchaseVatRate == 0;

    return this.state.vehicleAdvertHistory.map((vehicleAdv, index) => (
      <div key={index} className="timeline-item">
        <div className="timeline-item--content">
          <div className="timeline-item--icon " />
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <Button
                className="m-2"
                variant="outlined"
                color="secondary"
                disabled={this.state.executing}
                onClick={() =>
                  this.getAdvertReportWord(vehicleAdv.id, vehicleAdv.vehicleId, vehicleAdv.caseId)
                }
              >
                {this.translatorService.Tranlate('ADVERT_REPORT_PDF', 'Afis Pret')}
              </Button>
            </Grid>
          </Grid>

          <p className="timeline-item--content font-weight-bold">
            {moment.utc(vehicleAdv.dateCreation).local().format(this.props.appState.longDateFormat)}
          </p>
          <p className="timeline-item--content font-weight-bold">
            {vehicleAdv.createdByUser?.userName}
          </p>
          <p className="timeline-item--label mb-2 font-weight-bold">
            {caseHasNoVAT
              ? this.translatorService.Tranlate('VEHICLE_ADVERT_PRICE_OFFERED', 'Pret calculat')
              : this.translatorService.Tranlate(
                  'VEHICLE_ADVERT_PRICE_OFFERED_WITH_VAT',
                  'Pret calculat cu TVA'
                )}
            :{vehicleAdv.priceWithVat + ' ' + this.state.vehicleAdvertData.currency},
          </p>

          {vehicleAdv.vehicleAdvertAdvertisers.map((item, idx) => (
            <Button key={idx} className="m-2" variant="outlined" color="primary">
              <a href={item.url} rel="noopener noreferrer" target="_blank">
                {this.state.advertisers.find((i) => i.id === item.advertiserId)?.name}
              </a>
            </Button>
          ))}
        </div>
      </div>
    ));
  };

  isAdvertisersDisabled(operationType: string): boolean | undefined {
    const operation =
      operationType == VehicleAdvertOperationTypes.publish
        ? VehicleAdvertOperationTypes.delete
        : VehicleAdvertOperationTypes.publish;
    const result = this.state.advertiserOperations.filter(
      (item) => item.operationType === operation && item.checked === true
    );
    if (result && result.length) {
      return true;
    }
    return false;
  }

  renderAdvertisers = (operationType: string) => {
    const isMavoAdvertPresent = this.state.advertisers.some(
      (i) => i.code === AdvertOptionCodeEnum.MAVO
    );
    return this.state.advertisers.map((adv, index) => {
      return (
        <Grid item xs={isMavoAdvertPresent ? 2 : 3} key={index}>
          <div className="text-left">
            <FormControlLabel
              disabled={
                this.state.vehicleAdvertData.id === 0 || this.isAdvertisersDisabled(operationType)
              }
              name="advertiser"
              control={
                <Switch
                  disabled={!this.state.hasRights}
                  checked={this.isChecked(operationType, adv.code!)}
                  onChange={(e: any) => this.onToogleAdvertiser(e, operationType, adv.code!)}
                  color="primary"
                />
              }
              label={adv.name ? adv.displayName : ''}
              labelPlacement="end"
              className="mt-2 ml-0 mr-0"
            />
          </div>
        </Grid>
      );
    });
  };

  handleCloseConfirmationDialog = () => {
    this.setState({
      isOpen: false,
      dialogVisible: false
    });
  };

  handleOpenConfirmationDialog = () => {
    this.setState({
      isOpen: true,
      dialogVisible: true
    });
  };

  handleCloseModal = () => {
    this.setState({
      isOpen: false,
      dialogVisible: false
    });
  };

  getCarOptionsColumns = () => {
    return [
      {
        field: 'name',
        title: this.translatorService.Tranlate(
          'CAR_DETAILS_CAR_ADEVERT_OPTIONAL_OPTION',
          'Descriere dotari autovehicul'
        ),
        searchable: true
      } as Column<any>,
      {
        field: 'checked',
        render: (option: VehicleOption) => {
          return (
            <div className="text-right">
              <Switch
                edge="end"
                onChange={this.handleToggleOptions(option.id)}
                checked={this.optionIsChecked(option)}
                inputProps={{ 'aria-labelledby': 'switch-list-label-' + option.id }}
              />
            </div>
          );
        }
      } as Column<any>
    ];
  };

  public renderCarOptionsTable = () => {
    return (
      <MaterialTable
        icons={MaterialTableIcons}
        title={''}
        columns={this.getCarOptionsColumns()}
        data={this.state.vehicleOptions}
        options={{
          actionsColumnIndex: -1,
          addRowPosition: 'first',
          toolbar: this.state.hasRights
        }}
        localization={getAdvertLocalization(this.translatorService)}
        editable={{}}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />
        }}
      />
    );
  };
  scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);

  rendeVehicleAdvertForm = () => {
    const calculatedPriceWithVat = this.translatorService.Tranlate(
      'VEHICLE_ADVERT_FORM_PRICE_WITH_VAT',
      'Pret calculat cu TVA'
    );

    const advertPriceWithVat = this.translatorService.Tranlate(
      'VEHICLE_ADVERT_FORM_ADVERT_PRICE_WITH_VAT',
      'Pret anunt cu TVA'
    );

    const caseHasNoVAT = this.props.vehicleState?.vehicle?.purchaseVatRate === 0 || false;
    const purchasePrice = (this.props.vehicleState?.vehicle?.purchasePrice as number) || 0;

    return (
      <ValidatorForm
        onSubmit={(e) => {
          this.checkConfirmChanges();
        }}
        onError={(errors) => {
          if (errors[0].props.name === 'advertPriceWithVAT') {
            this.scrollToRef(this.inputTextValidator);
          }
        }}
      >
        <div className="m-3 text-center">
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <TextValidator
                fullWidth
                disabled={true}
                id="price-without-vat"
                className="m-2 pr-3"
                name="valuationPrice"
                placeholder={this.translatorService.Tranlate(
                  'VEHICLE_ADVERT_FORM_PRICE_WITHOUT_VAT',
                  'Pret calculat fara TVA'
                )}
                value={this.getPriceWithoutVAT(this.state.valuationPrice, this.state.vatValue)}
                onChange={(e: any) => {
                  this.setState({
                    valuationPrice: e.target.value
                  });
                }}
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={this.translatorService.Tranlate(
                  'VEHICLE_ADVERT_FORM_PRICE_WITHOUT_VAT',
                  'Pret calculat fara TVA'
                )}
                InputProps={{
                  inputComponent: this.NumberFormatCustom
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextValidator
                fullWidth
                disabled={true}
                id="vat"
                className="m-2 pr-3"
                name="vat"
                placeholder={this.translatorService.Tranlate('VEHICLE_ADVERT_FORM_VAT', 'TVA')}
                value={
                  !caseHasNoVAT
                    ? this.getReverseVatValue(this.state.valuationPrice, this.state.vatValue)
                    : Utils.getMarginVat(
                        this.state.valuationPrice,
                        +purchasePrice,
                        this.state.vatValue
                      )
                }
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={this.translatorService.Tranlate('VEHICLE_ADVERT_FORM_VAT', 'TVA')}
                InputProps={{
                  inputComponent: this.NumberFormatCustom
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextValidator
                fullWidth
                disabled={true}
                id="price-with-vat"
                className="m-2 pr-3"
                name="priceWithVat"
                placeholder={calculatedPriceWithVat}
                value={this.state.valuationPrice}
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={calculatedPriceWithVat}
                InputProps={{
                  inputComponent: this.NumberFormatCustom
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <TextValidator
                fullWidth
                disabled={true}
                id="price"
                className="m-2 pr-3"
                name="price"
                placeholder={this.translatorService.Tranlate(
                  'VEHICLE_ADVERT_FORM_ADVERT_PRICE_WITHOUT_VAT',
                  'Pret anunt fara TVA'
                )}
                value={this.state.vehicleAdvertData.price}
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={this.translatorService.Tranlate(
                  'VEHICLE_ADVERT_FORM_ADVERT_PRICE_WITHOUT_VAT',
                  'Pret anunt fara TVA'
                )}
                InputProps={{
                  inputComponent: this.NumberFormatCustom
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextValidator
                fullWidth
                disabled={true}
                id="advert-price-vat"
                className="m-2 pr-3"
                name="advertPriceVAT"
                placeholder={this.translatorService.Tranlate(
                  'VEHICLE_ADVERT_FORM_ADVERT_PRICE_VAT',
                  'TVA'
                )}
                value={this.getVATFromAdvertPrice()}
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={this.translatorService.Tranlate(
                  'VEHICLE_ADVERT_FORM_ADVERT_PRICE_VAT',
                  'TVA'
                )}
                InputProps={{
                  inputComponent: this.NumberFormatCustom
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextValidator
                fullWidth
                disabled={!this.state.hasRights}
                ref={this.inputTextValidator}
                id="advert-price-with-vat"
                className="m-2 pr-3"
                name="advertPriceWithVAT"
                placeholder={advertPriceWithVat}
                value={this.state.vehicleAdvertData.priceWithVat}
                onChange={(e: any) => {
                  this.setState({
                    vehicleAdvertData: {
                      ...this.state.vehicleAdvertData,
                      priceWithVat: e.target.value,
                      price: this.getPriceWithoutVAT(e.target.value, this.state.vatValue)
                    }
                  });
                }}
                validators={['required', 'matchRegexp:^[1-9][0-9]*$']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu'),
                  this.translatorService.Tranlate(
                    'VALIDATORS_ZERO_VALUE',
                    'Va rugam sa completati pretul de vanzare'
                  )
                ]}
                label={advertPriceWithVat}
                InputProps={{
                  inputComponent: this.NumberFormatCustom
                }}
              />
            </Grid>
          </Grid>
          <h5 className="bg-first text-left mt-3 p-1" style={{ color: 'white' }}>
            {this.translatorService.Tranlate(
              'VEHICLE_ADVERT_FORM_FINANCIAL_OPTIONS_TITLE',
              'Optiuni financiare'
            )}
          </h5>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <div className="text-left">
                <FormControlLabel
                  name="hasInvoice"
                  control={
                    <Switch
                      disabled={!this.state.hasRights}
                      checked={this.state.vehicleAdvertData.hasInvoice}
                      onChange={this.handleSwitchValue('hasInvoice')}
                      color="primary"
                    />
                  }
                  label={this.translatorService.Tranlate(
                    'VEHICLE_ADVERT_FORM_HAS_INVOICE',
                    'Se emite factura'
                  )}
                  labelPlacement="end"
                  className="mt-3 ml-0 mr-0"
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="text-left">
                <FormControlLabel
                  name="leasingConcession"
                  control={
                    <Switch
                      disabled={!this.state.hasRights}
                      checked={this.state.vehicleAdvertData.leasingConcession}
                      onChange={this.handleSwitchValue('leasingConcession')}
                      color="primary"
                    />
                  }
                  label={this.translatorService.Tranlate(
                    'VEHICLE_ADVERT_FORM_HAS_LEASING',
                    'Predare leasing'
                  )}
                  labelPlacement="end"
                  className="mt-3 ml-0 mr-0"
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="text-left">
                <FormControlLabel
                  name="authorizedDealer"
                  control={
                    <Switch
                      disabled={!this.state.hasRights}
                      checked={this.state.vehicleAdvertData.authorizedDealer}
                      onChange={this.handleSwitchValue('authorizedDealer')}
                      color="primary"
                    />
                  }
                  label={this.translatorService.Tranlate(
                    'VEHICLE_ADVERT_FORM_HAS_AUTHORIZED_DEALER',
                    'Dealer autorizat'
                  )}
                  labelPlacement="end"
                  className="mt-3 ml-0 mr-0"
                />
              </div>
            </Grid>
          </Grid>
          <TextValidator
            fullWidth
            disabled={!this.state.hasRights}
            id="observations"
            className="m-2 pr-3"
            name="observations"
            placeholder={this.translatorService.Tranlate(
              'VEHICLE_ADVERT_FORM_OBSERVATIONS',
              'Detalii anunt'
            )}
            value={this.state.vehicleAdvertData.description}
            onChange={(e: any) => {
              this.setState({
                vehicleAdvertData: {
                  ...this.state.vehicleAdvertData,
                  description: e.target.value
                }
              });
            }}
            validators={['required', 'minLength', 'maxLength']}
            errorMessages={[
              this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu'),
              this.translatorService.Tranlate(
                'VALIDATORS_MIN_LENGTH',
                'Numar minim de caractere: '
              ) + minNumberOfCharactersAdvertDetails,
              this.translatorService.Tranlate(
                'VALIDATORS_MAX_LENGTH',
                'Numar maxim de caractere: '
              ) + maxNumberOfCharactersAdvertDetails
            ]}
            label={this.translatorService.Tranlate(
              'VEHICLE_ADVERT_FORM_OBSERVATIONS',
              'Detalii anunt'
            )}
            multiline
            variant="outlined"
            rows={6}
          />
          {/*
          <h5 className="bg-first text-left mt-3 p-1" style={{ color: 'white', display:'none' }} >
            {this.translatorService.Tranlate('VEHICLE_ADVERT_FORM_REGIONS_TITLE', 'Locatie anunt')}
          </h5>

          <Grid container spacing={0} style={{display:'none'}}>
            <Grid item xs={6}>
              <div className="text-left">
                <Autocomplete
                  disableClearable
                  disabled={!this.state.hasRights}
                  id="region"
                  className="m-2"
                  options={this.state.regionsTypes}
                  value={this.state.vehicleAdvertData.region || null}
                  onChange={(e: any, newValue: IReferential | null) =>
                    this.handleAdvertRegionTypeChange(newValue)
                  }
                  getOptionLabel={(option: IReferential) => option.displayName || ''}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      name="region"
                      value={this.state.vehicleAdvertData.region}
                      label={this.translatorService.Tranlate(
                        'VEHICLE_ADVERT_FORM_ADVERT_REGION',
                        'Regiune anunt'
                      )}
                      fullWidth
                      validators={['required']}
                      errorMessages={[
                        this.translatorService.Tranlate(
                          'VALIDATORS_REQUIRED',
                          'Campul este obligatoriu'
                        )
                      ]}
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="text-left">
                <Autocomplete
                  disableClearable
                  disabled={!this.state.hasRights}
                  id="city"
                  className="m-2"
                  options={
                    this.state.citiesTypes.filter(
                      (city) => city.dependencyId === this.state.vehicleAdvertData.regionId
                    ) || this.state.citiesTypes
                  }
                  value={this.state.vehicleAdvertData.city || null}
                  onChange={(e: any, newValue: IReferential | null) =>
                    this.handleAdvertCityTypeChange(newValue)
                  }
                  getOptionLabel={(option: IReferential) => option.displayName || ''}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      name="city"
                      value={this.state.vehicleAdvertData.city}
                      label={this.translatorService.Tranlate(
                        'VEHICLE_ADVERT_FORM_ADVERT_CITY',
                        'Oras anunt'
                      )}
                      fullWidth
                      validators={['required']}
                      errorMessages={[
                        this.translatorService.Tranlate(
                          'VALIDATORS_REQUIRED',
                          'Campul este obligatoriu'
                        )
                      ]}
                    />
                  )}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={0} style={{ display: 'none' }}>
            <Grid item xs={6}>
              <div className="text-left">
                <Autocomplete
                  disabled={!this.state.hasRights}
                  id="district"
                  className="m-2"
                  options={
                    this.state.districtsTypes.filter(
                      (district) => district.dependencyId === this.state.vehicleAdvertData.cityId
                    ) || []
                  }
                  value={this.state.vehicleAdvertData.district || null}
                  onChange={(e: any, newValue: IReferential | null) =>
                    this.handleAdvertDistrictTypeChange(newValue)
                  }
                  getOptionLabel={(option: IReferential) => option.displayName || ''}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      name="region"
                      value={this.state.vehicleAdvertData.district}
                      label={this.translatorService.Tranlate(
                        'VEHICLE_ADVERT_FORM_ADVERT_DISTRICT',
                        'District anunt'
                      )}
                      fullWidth
                      validators={
                        this.state.districtsTypes.filter(
                          (district) =>
                            district.dependencyId === this.state.vehicleAdvertData.cityId
                        ).length > 0
                          ? ['required']
                          : []
                      }
                      errorMessages={[
                        this.translatorService.Tranlate(
                          'VALIDATORS_REQUIRED',
                          'Campul este obligatoriu'
                        )
                      ]}
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="text-left"></div>
            </Grid>
          </Grid>
          */}
          <h5 className="bg-first text-left mt-3 p-1" style={{ color: 'white' }}>
            {this.translatorService.Tranlate('VEHICLE_ADVERT_FORM_DETAILS_TITLE', 'Detalii')}
          </h5>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Autocomplete
                disabled={!this.state.hasRights}
                id="car-color"
                className="m-2"
                options={this.state.colorTypes}
                value={this.state.vehicleAdvertData.color}
                onChange={(e: any, newValue: IReferential | null) =>
                  this.handleColorTypeChange(newValue)
                }
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="color"
                    value={this.state.vehicleAdvertData.color}
                    label={this.translatorService.Tranlate('CAR_DETAILS_COLOR', 'Culoare')}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                disabled={!this.state.hasRights}
                id="car-paint"
                className="m-2"
                options={this.state.paintTypes}
                value={this.state.vehicleAdvertData.paintType}
                onChange={(e: any, newValue: IReferential | null) =>
                  this.handlePaintTypeChange(newValue)
                }
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="traction"
                    value={this.state.vehicleAdvertData.paintType}
                    label={this.translatorService.Tranlate('CAR_DETAILS_PAINT_TYPE', 'Tip vopsea')}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Autocomplete
                disabled={!this.state.hasRights || true}
                id="brand"
                className="m-2"
                options={this.state.makeTypes}
                value={this.state.vehicleAdvertData.make}
                onChange={(e: any, newValue: IReferential | null) =>
                  this.handleMakeTypeChange(newValue)
                }
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="brand"
                    value={this.state.vehicleAdvertData.make}
                    label={this.translatorService.Tranlate('VEHICLE_ADVERT_FORM_MAKE', 'Marca')}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                disabled={!this.state.hasRights || true}
                id="model"
                className="m-2"
                options={this.state.modelTypes}
                value={this.state.vehicleAdvertData.model}
                onChange={(e: any, newValue: IReferential | null) =>
                  this.handleModelTypeChange(newValue)
                }
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="model"
                    value={this.state.vehicleAdvertData.model}
                    label={this.translatorService.Tranlate('VEHICLE_ADVERT_FORM_MODEL', 'Model')}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />
              {/* <TextValidator
                                className="m-2"
                                name="model"
                                value={this.state.vehicleAdvertData.model}
                                label={this.translatorService.Tranlate("VEHICLE_ADVERT_FORM_MODEL", "Model")}
                                fullWidth
                                validators={['required']}
                                errorMessages={[this.translatorService.Tranlate("VALIDATORS_REQUIRED", "Campul este obligatoriu")]}
                            /> */}
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            {/* <Grid item xs={6}>
                            <div className="text-left">
                                <FormControlLabel
                                    name="isActiveFinanceEligibility"
                                    control={<Switch
                                        disabled={!this.state.hasRights}
                                        checked={this.state.vehicleAdvertData.hasFinanceEligibility}
                                        onChange={(e: any) => this.handleIsActiveFinanceEligibility(e)}
                                        color="primary" />}
                                    label={this.translatorService.Tranlate("VEHICLE_ADVERT_FORM_HAS_FINANCE_ELIGIBILITY", "Eligibil pentru finantare")}
                                    labelPlacement="end"
                                    className="mt-3 ml-0 mr-0"
                                />
                            </div>
                        </Grid> */}
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <TextValidator
                fullWidth
                disabled={!this.state.hasRights}
                id="version"
                className="m-2 pr-3"
                name="version"
                placeholder={this.translatorService.Tranlate(
                  'VEHICLE_ADVERT_FORM_VERSION',
                  'Versiune'
                )}
                value={this.state.vehicleAdvertData.version}
                onChange={(e: any) => {
                  this.setState({
                    vehicleAdvertData: {
                      ...this.state.vehicleAdvertData,
                      version: e.target.value
                    }
                  });
                }}
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={this.translatorService.Tranlate('VEHICLE_ADVERT_FORM_VERSION', 'Versiune')}
              />
            </Grid>
            <Grid item xs={6}>
              <div className="text-left">
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={MomentUtils}
                  locale={this.props.appState.language}
                >
                  <DatePicker
                    fullWidth
                    className="mt-2 ml-2 pr-3"
                    variant="inline"
                    openTo="date"
                    views={['year', 'month', 'date']}
                    format={this.props.appState.dateFormat.toUpperCase()}
                    margin="normal"
                    id="manufacturer-warranty"
                    label={this.translatorService.Tranlate(
                      'VEHICLE_ADVERT_FORM_MANUFACTURER_WARRANTY',
                      'Garantie de la producator pana la'
                    )}
                    value={this.state.vehicleAdvertData.manufacturerWarrantyDate}
                    onChange={this.handleManufacturerWarrantyDateChange}
                    autoOk={true}
                    minDate={new Date()}
                    InputProps={{
                      endAdornment: this.state.vehicleAdvertData.manufacturerWarrantyDate ? (
                        <InputAdornment position="end">
                          <IconButton onClick={this.handleManufacturerWarrantyDateClear}>
                            <ClearIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      ) : (
                        ''
                      )
                    }}
                    // minDate={new Date("01.01.1900")}
                    // error={this.state.datePickerYearOfFabricationError}
                    // helperText={this.state.datePickerYearOfFabricationError === true ? this.translatorService.Tranlate("CAR_DETAILS_YEAR_OF_FABRICATION_ERROR", "Data fabricatiei trebuie sa fie mai mica decat cea a primei inmatriculari") : ""}
                    // minDate={activeButton === 1 ? new Date() : this.state.minDate}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider
                libInstance={moment}
                utils={MomentUtils}
                locale={this.props.appState.language}
              >
                <DatePicker
                  fullWidth
                  className="mt-2 ml-2 pr-3"
                  variant="inline"
                  openTo="date"
                  views={['year', 'month', 'date']}
                  format={this.props.appState.dateFormat.toUpperCase()}
                  margin="normal"
                  id="yearOfFabrication"
                  label={this.translatorService.Tranlate(
                    'VEHICLE_ADVERT_FORM_MANUFACTURER_YEAR',
                    'Anul fabricatiei'
                  )}
                  value={this.state.vehicleAdvertData.makeDate}
                  onChange={this.handleManufacturedYearDateChange}
                  autoOk={true}
                  // error={this.state.datePickerYearOfFabricationError}
                  // helperText={this.state.datePickerYearOfFabricationError === true ? this.translatorService.Tranlate("CAR_DETAILS_YEAR_OF_FABRICATION_ERROR", "Data fabricatiei trebuie sa fie mai mica decat cea a primei inmatriculari") : ""}
                  // minDate={activeButton === 1 ? new Date() : this.state.minDate}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}>
              <div className="text-left">
                <TextValidator
                  fullWidth
                  disabled={!this.state.hasRights}
                  id="km-limit"
                  className="m-2 pr-3"
                  name="kmLimit"
                  placeholder={this.translatorService.Tranlate(
                    'VEHICLE_ADVERT_FORM_KM_LIMIT',
                    'sau in limita a'
                  )}
                  value={this.state.vehicleAdvertData.warrantyKmLimit}
                  onChange={(e: any) => {
                    this.setState({
                      vehicleAdvertData: {
                        ...this.state.vehicleAdvertData,
                        warrantyKmLimit: e.target.value
                      }
                    });
                  }}
                  validators={['required']}
                  errorMessages={[
                    this.translatorService.Tranlate(
                      'VALIDATORS_REQUIRED',
                      'Campul este obligatoriu'
                    )
                  ]}
                  label={this.translatorService.Tranlate(
                    'VEHICLE_ADVERT_FORM_KM_LIMIT',
                    'sau in limita a'
                  )}
                  InputProps={{
                    inputComponent: this.NumberFormatCustom
                  }}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <TextValidator
                fullWidth
                disabled={!this.state.hasRights}
                id="km"
                className="m-2 pr-3"
                name="km"
                placeholder={this.translatorService.Tranlate(
                  'VEHICLE_ADVERT_FORM_KM',
                  'Kilometraj'
                )}
                value={this.state.vehicleAdvertData.kilometersNr}
                onChange={(e: any) => {
                  this.setState({
                    vehicleAdvertData: {
                      ...this.state.vehicleAdvertData,
                      kilometersNr: e.target.value
                    }
                  });
                }}
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={this.translatorService.Tranlate('VEHICLE_ADVERT_FORM_KM', 'Kilometraj')}
                InputProps={{
                  inputComponent: this.NumberFormatCustom
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div className="text-left">
                <Autocomplete
                  disableClearable
                  disabled={!this.state.hasRights}
                  id="origin-country"
                  className="m-2"
                  options={this.state.originCountriesTypes}
                  value={this.state.vehicleAdvertData.countryOrigin}
                  placeholder={this.translatorService.Tranlate(
                    'VEHICLE_ADVERT_FORM_ORIGIN_COUNTRY',
                    'Tara de origine'
                  )}
                  onChange={(e: any, newValue: IReferential | null) =>
                    this.handleAdvertOriginCountryTypeChange(newValue)
                  }
                  getOptionLabel={(option: IReferential) => option.displayName || ''}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      name="countryOrigin"
                      validators={['required']}
                      value={this.state.vehicleAdvertData.countryOrigin}
                      errorMessages={[
                        this.translatorService.Tranlate(
                          'VALIDATORS_REQUIRED',
                          'Campul este obligatoriu'
                        )
                      ]}
                      label={this.translatorService.Tranlate(
                        'VEHICLE_ADVERT_FORM_ORIGIN_COUNTRY',
                        'Tara de origine'
                      )}
                      fullWidth
                    />
                  )}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <TextValidator
                fullWidth
                disabled={!this.state.hasRights}
                id="vin"
                className="m-2 pr-3"
                name="vin"
                placeholder={this.translatorService.Tranlate('VEHICLE_ADVERT_FORM_VIN', 'VIN')}
                value={this.state.vehicleAdvertData.vin}
                onChange={(e: any) => {
                  this.setState({
                    vehicleAdvertData: {
                      ...this.state.vehicleAdvertData,
                      vin: e.target.value
                    }
                  });
                }}
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={this.translatorService.Tranlate('VEHICLE_ADVERT_FORM_VIN', 'VIN')}
              />
            </Grid>
            <Grid item xs={6}>
              <div className="text-left">
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={MomentUtils}
                  locale={this.props.appState.language}
                >
                  <DatePicker
                    fullWidth
                    className="mt-2 ml-2 pr-3"
                    variant="inline"
                    openTo="date"
                    views={['year', 'month', 'date']}
                    format={this.props.appState.dateFormat.toUpperCase()}
                    margin="normal"
                    id="first-registration-date"
                    label={this.translatorService.Tranlate(
                      'CAR_DETAILS_YEAR_OF_FIRST_REGISTRATION',
                      'Data primei inmatriculari'
                    )}
                    value={this.state.vehicleAdvertData.firstRegistrationDate}
                    onChange={this.handleFirstRegistrationDateChange}
                    autoOk={true}
                    // error={this.state.datePickerYearOfFabricationError}
                    // helperText={this.state.datePickerYearOfFabricationError === true ? this.translatorService.Tranlate("CAR_DETAILS_YEAR_OF_FABRICATION_ERROR", "Data fabricatiei trebuie sa fie mai mica decat cea a primei inmatriculari") : ""}
                    // minDate={activeButton === 1 ? new Date() : this.state.minDate}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Autocomplete
                disabled={!this.state.hasRights}
                id="fuel"
                className="m-2"
                options={this.state.fuelTypes}
                value={this.state.vehicleAdvertData.fuelType}
                onChange={(e: any, newValue: IReferential | null) =>
                  this.handleFuelTypeChange(newValue)
                }
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="fuel"
                    value={this.state.vehicleAdvertData.fuelType}
                    label={this.translatorService.Tranlate(
                      'VEHICLE_ADVERT_FORM_FUEL',
                      'Combustibil'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <div className="text-left">
                <FormControlLabel
                  control={
                    <Switch
                      disabled={!this.state.hasRights}
                      checked={this.state.vehicleAdvertData.isRegistered}
                      onChange={this.handleSwitchValue('isRegistered')}
                      color="primary"
                    />
                  }
                  label={this.translatorService.Tranlate(
                    'VEHICLE_ADVERT_FORM_HAS_REGISTERED',
                    'Inmatriculat'
                  )}
                  labelPlacement="end"
                  className="mt-3 ml-0 mr-0"
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <TextValidator
                fullWidth
                disabled={true}
                id="hp"
                className="m-2 pr-3"
                name="hp"
                placeholder={this.translatorService.Tranlate(
                  'VEHICLE_ADVERT_FORM_HP',
                  'Putere (CP)'
                )}
                value={this.state.vehicleAdvertData.horsePower}
                onChange={(e: any) => {
                  this.setState({
                    vehicleAdvertData: {
                      ...this.state.vehicleAdvertData,
                      horsePower: e.target.value
                    }
                  });
                }}
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={this.translatorService.Tranlate('VEHICLE_ADVERT_FORM_HP', 'Putere')}
                InputProps={{
                  inputComponent: this.NumberFormatCustom
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div className="text-left">
                <FormControlLabel
                  control={
                    <Switch
                      disabled={!this.state.hasRights}
                      checked={this.state.vehicleAdvertData.isFirstOwner}
                      onChange={this.handleSwitchValue('isFirstOwner')}
                      color="primary"
                    />
                  }
                  label={this.translatorService.Tranlate(
                    'VEHICLE_ADVERT_FORM_HAS_FIRST_OWNER',
                    'Primul proprietar'
                  )}
                  labelPlacement="end"
                  className="mt-3 ml-0 mr-0"
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <TextValidator
                fullWidth
                disabled={!this.state.hasRights}
                id="cylinder-capacity"
                className="m-2 pr-3"
                name="cylinderCapacity"
                placeholder={this.translatorService.Tranlate(
                  'VEHICLE_ADVERT_FORM_CM3',
                  'Capacitate cilindrica'
                )}
                value={this.state.vehicleAdvertData.engineSize}
                onChange={(e: any) => {
                  this.setState({
                    vehicleAdvertData: {
                      ...this.state.vehicleAdvertData,
                      engineSize: e.target.value
                    }
                  });
                }}
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
                label={this.translatorService.Tranlate(
                  'VEHICLE_ADVERT_FORM_CM3',
                  'Capacitate cilindrica'
                )}
                InputProps={{
                  inputComponent: this.NumberFormatCustom
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <div className="text-left">
                <FormControlLabel
                  control={
                    <Switch
                      disabled={!this.state.hasRights}
                      checked={this.state.vehicleAdvertData.hasAccident}
                      onChange={this.handleSwitchValue('hasAccident')}
                      color="primary"
                    />
                  }
                  label={this.translatorService.Tranlate(
                    'VEHICLE_ADVERT_FORM_HAS_FIRST_ACCIDENT',
                    'Fara accident in istoric'
                  )}
                  labelPlacement="end"
                  className="mt-3 ml-0 mr-0"
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Autocomplete
                disabled={!this.state.hasRights}
                id="transmission"
                className="m-2"
                options={this.state.transmissionTypes}
                value={this.state.vehicleAdvertData.tractionType}
                onChange={(e: any, newValue: IReferential | null) =>
                  this.handleTransmissionTypeChange(newValue)
                }
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="fuel"
                    value={this.state.vehicleAdvertData.tractionType}
                    label={this.translatorService.Tranlate(
                      'VEHICLE_ADVERT_FORM_TRANSMISSION',
                      'Transmisie'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <div className="text-left">
                <FormControlLabel
                  control={
                    <Switch
                      disabled={!this.state.hasRights}
                      checked={this.state.vehicleAdvertData.hasServiceBook}
                      onChange={this.handleSwitchValue('hasServiceBook')}
                      color="primary"
                    />
                  }
                  label={this.translatorService.Tranlate(
                    'VEHICLE_ADVERT_FORM_HAS_SERVICE_BOOK',
                    'Carte de service'
                  )}
                  labelPlacement="end"
                  className="mt-3 ml-0 mr-0"
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Autocomplete
                disabled={!this.state.hasRights}
                id="gear-box"
                className="m-2"
                options={this.state.gearBoxTypes}
                value={this.state.vehicleAdvertData.transmissionType}
                onChange={(e: any, newValue: IReferential | null) =>
                  this.handleGearBoxTypeChange(newValue)
                }
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="fuel"
                    value={this.state.vehicleAdvertData.transmissionType}
                    label={this.translatorService.Tranlate(
                      'VEHICLE_ADVERT_FORM_GEAR_BOX',
                      'Cutie de viteze'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <div className="text-left">
                <FormControlLabel
                  name="isNew"
                  control={
                    <Switch
                      disabled={!this.state.hasRights}
                      checked={this.state.vehicleAdvertData.isNew}
                      onChange={this.handleSwitchValue('isNew')}
                      color="primary"
                    />
                  }
                  label={this.translatorService.Tranlate('VEHICLE_ADVERT_FORM_IS_NEW', 'Nou')}
                  labelPlacement="end"
                  className="mt-3 ml-0 mr-0"
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Autocomplete
                disabled={!this.state.hasRights}
                id="pollution-norm"
                className="m-2"
                options={this.state.pollutionNormTypes}
                value={this.state.vehicleAdvertData.pollutionNorm}
                onChange={(e: any, newValue: IReferential | null) =>
                  this.handlePollutionNormTypeChange(newValue)
                }
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="pollutionNorm"
                    value={this.state.vehicleAdvertData.pollutionNorm}
                    label={this.translatorService.Tranlate(
                      'VEHICLE_ADVERT_FORM_POLLUTION_NORM',
                      'Norma de poluare'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <div className="text-left">
                <FormControlLabel
                  name="hasParticlesFilter"
                  control={
                    <Switch
                      disabled={!this.state.hasRights}
                      checked={this.state.vehicleAdvertData.hasParticlesFilter}
                      onChange={this.handleSwitchValue('hasParticlesFilter')}
                      color="primary"
                    />
                  }
                  label={this.translatorService.Tranlate(
                    'VEHICLE_ADVERT_FORM_HAS_PARTICLES_FILTER',
                    'Filtru de particule'
                  )}
                  labelPlacement="end"
                  className="mt-3 ml-0 mr-0"
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className="text-left">
                <TextValidator
                  fullWidth
                  disabled={!this.state.hasRights}
                  id="co2-emission"
                  className="m-2 pr-3"
                  name="co2Emission"
                  placeholder={this.translatorService.Tranlate(
                    'VEHICLE_ADVERT_FORM_CO2_EMISSION',
                    'Emisii CO2'
                  )}
                  value={this.state.vehicleAdvertData.co2Emission}
                  onChange={(e: any) => {
                    this.setState({
                      vehicleAdvertData: {
                        ...this.state.vehicleAdvertData,
                        co2Emission: e.target.value
                      }
                    });
                  }}
                  validators={['required']}
                  errorMessages={[
                    this.translatorService.Tranlate(
                      'VALIDATORS_REQUIRED',
                      'Campul este obligatoriu'
                    )
                  ]}
                  label={this.translatorService.Tranlate(
                    'VEHICLE_ADVERT_FORM_CO2_EMISSION',
                    'Emisii CO2'
                  )}
                  InputProps={{
                    inputComponent: this.NumberFormatCustom
                  }}
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className="text-left">
                <FormControlLabel
                  name="isActiveFinanceEligibility"
                  control={
                    <Switch
                      disabled={!this.state.hasRights}
                      checked={this.state.vehicleAdvertData.hasFinanceEligibility}
                      onChange={this.handleSwitchValue('hasFinanceEligibility')}
                      color="primary"
                    />
                  }
                  label={this.translatorService.Tranlate(
                    'VEHICLE_ADVERT_FORM_HAS_FINANCE_ELIGIBILITY',
                    'Eligibil pentru finantare'
                  )}
                  labelPlacement="end"
                  className="mt-3 ml-0 mr-0"
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <div className="text-left">
                <TextValidator
                  fullWidth
                  disabled={!this.state.hasRights}
                  id="nr-of-doors"
                  className="m-2 pr-3"
                  name="nrOfDoors"
                  placeholder={this.translatorService.Tranlate(
                    'VEHICLE_ADVERT_FORM_NR_OF_DOORS',
                    'Numar de portiere'
                  )}
                  value={this.state.vehicleAdvertData.nrOfDoors}
                  onChange={(e: any) => {
                    this.setState({
                      vehicleAdvertData: {
                        ...this.state.vehicleAdvertData,
                        nrOfDoors: e.target.value
                      }
                    });
                  }}
                  validators={['required']}
                  errorMessages={[
                    this.translatorService.Tranlate(
                      'VALIDATORS_REQUIRED',
                      'Campul este obligatoriu'
                    )
                  ]}
                  label={this.translatorService.Tranlate(
                    'VEHICLE_ADVERT_FORM_NR_OF_DOORS',
                    'Numar de portiere'
                  )}
                  InputProps={{
                    inputComponent: this.NumberFormatCustom
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="text-left">
                <Autocomplete
                  disabled={!this.state.hasRights}
                  id="car-body"
                  className="m-2"
                  options={this.state.carBodyTypes}
                  value={this.state.vehicleAdvertData.bodyType}
                  onChange={(e: any, newValue: IReferential | null) =>
                    this.handleCarBodyTypeChange(newValue)
                  }
                  getOptionLabel={(option: IReferential) => option.displayName || ''}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      name="carBody"
                      value={this.state.vehicleAdvertData.bodyType}
                      label={this.translatorService.Tranlate(
                        'VEHICLE_ADVERT_FORM_CAR_BODY',
                        'Caroserie'
                      )}
                      fullWidth
                      validators={['required']}
                      errorMessages={[
                        this.translatorService.Tranlate(
                          'VALIDATORS_REQUIRED',
                          'Campul este obligatoriu'
                        )
                      ]}
                    />
                  )}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <div className="text-left">
                <Autocomplete
                  disableClearable
                  disabled={!this.state.hasRights}
                  id="guarantee-type"
                  className="m-2"
                  options={this.state.guaranteeTypes}
                  value={this.state.vehicleAdvertData.guaranteeType}
                  placeholder={this.translatorService.Tranlate(
                    'SALE_OFFER_GUARANTEE_TYPE',
                    'Tip garantie'
                  )}
                  onChange={(e: any, newValue: IReferential | null) =>
                    this.handleGuaranteeTypeChange(newValue)
                  }
                  getOptionLabel={(option: IReferential) => option.displayName || ''}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      name="guaranteeType"
                      validators={['required']}
                      value={this.state.vehicleAdvertData.guaranteeType}
                      errorMessages={[
                        this.translatorService.Tranlate(
                          'VALIDATORS_REQUIRED',
                          'Campul este obligatoriu'
                        )
                      ]}
                      label={this.translatorService.Tranlate(
                        'SALE_OFFER_GUARANTEE_TYPE',
                        'Tip garantie'
                      )}
                      fullWidth
                    />
                  )}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <div className="text-left">
                <FormControlLabel
                  name="hasStandardImages"
                  control={
                    <Switch
                      disabled={!this.state.hasRights}
                      checked={this.state.vehicleAdvertData.hasStandardImages}
                      onChange={this.handleSwitchValue('hasStandardImages')}
                      color="primary"
                    />
                  }
                  label={this.translatorService.Tranlate(
                    'VEHICLE_ADVERT_FORM_HAS_STANDARD_IMAGES',
                    'Fotografii Standard'
                  )}
                  labelPlacement="end"
                  className="mt-3 ml-0 mr-0"
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="text-left">
                <FormControlLabel
                  name="hasOptionalImages"
                  control={
                    <Switch
                      disabled={!this.state.hasRights}
                      checked={this.state.vehicleAdvertData.hasOptionalImages}
                      onChange={this.handleSwitchValue('hasOptionalImages')}
                      color="primary"
                    />
                  }
                  label={this.translatorService.Tranlate(
                    'VEHICLE_ADVERT_FORM_HAS_OPTIONAL_IMAGES',
                    'Fotografii Optionale'
                  )}
                  labelPlacement="end"
                  className="mt-3 ml-0 mr-0"
                />
              </div>
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
          <div className="optional-car-options">
            <h5 className="bg-first text-left mt-3 p-1" style={{ color: 'white' }}>
              {this.translatorService.Tranlate(
                'VEHICLE_ADVERT_FORM_OPTIONS_TITLE',
                'Dotari standard + optionale '
              )}
            </h5>
            {this.state.vehicleOptions ? this.renderCarOptionsTable() : ''}
          </div>
        </div>
        <Modal open={this.state.isOpen} onClose={this.handleCloseModal}>
          <Dialog open={this.state.dialogVisible} onClose={this.handleCloseConfirmationDialog}>
            <DialogContent>
              <DialogContentText style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                {`${this.translatorService.Tranlate(
                  'VEHICLE_OPTION_CONFIRMATION_MESSAGE',
                  'Nu a fost selectata nicio optiune de echipare , doriti sa continuati ?'
                )} `}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={() => this.submitData()}>
                {this.translatorService.Tranlate('VEHICLE_SEARCH_FORM_VEHICLE_YES', 'Da')}
              </Button>
              <Button onClick={() => this.handleCloseConfirmationDialog()}>
                {this.translatorService.Tranlate('VEHICLE_SEARCH_FORM_VEHICLE_NO', 'Nu')}
              </Button>
            </DialogActions>
          </Dialog>
        </Modal>
        <Button
          className="m-2"
          variant="contained"
          color="primary"
          type="submit"
          disabled={!this.state.hasRights || this.state.executing}
        >
          {this.translatorService.Tranlate('SAVE', 'Salveaza')}
        </Button>

        <Grid container item xs={12} direction="row" alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <Button
              className="m-2"
              variant="outlined"
              color="secondary"
              disabled={this.publishingAndDeletingAreAllowed(VehicleAdvertOperationTypes.publish)}
              onClick={this.publishAdvert(VehicleAdvertOperationTypes.publish)}
            >
              {this.translatorService.Tranlate(
                'VEHICLE_ADVERT_FORM_PUBLISH_ADVERT',
                'Publica anunt'
              )}
            </Button>
          </Grid>
          {this.renderAdvertisers(VehicleAdvertOperationTypes.publish)}
        </Grid>

        <Grid container item xs={12} direction="row" alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <Button
              className="m-2"
              variant="outlined"
              color="secondary"
              disabled={this.publishingAndDeletingAreAllowed(VehicleAdvertOperationTypes.delete)}
              onClick={this.deleteAdvert(VehicleAdvertOperationTypes.delete)}
            >
              {this.translatorService.Tranlate('VEHICLE_ADVERT_FORM_DELETE_ADVERT', 'Sterge anunt')}
            </Button>
          </Grid>
          {this.renderAdvertisers(VehicleAdvertOperationTypes.delete)}
        </Grid>
      </ValidatorForm>
    );
  };

  private optionIsChecked(option: VehicleOption): boolean | undefined {
    const res = (this.state.vehicleOptions.find((op) => op.id === option.id) as any).checked;
    return res || false;
  }

  private isChecked(operationType: string, advertiserCode: string): boolean | undefined {
    const result = this.state.advertiserOperations.find(
      (item) => item.operationType === operationType && item.advertiserCode === advertiserCode
    );
    return result === undefined ? false : result.checked;
  }

  private publishingAndDeletingAreAllowed(operationType: string): boolean {
    const selectedAdvertiserCodes = this.state.advertiserOperations.find(
      (item) => item.operationType === operationType && item.checked === true
    );

    return (
      !this.state.hasRights ||
      this.state.executing ||
      this.state.vehicleAdvertData.id === 0 ||
      !selectedAdvertiserCodes ||
      selectedAdvertiserCodes.length === 0
    );
  }

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.vehicleService = (this.context as AppContext).vehicleService;
    this.appReferentialService = (this.context as AppContext).referentialService;
    this.appUserService = (this.context as AppContext).appUserService;
    this.reportService = (this.context as AppContext).reportsService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <div className="text-center m-4">{this.rendeVehicleAdvertForm()}</div>
        ) : null}

        <Divider></Divider>
        {this.state.vehicleAdvertHistory.length > 0 ? (
          <div className="ml-4 mt-4 timeline-container timeline-list timeline-list--dark text-left">
            {this.showOfferTimeline()}
          </div>
        ) : (
          ''
        )}
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalCaseDetailsCaseTabVwehicleAdvertProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app,
    vehicleState: state.vehicle
  }),
  CaseSettingsActionCreators,
  mergeProps
)(withSnackbar(CaseDetailsCaseTabVehicleAdvertComponent as any));
