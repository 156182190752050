import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { Button, Checkbox, FormControlLabel, Grid, Switch, Typography } from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import { WorkflowSectionStepForm } from '../../interfaces/Workflow';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { AppUser } from '../../interfaces/AppUser';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { FormHelpers } from '../../helpers/forms/FormHelpers';
import NumberFormat from 'react-number-format';
import * as R from 'ramda';
import { Appointment, CaseVehicleDetails } from '../../interfaces/Case';
import { Utils } from '../../helpers/Utils';
import { IReferential } from '../../interfaces/IReferential';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import _ from 'lodash';
import { CalculationTypeCode, CountryCodeEnum } from '../../helpers/Constants';

interface ICaseDetailsCaseTabInOutDataFormState {
  isLoading: boolean;
  hasRights: boolean;
  executing: boolean;
  caseVehicleDetails: CaseVehicleDetails;
  repairDateError: string;
  appointment: Appointment | null;
  minDate: string | null;
}

class LocalizedUtils extends MomentUtils {
  // dateFormat = "DD MM YYYY";
}


export interface ExternalCaseDetailsCaseTabInOutDataFormProps {
  workflowForm: WorkflowSectionStepForm;
}

type ICaseDetailsCaseTabInOutDataFormProps = ExternalCaseDetailsCaseTabInOutDataFormProps & {
  caseSettingsState: CaseSettingsState;
  appState: AppState;
} & typeof CaseSettingsActionCreators &
  ProviderContext &
  RouteComponentProps<{ id: string }>;

class CaseDetailsCaseTabInOutDataForm extends React.PureComponent<
  ICaseDetailsCaseTabInOutDataFormProps,
  ICaseDetailsCaseTabInOutDataFormState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;

  static contextType = ApplicationContext;
  state = {
    isLoading: false,
    hasRights: false,
    executing: false,
    caseVehicleDetails: {
      id: 0,
      caseId: this.props.caseSettingsState.case!.id,
      repairDate: null,
      hasOkWindows: false,
      hasGlitchWindows: false,
      hasOkLights: false,
      hasGlitchLights: false,
      hasOkBodyAndPaint: false,
      hasGlitchBodyAndPaint: false,
      hasOkWipers: false,
      hasGlitchWipers: false,
      fuelInTheTank1: false,
      fuelInTheTank2: false,
      fuelInTheTank3: false,
      fuelInTheTank4: false,
      fuelInTheTank5: false,
      mandatoryEquipment1: true,
      mandatoryEquipment2: true,
      mandatoryEquipment3: true,
      mandatoryEquipment4: true,
      mandatoryEquipment5: true,
      otherEquipment1: false,
      otherEquipment2: false,
      otherEquipment3: false,
      otherEquipment4: false,
      otherEquipment5: false,
      hasPickUp: false,
      replacementVehicle: false,
      documentsReceived1: false,
      documentsReceived2: false,
      documentsReceived3: false,
      documentsReceived4: false
    } as CaseVehicleDetails,
    appointment: null,
    minDate: null
  } as ICaseDetailsCaseTabInOutDataFormState;

  inputTextValidator = React.createRef<TextValidator>();

  public componentDidMount() {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (R.isNil(caseSettings) || Number.isNaN(caseId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadCaseInOutDataForm();
      }
    );
  }

  loadCaseInOutDataForm = async () => {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (R.isNil(caseSettings) || Number.isNaN(caseId)) {
      return;
    }
    
    this.setState({
      ...this.state,
      isLoading: true
    });

    const [appointment, lastApprovedCaseCalculationHistory] = await Promise.all([
      this.props.workflowForm.appointmentCategoryTypeId != null ? this.caseService.GetAppointmentByType(caseId, this.props.workflowForm.appointmentCategoryTypeId) : null,
      this.caseService.GetLastApprovedCaseCalculationHistory(caseId, CalculationTypeCode.ALT.toString())
    ]);

    console.log('Programare asociata: ', appointment);
    if (appointment == null) {
      // this.props.enqueueSnackbar(this.translatorService.Tranlate('NO_APPOINTMENT_FOUND', 'Nu s-a gasit programarea asociata!'), {
      //   variant: 'warning'
      // });

      this.setState({
        ...this.state,
        isLoading: false
      });  

      return;
    }

    let caseVehicleDetails = await this.caseService.GetCurrentVehicleDetails(appointment.id);
    if (R.isNil(caseVehicleDetails)) {
      caseVehicleDetails = _.cloneDeep(this.state.caseVehicleDetails);
      caseVehicleDetails.repairDate = appointment.date ? moment(appointment.date).format('YYYY-MM-DD') : null;
      caseVehicleDetails.repairPrice = lastApprovedCaseCalculationHistory != null ? lastApprovedCaseCalculationHistory.amount * 1.1 : caseVehicleDetails.repairPrice;
      caseVehicleDetails.appointmentId = appointment.id;
    }
    
    console.log('caseVehicleDetails', caseVehicleDetails);
    
    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      this.props.caseSettingsState.case!.caseStatus.caseStatusId
    );

    this.setState({
      ...this.state,
      isLoading: false,
      hasRights: hasRights,
      caseVehicleDetails: caseVehicleDetails,
      appointment: appointment,
      minDate: appointment && appointment.date ? moment(appointment.date).format('YYYY-MM-DD') : null
    });
  };


  submitForm = async (caseId: number, caseSectionStepFormId: number, appUser: AppUser | null) => {
    let newStatus = await this.caseService.SubmitForm(caseId, caseSectionStepFormId, appUser);

    if (newStatus === null) {
      newStatus = this.props.caseSettingsState.case!.caseStatus;
    }

    this.props.SetCaseStatus(newStatus);
    if (appUser !== null) {
      this.props.AddPartner(appUser!.hoId === null ? appUser!.organizationId : appUser!.hoId);
    }

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      newStatus.caseStatusId
    );
    this.setState({ hasRights: hasRights });
  };

  isAllowed = (values: any) => {
    const { floatValue } = values;
    console.log('isAllowed', floatValue);
    return floatValue === undefined || floatValue <= 999999.99;
  };

  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;
    const countryCode = (this.props.appState.appUser?.organization?.country ?? null)?.code ?? CountryCodeEnum.RO.toString();
    if (countryCode == CountryCodeEnum.CZ.toString()) {
      return (
        <NumberFormat
          {...other}
          getInputRef={inputRef}
          onValueChange={(values: any) => {
            onChange({
              target: {
                value: values.value
              }
            });
          }}
          allowNegative={false}
          thousandSeparator=" "
          decimalSeparator=","
          decimalScale={2}
          fixedDecimalScale={true}
          isAllowed={this.isAllowed}
        />
      );
    }

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            },
            currentTarget: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={2}
      />
    );

  };

  submitCaseVehicleDetailsData = async (e: any) => {
    try {
      if (this.state.caseVehicleDetails && !this.state.caseVehicleDetails.repairDate) {  
        this.props.enqueueSnackbar(this.translatorService.Tranlate('VALIDATORS_REQUIRED_REPAIR_DATE', 'Campul estimare data reparatie este obligatoriu!'), {
          variant: 'error'
        }); 
        return;
      }

      this.setState({ executing: true });

      let newCaseVehicleDetails = {
        ...this.state.caseVehicleDetails
      };

      if (this.state.caseVehicleDetails.id === 0) {
        newCaseVehicleDetails = await this.caseService.AddCaseVehicleDetails(this.state.caseVehicleDetails);
      } else {
        await this.caseService.UpdateCaseVehicleDetails(newCaseVehicleDetails);
      }

      await this.submitForm(
        this.props.caseSettingsState.case!.id,
        this.props.workflowForm.id,
        null
      );

      await this.loadCaseInOutDataForm();

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (ex) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    } finally {
      this.setState({ executing: false });
    }
  };

  onChange =
  (fieldName: string, fieldNameId?: string) => (e: any, newValue?: IReferential | null) => {
    !R.isNil(fieldNameId)
      ? this.setState({
          caseVehicleDetails: {
            ...this.state.caseVehicleDetails,
            [fieldName]: newValue || e.target.value,
            [fieldNameId]: Utils.GetIdOrNull(newValue)
          }
        })
      : this.setState({
        caseVehicleDetails: {
            ...this.state.caseVehicleDetails,
            [fieldName]: newValue || e.target.value
          }
        });
  };

  onChangeCheckedField =  (fieldName: string, fieldNameId?: string) => (e: any)  => {
    !R.isNil(fieldNameId)
    ? this.setState({
        caseVehicleDetails: {
          ...this.state.caseVehicleDetails,
          [fieldName]: e.target.value.checked
        }
      })
    : this.setState({
      caseVehicleDetails: {
          ...this.state.caseVehicleDetails,
          [fieldName]: e.target.value.checked
        }
      });
  };

  handleHasOkWindows = (e: any) => {
    this.setState({
      caseVehicleDetails: {
        ...this.state.caseVehicleDetails,
        hasOkWindows: e.currentTarget.checked,
        hasGlitchWindows: !e.currentTarget.checked
      }
    });
  };

  handleHasOkLights = (e: any) => {
    this.setState({
      caseVehicleDetails: {
        ...this.state.caseVehicleDetails,
        hasOkLights: e.currentTarget.checked,
        hasGlitchLights: !e.currentTarget.checked
      }
    });
  };

  handleHasOkBodyAndPaint = (e: any) => {
    this.setState({
      caseVehicleDetails: {
        ...this.state.caseVehicleDetails,
        hasOkBodyAndPaint: e.currentTarget.checked,
        hasGlitchBodyAndPaint: !e.currentTarget.checked
      }
    });
  };

  handleHasOkWipers = (e: any) => {
    this.setState({
      caseVehicleDetails: {
        ...this.state.caseVehicleDetails,
        hasOkWipers: e.currentTarget.checked,
        hasGlitchWipers: !e.currentTarget.checked
      }
    });
  };

  handleCheckBoxFuelInTheTank1Change = async (checked: boolean) => {
    this.setState({
      caseVehicleDetails: {
        ...this.state.caseVehicleDetails,
        fuelInTheTank1: checked,
        fuelInTheTank2: checked ? false : this.state.caseVehicleDetails.fuelInTheTank2,
        fuelInTheTank3: checked ? false : this.state.caseVehicleDetails.fuelInTheTank3,
        fuelInTheTank4: checked ? false : this.state.caseVehicleDetails.fuelInTheTank4,
      }
    });
  }

  handleCheckBoxFuelInTheTank2Change = async (checked: boolean) => {
    this.setState({
      caseVehicleDetails: {
        ...this.state.caseVehicleDetails,
        fuelInTheTank2: checked,
        fuelInTheTank1: checked ? false : this.state.caseVehicleDetails.fuelInTheTank1,
        fuelInTheTank3: checked ? false : this.state.caseVehicleDetails.fuelInTheTank3,
        fuelInTheTank4: checked ? false : this.state.caseVehicleDetails.fuelInTheTank4,
        fuelInTheTank5: checked ? false : this.state.caseVehicleDetails.fuelInTheTank5
      }
    });
  }

  handleCheckBoxFuelInTheTank3Change = async (checked: boolean) => {
    this.setState({
      caseVehicleDetails: {
        ...this.state.caseVehicleDetails,
        fuelInTheTank3: checked,
        fuelInTheTank1: checked ? false : this.state.caseVehicleDetails.fuelInTheTank1,
        fuelInTheTank2: checked ? false : this.state.caseVehicleDetails.fuelInTheTank2,
        fuelInTheTank4: checked ? false : this.state.caseVehicleDetails.fuelInTheTank4,
        fuelInTheTank5: checked ? false : this.state.caseVehicleDetails.fuelInTheTank5
      }
    });
  }

  handleCheckBoxFuelInTheTank4Change = async (checked: boolean) => {
    this.setState({
      caseVehicleDetails: {
        ...this.state.caseVehicleDetails,
        fuelInTheTank4: checked,
        fuelInTheTank1: checked ? false : this.state.caseVehicleDetails.fuelInTheTank1,
        fuelInTheTank2: checked ? false : this.state.caseVehicleDetails.fuelInTheTank2,
        fuelInTheTank3: checked ? false : this.state.caseVehicleDetails.fuelInTheTank3,
        fuelInTheTank5: checked ? false : this.state.caseVehicleDetails.fuelInTheTank5
      }
    });
  }

  handleCheckBoxFuelInTheTank5Change = async (checked: boolean) => {
    this.setState({
      caseVehicleDetails: {
        ...this.state.caseVehicleDetails,
        fuelInTheTank5: checked,
        fuelInTheTank1: checked ? false : this.state.caseVehicleDetails.fuelInTheTank1,
        fuelInTheTank2: checked ? false : this.state.caseVehicleDetails.fuelInTheTank2,
        fuelInTheTank3: checked ? false : this.state.caseVehicleDetails.fuelInTheTank3,
        fuelInTheTank4: checked ? false : this.state.caseVehicleDetails.fuelInTheTank4
      }
    });
  }

  handleCheckBoxMandatoryEquipment1Change = async (checked: boolean) => {
    this.setState({
      caseVehicleDetails: {
        ...this.state.caseVehicleDetails,
        mandatoryEquipment1: checked
      }
    });
  }

  handleCheckBoxMandatoryEquipment2Change = async (checked: boolean) => {
    this.setState({
      caseVehicleDetails: {
        ...this.state.caseVehicleDetails,
        mandatoryEquipment2: checked
      }
    });
  }

  handleCheckBoxMandatoryEquipment3Change = async (checked: boolean) => {
    this.setState({
      caseVehicleDetails: {
        ...this.state.caseVehicleDetails,
        mandatoryEquipment3: checked
      }
    });
  }

  handleCheckBoxMandatoryEquipment4Change = async (checked: boolean) => {
    this.setState({
      caseVehicleDetails: {
        ...this.state.caseVehicleDetails,
        mandatoryEquipment4: checked
      }
    });
  }

  handleCheckBoxMandatoryEquipment5Change = async (checked: boolean) => {
    this.setState({
      caseVehicleDetails: {
        ...this.state.caseVehicleDetails,
        mandatoryEquipment5: checked
      }
    });
  }

  handleCheckBoxOtherEquipment1Change = async (checked: boolean) => {
    this.setState({
      caseVehicleDetails: {
        ...this.state.caseVehicleDetails,
        otherEquipment1: checked
      }
    });
  }

  handleCheckBoxOtherEquipment2Change = async (checked: boolean) => {
    this.setState({
      caseVehicleDetails: {
        ...this.state.caseVehicleDetails,
        otherEquipment2: checked
      }
    });
  }

  handleCheckBoxOtherEquipment3Change = async (checked: boolean) => {
    this.setState({
      caseVehicleDetails: {
        ...this.state.caseVehicleDetails,
        otherEquipment3: checked
      }
    });
  }
  
  handleCheckBoxOtherEquipment4Change = async (checked: boolean) => {
    this.setState({
      caseVehicleDetails: {
        ...this.state.caseVehicleDetails,
        otherEquipment4: checked
      }
    });
  }

  handleCheckBoxOtherEquipment5Change = async (checked: boolean) => {
    this.setState({
      caseVehicleDetails: {
        ...this.state.caseVehicleDetails,
        otherEquipment5: checked
      }
    });
  }

  handleHasPickUp = (e: any) => {
    this.setState({
      caseVehicleDetails: {
        ...this.state.caseVehicleDetails,
        hasPickUp: e.currentTarget.checked
      }
    });
  };

  handleReplacementVehicle = (e: any) => {
    this.setState({
      caseVehicleDetails: {
        ...this.state.caseVehicleDetails,
        replacementVehicle: e.currentTarget.checked
      }
    });
  };

  handleCheckBoxDocumentsReceived1Change = (checked: boolean) => {
    this.setState({
      caseVehicleDetails: {
        ...this.state.caseVehicleDetails,
        documentsReceived1: checked
      }
    });
  }

  handleCheckBoxDocumentsReceived2Change = (checked: boolean) => {
    this.setState({
      caseVehicleDetails: {
        ...this.state.caseVehicleDetails,
        documentsReceived2: checked
      }
    });
  }

  handleCheckBoxDocumentsReceived3Change = (checked: boolean) => {
    this.setState({
      caseVehicleDetails: {
        ...this.state.caseVehicleDetails,
        documentsReceived3: checked
      }
    });
  }
  
  handleCheckBoxDocumentsReceived4Change = (checked: boolean) => {
    this.setState({
      caseVehicleDetails: {
        ...this.state.caseVehicleDetails,
        documentsReceived4: checked
      }
    });
  }

  
  handleRepairDateChange = (
    date: MaterialUiPickersDate | null,
    value?: string | null
  ) => {
    if (date === null || !date.isValid) {
      return;
    }

    this.setState({
      caseVehicleDetails: {
        ...this.state.caseVehicleDetails,
        repairDate: date.format('YYYY-MM-DD'),
      },
      repairDateError: ''
    });
  };

  validateDates = (repairDate: string | null): void => {
    /*
    Validate repair date
    */
    let repairDateError = '';
    if (!repairDate) {
      repairDateError = this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu');
    } 

    this.setState({
      repairDateError: repairDateError
    });
  }

  rendeCaseVehicleDetailsDataForm = () => {
    return (
      <ValidatorForm
        onSubmit={(e) => {
          this.submitCaseVehicleDetailsData(e);
        }}
      >
        <div className="m-3 text-center">
          <Grid container spacing={0}>
            <Grid item xs={6}>
              {/* VEHICLE_CONDITION_FROM_OUTSIDE_SECTION */}
              <Typography component="div" variant="h4" align="left" className="mt-3">
                {this.translatorService.Tranlate('VEHICLE_DETAILS_REPAIR_DATE', 'Estimare data reparatie')}
              </Typography>
              <Grid item xs={12}>
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={LocalizedUtils}
                  locale={this.props.appState.language}
                >
                  <DatePicker
                    disabled={!this.state.hasRights}
                    fullWidth
                    className="m-2 pr-3"
                    variant="inline"
                    openTo="date"
                    views={['year', 'month', 'date']}
                    format={this.props.appState.dateFormat.toUpperCase()}
                    margin="normal"
                    id="repairDate"
                    label={this.translatorService.Tranlate(
                      'VEHICLE_DETAILS_REPAIR_DATE',
                      'Estimare data reparatie'
                    )}
                    value={this.state.caseVehicleDetails.repairDate || null}
                    onChange={this.handleRepairDateChange}
                    autoOk={true}
                    error={this.state.repairDateError ? true : false}
                    helperText={this.state.repairDateError}
                    required={true}
                    minDate={this.state.minDate ? new Date(this.state.minDate) : new Date()}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              {/* VEHICLE_CONDITION_FROM_OUTSIDE_SECTION */}
              <Typography component="div" variant="h4" align="left" className="mt-3">
                {this.translatorService.Tranlate('VEHICLE_DETAILS_REPAIR_PRICE_SECTION', 'Estimarea cost reparatie')}
              </Typography>
              <Grid item xs={12}>
                <TextValidator
                  fullWidth
                  disabled={!this.state.hasRights}
                  className="m-2 pr-3"
                  name="repairPrice"
                  placeholder={this.translatorService.Tranlate(
                    'VEHICLE_DETAILS_REPAIR_PRICE',
                    'Pret estimativ reparatie'
                  )}
                  value={this.state.caseVehicleDetails.repairPrice}
                  onChange={(e: any) => {
                    this.setState({
                      caseVehicleDetails: {
                        ...this.state.caseVehicleDetails,
                        repairPrice: parseFloat(e.target.value)
                      }
                    });
                  }}
                  validators={['required']}
                  errorMessages={[
                    this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                  ]}
                  label={this.translatorService.Tranlate('VEHICLE_DETAILS_REPAIR_PRICE', 'Pret estimativ reparatie')}
                  required={true}
                  InputProps={{
                    inputComponent: this.NumberFormatCustom,
                    style: { fontWeight: 500 }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* VEHICLE_SERVICE_COMMENT_SECTION */}
          <Typography component="div" variant="h4" align="left" className="mt-3">
            {this.translatorService.Tranlate('VEHICLE_SERVICE_COMMENT_SECTION', 'Comentariu service')}
          </Typography>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <TextValidator
                fullWidth
                disabled={!this.state.hasRights}
                id="serviceComment"
                className="mt-3 m-2 pr-3"
                name="serviceComment"
                placeholder={this.translatorService.Tranlate(
                  'VEHICLE_SERVICE_COMMENT_SECTION',
                  'Comentariu service'
                )}
                value={this.state.caseVehicleDetails.serviceComment || ''}
                onChange={(e: any) => this.onChange('serviceComment')(e)}
                label={this.translatorService.Tranlate(
                  'VEHICLE_SERVICE_COMMENT_SECTION',
                  'Comentariu service'
                )}
                multiline
                variant="outlined"
                rows={2}
              />
            </Grid>
          </Grid>
          {/* VEHICLE_CONDITION_FROM_OUTSIDE_SECTION */}
          <Typography component="div" variant="h4" align="left" className="mt-3">
            {this.translatorService.Tranlate('VEHICLE_CONDITION_FROM_OUTSIDE_SECTION', 'Vehicle condition from the outside')}
          </Typography>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <div className="text-left">
                <FormControlLabel
                  name="hasOkWindows"
                  control={
                    <Switch
                      disabled={!this.state.hasRights}
                      checked={this.state.caseVehicleDetails.hasOkWindows}
                      onChange={(e: any) => this.handleHasOkWindows(e)}
                      color="primary"
                    />
                  }
                  label={this.translatorService.Tranlate(
                    'VEHICLE_DETAILS_HAS_OK_WINDOWS_AND_WINDSHIELD',
                    'Geamuri si parbriz OK'
                  )}
                  labelPlacement="start"
                  className="mt-3 ml-2 mr-0"
                />
              </div>
            </Grid>
            <Grid item xs={8}>
              <TextValidator
                fullWidth
                disabled={!this.state.hasRights}
                id="hasWindowsComment"
                className="m-2 pr-3"
                name="hasWindowsComment"
                placeholder={this.translatorService.Tranlate(
                  'VEHICLE_DETAILS_HAS_OK_WINDOWS_AND_WINDSHIELD_COMMENT',
                  'Observatii geamuri si parbriz'
                )}
                value={this.state.caseVehicleDetails.hasWindowsComment || ''}
                onChange={(e: any) => this.onChange('hasWindowsComment')(e)}
                label={this.translatorService.Tranlate(
                  'VEHICLE_DETAILS_HAS_OK_WINDOWS_AND_WINDSHIELD_COMMENT',
                  'Observatii geamuri si parbriz'
                )}
                multiline
                variant="outlined"
                rows={2}
              />
            </Grid>
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={4}>
              <div className="text-left">
                <FormControlLabel
                  name="hasOkLights"
                  control={
                    <Switch
                      disabled={!this.state.hasRights}
                      checked={this.state.caseVehicleDetails.hasOkLights}
                      onChange={(e: any) => this.handleHasOkLights(e)}
                      color="primary"
                    />
                  }
                  label={this.translatorService.Tranlate(
                    'VEHICLE_DETAILS_HAS_OK_LIGHTS',
                    'Lumini OK'
                  )}
                  labelPlacement="start"
                  className="mt-3 ml-2 mr-0"
                />
              </div>
            </Grid>
            <Grid item xs={8}>
              <TextValidator
                fullWidth
                disabled={!this.state.hasRights}
                id="hasLightsComment"
                className="m-2 pr-3"
                name="hasLightsComment"
                placeholder={this.translatorService.Tranlate(
                  'VEHICLE_DETAILS_HAS_OK_LIGHTS_COMMENT',
                  'Observatii lumini'
                )}
                value={this.state.caseVehicleDetails.hasLightsComment || ''}
                onChange={(e: any) => this.onChange('hasLightsComment')(e)}
                label={this.translatorService.Tranlate(
                  'VEHICLE_DETAILS_HAS_OK_LIGHTS_COMMENT',
                  'Observatii lumini'
                )}
                multiline
                variant="outlined"
                rows={2}
              />
            </Grid>
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={4}>
              <div className="text-left">
                <FormControlLabel
                  name="hasOkBodyAndPaint"
                  control={
                    <Switch
                      disabled={!this.state.hasRights}
                      checked={this.state.caseVehicleDetails.hasOkBodyAndPaint}
                      onChange={(e: any) => this.handleHasOkBodyAndPaint(e)}
                      color="primary"
                    />
                  }
                  label={this.translatorService.Tranlate(
                    'VEHICLE_DETAILS_HAS_OK_BODY_AND_PAINT',
                    'Caroserie si vopsea OK'
                  )}
                  labelPlacement="start"
                  className="mt-3 ml-2 mr-0"
                />
              </div>
            </Grid>
            <Grid item xs={8}>
              <TextValidator
                fullWidth
                disabled={!this.state.hasRights}
                id="hasBodyAndPaintComment"
                className="m-2 pr-3"
                name="hasBodyAndPaintComment"
                placeholder={this.translatorService.Tranlate(
                  'VEHICLE_DETAILS_HAS_OK_BODY_AND_PAINT_COMMENT',
                  'Observatii caroserie si vopsea'
                )}
                value={this.state.caseVehicleDetails.hasBodyAndPaintComment || ''}
                onChange={(e: any) => this.onChange('hasBodyAndPaintComment')(e)}
                label={this.translatorService.Tranlate(
                  'VEHICLE_DETAILS_HAS_OK_BODY_AND_PAINT_COMMENT',
                  'Observatii caroserie si vopsea'
                )}
                multiline
                variant="outlined"
                rows={2}
              />
            </Grid>
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={4}>
              <div className="text-left">
                <FormControlLabel
                  name="hasOkWipers"
                  control={
                    <Switch
                      disabled={!this.state.hasRights}
                      checked={this.state.caseVehicleDetails.hasOkWipers}
                      onChange={(e: any) => this.handleHasOkWipers(e)}
                      color="primary"
                    />
                  }
                  label={this.translatorService.Tranlate(
                    'VEHICLE_DETAILS_HAS_OK_WIPERS',
                    'Stergatoare OK'
                  )}
                  labelPlacement="start"
                  className="mt-3 ml-2 mr-0"
                />
              </div>
            </Grid>
            <Grid item xs={8}>
              <TextValidator
                fullWidth
                disabled={!this.state.hasRights}
                id="hasWipersComment"
                className="m-2 pr-3"
                name="hasWipersComment"
                placeholder={this.translatorService.Tranlate(
                  'VEHICLE_DETAILS_HAS_OK_WIPERS_COMMENT',
                  'Observatii stergatoare'
                )}
                value={this.state.caseVehicleDetails.hasWipersComment || ''}
                onChange={(e: any) => this.onChange('hasWipersComment')(e)}
                label={this.translatorService.Tranlate(
                  'VEHICLE_DETAILS_HAS_OK_WIPERS_COMMENT',
                  'Observatii stergatoare'
                )}
                multiline
                variant="outlined"
                rows={2}
              />
            </Grid>
          </Grid>
          
          {/* FUEL_IN_THE_TANK_SECTION */}
          <Typography component="div" variant="h4" align="left"  className="mt-3">
            {this.translatorService.Tranlate('FUEL_IN_THE_TANK_LABEL', 'Fuel in the tank')}
          </Typography>
          <Grid container spacing={0}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.caseVehicleDetails.fuelInTheTank1}
                  onChange={(e) => this.handleCheckBoxFuelInTheTank1Change(e.target.checked)}
                  disabled={!this.state.hasRights}
                  
              ></Checkbox>
              }
              label={'0'}
              className="mt-3 ml-0 mr-5"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.caseVehicleDetails.fuelInTheTank2}
                  onChange={(e) => this.handleCheckBoxFuelInTheTank2Change(e.target.checked)}
                  disabled={!this.state.hasRights}
                  
              ></Checkbox>
              }
              label={'1/4'}
              className="mt-3 ml-0 mr-5"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.caseVehicleDetails.fuelInTheTank3}
                  onChange={(e) => this.handleCheckBoxFuelInTheTank3Change(e.target.checked)}
                  disabled={!this.state.hasRights}
                  
              ></Checkbox>
              }
              label={'1/2'}
              className="mt-3 ml-0 mr-5"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.caseVehicleDetails.fuelInTheTank4}
                  onChange={(e) => this.handleCheckBoxFuelInTheTank4Change(e.target.checked)}
                  disabled={!this.state.hasRights}
                  
              ></Checkbox>
              }
              label={'3/4'}
              className="mt-3 ml-0 mr-5"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.caseVehicleDetails.fuelInTheTank5}
                  onChange={(e) => this.handleCheckBoxFuelInTheTank5Change(e.target.checked)}
                  disabled={!this.state.hasRights}
                  
              ></Checkbox>
              }
              label={'1'}
              className="mt-3 ml-0 mr-5"
            />
          </Grid>

          {/* MANDATORY_EQUIPMENT_SECTION */}
          <Typography component="div" variant="h4" align="left"  className="mt-3">
            {this.translatorService.Tranlate('MANDATORY_EQUIPMENT_SECTION', 'Mandatory equipment')}
          </Typography>
          <Grid container spacing={0}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.caseVehicleDetails.mandatoryEquipment1}
                  onChange={(e) => this.handleCheckBoxMandatoryEquipment1Change(e.target.checked)}
                  disabled={!this.state.hasRights}
                  
              ></Checkbox>
              }
              label={this.translatorService.Tranlate('MANDATORY_EQUIPMENT_SECTION_1', 'First aid kit')}
              className="mt-3 ml-0 mr-3"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.caseVehicleDetails.mandatoryEquipment2}
                  onChange={(e) => this.handleCheckBoxMandatoryEquipment2Change(e.target.checked)}
                  disabled={!this.state.hasRights}
                  
              ></Checkbox>
              }
              label={this.translatorService.Tranlate('MANDATORY_EQUIPMENT_SECTION_2', 'Itrogens')}
              className="mt-3 ml-0 mr-3"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.caseVehicleDetails.mandatoryEquipment3}
                  onChange={(e) => this.handleCheckBoxMandatoryEquipment3Change(e.target.checked)}
                  disabled={!this.state.hasRights}
                  
              ></Checkbox>
              }
              label={this.translatorService.Tranlate('MANDATORY_EQUIPMENT_SECTION_3', 'Reserve')}
              className="mt-3 ml-0 mr-3"
            />    

            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.caseVehicleDetails.mandatoryEquipment4}
                  onChange={(e) => this.handleCheckBoxMandatoryEquipment4Change(e.target.checked)}
                  disabled={!this.state.hasRights}
                  
              ></Checkbox>
              }
              label={this.translatorService.Tranlate('MANDATORY_EQUIPMENT_SECTION_4', 'Rope')}
              className="mt-3 ml-0 mr-3"
            />  

            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.caseVehicleDetails.mandatoryEquipment5}
                  onChange={(e) => this.handleCheckBoxMandatoryEquipment5Change(e.target.checked)}
                  disabled={!this.state.hasRights}
                  
              ></Checkbox>
              }
              label={this.translatorService.Tranlate('MANDATORY_EQUIPMENT_SECTION_5', 'Light bulbs')}    
              className="mt-3 ml-0 mr-3"
            />  
          </Grid>

          {/* OTHER_EQUIPMENT_SECTION */}
          <Typography component="div" variant="h4" align="left"  className="mt-3">
            {this.translatorService.Tranlate('OTHER_EQUIPMENT_SECTION', 'Other equipment')}
          </Typography>
          <Grid container spacing={0}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.caseVehicleDetails.otherEquipment1}
                  onChange={(e) => this.handleCheckBoxOtherEquipment1Change(e.target.checked)}
                  disabled={!this.state.hasRights}
                  
              ></Checkbox>
              }
              label={this.translatorService.Tranlate('OTHER_EQUIPMENT_SECTION_1', 'Car radio')}
              className="mt-3 ml-0 mr-3"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.caseVehicleDetails.otherEquipment2}
                  onChange={(e) => this.handleCheckBoxOtherEquipment2Change(e.target.checked)}
                  disabled={!this.state.hasRights}
                  
              ></Checkbox>
              }
              label={this.translatorService.Tranlate('OTHER_EQUIPMENT_SECTION_2', 'Navigation')}
              className="mt-3 ml-0 mr-3"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.caseVehicleDetails.otherEquipment3}
                  onChange={(e) => this.handleCheckBoxOtherEquipment3Change(e.target.checked)}
                  disabled={!this.state.hasRights}
                  
              ></Checkbox>
              }
              label={this.translatorService.Tranlate('OTHER_EQUIPMENT_SECTION_3', 'Hands free')}
              className="mt-3 ml-0 mr-3"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.caseVehicleDetails.otherEquipment4}
                  onChange={(e) => this.handleCheckBoxOtherEquipment4Change(e.target.checked)}
                  disabled={!this.state.hasRights}
                  
              ></Checkbox>
              }
              label={this.translatorService.Tranlate('OTHER_EQUIPMENT_SECTION_4', 'Fire extinguisher')}
              className="mt-3 ml-0 mr-3"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.caseVehicleDetails.otherEquipment5}
                  onChange={(e) => this.handleCheckBoxOtherEquipment5Change(e.target.checked)}
                  disabled={!this.state.hasRights}
                  
              ></Checkbox>
              }
              label={this.translatorService.Tranlate('OTHER_EQUIPMENT_SECTION_5', 'Profession')}
              className="mt-3 ml-0 mr-3"
            />

          </Grid>

          {/* REQUIREMENT_SECTION */}
          <Typography component="div" variant="h4" align="left"  className="mt-3">
            {this.translatorService.Tranlate('REQUIREMENT_SECTION', 'Requirement')}
          </Typography>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <div className="text-left">
                <FormControlLabel
                  name="hasPickUp"
                  control={
                    <Switch
                      disabled={!this.state.hasRights}
                      checked={this.state.caseVehicleDetails.hasPickUp}
                      onChange={(e: any) => this.handleHasPickUp(e)}
                      color="primary"
                    />
                  }
                  label={this.translatorService.Tranlate(
                    'VEHICLE_DETAILS_HAS_PICK_UP',
                    'Pick-up'
                  )}
                  labelPlacement="start"
                  className="mt-3 ml-2 mr-0"
                />
              </div>
            </Grid>

            <Grid item xs={4}>
              <TextValidator
                fullWidth
                disabled={!this.state.hasRights}
                id="pickUpCode"
                className="mr-5 pr-3"
                name="pickUpCode"
                placeholder={this.translatorService.Tranlate(
                  'VEHICLE_DETAILS_PICK_UP_CODE',
                  'Pick-up code'
                )}
                value={this.state.caseVehicleDetails.pickUpCode || ''}
                onChange={(e: any) => this.onChange('pickUpCode')(e)}
                label={this.translatorService.Tranlate(
                  'VEHICLE_DETAILS_PICK_UP_CODE',
                  'Pick-up code'
                )}
                multiline
                variant="outlined"
                rows={2}
              />
            </Grid>

            <Grid item xs={4}>
              <div className="text-left">
                <FormControlLabel
                  name="replacementVehicle"
                  control={
                    <Switch
                      disabled={!this.state.hasRights}
                      checked={this.state.caseVehicleDetails.replacementVehicle}
                      onChange={(e: any) => this.handleReplacementVehicle(e)}
                      color="primary"
                    />
                  }
                  label={this.translatorService.Tranlate(
                    'VEHICLE_DETAILS_HAS_REPLACEMENT_VEHICLE',
                    'Replacement vehicle'
                  )}
                  labelPlacement="start"
                  className="mt-3 ml-0 mr-0"
                />
              </div>
            </Grid>
          </Grid>

          {/* DOCUMENTS_RECEIVED_SECTION */}
          <Typography component="div" variant="h4" align="left"  className="mt-3">
            {this.translatorService.Tranlate('DOCUMENTS_RECEIVED_SECTION', 'Documents received')}
          </Typography>
          <Grid container spacing={0}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.caseVehicleDetails.documentsReceived1}
                  onChange={(e) => this.handleCheckBoxDocumentsReceived1Change(e.target.checked)}
                  disabled={!this.state.hasRights}
                  
              ></Checkbox>
              }
              label={this.translatorService.Tranlate('DOCUMENTS_RECEIVED_SECTION_1', 'Large TP')}
              className="mt-3 ml-0 mr-5"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.caseVehicleDetails.documentsReceived2}
                  onChange={(e) => this.handleCheckBoxDocumentsReceived2Change(e.target.checked)}
                  disabled={!this.state.hasRights}
                  
              ></Checkbox>
              }
              label={this.translatorService.Tranlate('DOCUMENTS_RECEIVED_SECTION_2', 'Small TP')}
              className="mt-3 ml-0 mr-5"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.caseVehicleDetails.documentsReceived3}
                  onChange={(e) => this.handleCheckBoxDocumentsReceived3Change(e.target.checked)}
                  disabled={!this.state.hasRights}
                  
              ></Checkbox>
              }
              label={this.translatorService.Tranlate('DOCUMENTS_RECEIVED_SECTION_3', 'Green card')}
              className="mt-3 ml-0 mr-5"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.caseVehicleDetails.documentsReceived4}
                  onChange={(e) => this.handleCheckBoxDocumentsReceived4Change(e.target.checked)}
                  disabled={!this.state.hasRights}
                  
              ></Checkbox>
              }
              label={this.translatorService.Tranlate('DOCUMENTS_RECEIVED_SECTION_4', 'Service book')}
              className="mt-3 ml-0 mr-5"
            />
          </Grid>
        </div>

        <Button
          className="m-2"
          variant="contained"
          color="primary"
          type="submit"
          disabled={!this.state.hasRights || this.state.executing}
          onClick={() => this.validateDates(this.state.caseVehicleDetails.repairDate)}
        >
          {this.translatorService.Tranlate('SAVE', 'Salveaza')}
        </Button>
      </ValidatorForm>
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <div className="text-center m-2">{this.rendeCaseVehicleDetailsDataForm()}</div>
        ) : null}
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalCaseDetailsCaseTabInOutDataFormProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  CaseSettingsActionCreators,
  mergeProps
)(withSnackbar(CaseDetailsCaseTabInOutDataForm as any));
