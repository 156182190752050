import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ApplicationState,
  CaseSettingsState,
  AppState,
  CaseSettingsActionCreators,
  VehicleState
} from '../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import { Button, IconButton, Grid, Divider, FormHelperText } from '@material-ui/core';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import {
  WorkflowFormAction,
  WorkflowPartner,
  WorkflowSectionStep,
  WorkflowSectionStepForm
} from '../../interfaces/Workflow';
import { isNullOrUndefined } from 'util';
import { AcquisitionCaseRequest, CaseAttachment, CaseRequest, GenerateInvoiceRequest } from '../../interfaces/Case';
import { ICaseService } from '../../services/Interfaces/ICaseService';
import { IWorkflowService } from '../../services/Interfaces/IWorkflowService';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import FileSaver from 'file-saver';
import { AppUser } from '../../interfaces/AppUser';
import { IOrganizationService } from '../../services/Interfaces/IOrganizationService';
import { IAppUserService } from '../../services/Interfaces/IAppUserService';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  ReferentialCode,
  AttachmentTypeCode,
  VehicleStatusCodeEnum,
  WorkflowFormActionTypeCode,
  CaseTypeCode,
  aquisitionSteps,
  aquisitionWorkflowSectionSteps,
  smartBillInvoiceWorkflowSectionSteps
} from '../../helpers/Constants';
import Referentials from '../../helpers/Referentials.json';
import { IReferential } from '../../interfaces/IReferential';
import { IReferentialService } from '../../services/Interfaces/IReferentialService';
import { FormHelpers } from '../../helpers/forms/FormHelpers';
import CaseDetailsCaseRequests from '../cases/CaseDetailsCaseRequests';
import { IVehicleService } from '../../services/Interfaces/IVehicleService';
import { Organization } from '../../interfaces/Organization';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';
import clsx from 'clsx';
import { FormCode } from '../../helpers/forms/FormCode';
import { VehicleHelper } from '../../helpers/VehicleHelper';

interface OrganizationBranch {
  id: number;
  organizationId: number;
  organizationName: string;
  organizationAddress: string;
  children: OrganizationBranch[];
  parentId: number | null;
  path: string | null;
  label: string;
  checked: boolean | null;
  organization?: Organization;
}

interface ExtendedWorkflowPartner extends WorkflowPartner {
  organizationBranch?: OrganizationBranch;
}

interface ICaseDetailsCaseTabBackToServiceFormState {
  backToServiceFormData: CaseRequest;
  organizationBranches: OrganizationBranch[];
  organizations: ExtendedWorkflowPartner[];
  selectedOrganization: ExtendedWorkflowPartner | null;
  users: AppUser[];
  selectedUser: AppUser | null;
  attachments: CaseAttachment[];
  isLoading: boolean;
  isLoading2: boolean;
  attachType: IReferential;
  isOrganizationsDisabled: boolean;
  isUsersDisabled: boolean;
  hasRights: boolean;
  isUserHidden: boolean;
  executing: boolean;
  formSubmitted: boolean;
  userRoleId: number | null;
  wfTransitionStepAction: WorkflowFormAction;
}

export interface ExternalCaseDetailsCaseTabBackToServiceFormProps {
  workflowForm: WorkflowSectionStepForm;
}

type ICaseDetailsCaseTabBackToServiceFormProps =
  ExternalCaseDetailsCaseTabBackToServiceFormProps & {
    caseSettingsState: CaseSettingsState;
    appState: AppState;
    vehicleState: VehicleState;
  } & typeof CaseSettingsActionCreators &
    ProviderContext &
    RouteComponentProps<{ id: string }>;

class CaseDetailsCaseTabBackToServiceForm extends React.PureComponent<
  ICaseDetailsCaseTabBackToServiceFormProps,
  ICaseDetailsCaseTabBackToServiceFormState
> {
  private translatorService!: ITranslatorService;
  private caseService!: ICaseService;
  private workflowService!: IWorkflowService;
  private appUserService!: IAppUserService;
  private organizationService!: IOrganizationService;
  private appReferentialServie!: IReferentialService;
  private vehicleService!: IVehicleService;

  static contextType = ApplicationContext;
  state = {
    backToServiceFormData: {} as CaseRequest,
    organizationBranches: [],
    organizations: [],
    selectedOrganization: null,
    users: [],
    selectedUser: null,
    attachments: [],
    isLoading: false,
    isLoading2: false,
    attachType: {} as IReferential,
    isOrganizationsDisabled: false,
    isUsersDisabled: false,
    hasRights: false,
    isUserHidden: false,
    executing: false,
    userRoleId: null,
    formSubmitted: false,
    wfTransitionStepAction: {} as WorkflowFormAction
  } as ICaseDetailsCaseTabBackToServiceFormState;

  public componentDidMount() {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (isNullOrUndefined(caseSettings) || Number.isNaN(caseId)) {
      return;
    }

    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.loadCaseBackToServiceForm();
      }
    );
  }

  assignObjectPaths = (obj: any, stack: any) => {
    Object.keys(obj).forEach((k) => {
      const node = obj[k];
      if (node && typeof node === 'object' && !Array.isArray(node)) {
        node.path = stack ? `${stack}.${k}` : k;
        this.assignObjectPaths(node, node.path);
      }
    });
  };

  findTopParent = (id: number, organizationBranches: OrganizationBranch[]): any => {
    let ob = organizationBranches.find((acc) => acc.id === id);
    if (!ob) {
      return;
    }
    if (ob.parentId !== null) {
      ob = this.findTopParent(ob.parentId, organizationBranches);
    }
    return ob;
  };

  loadCaseBackToServiceForm = async () => {
    const caseSettings = this.props.caseSettingsState.caseSettings;
    const caseStatusId = this.props.caseSettingsState.case?.caseStatus?.caseStatusId;
    const caseId = Number.parseInt(this.props.match.params.id);
    if (isNullOrUndefined(caseSettings) || Number.isNaN(caseId)) {
      return;
    }
    const caseRequest = {
      id: 0,
      caseId: this.props.caseSettingsState.case!.id,
      text: '',
      subject: '',
      to: '',
      userIdTo: '',
      organizationId: null,
      attachments: [] as CaseAttachment[],
      caseSectionStepFormId: this.props.workflowForm.id
    } as CaseRequest;

    const ref = Referentials.referential.find(
      (item) => item.code === ReferentialCode.AttachmentType
    );

    const wfStepActions = await this.workflowService.GetWorkflowSectionStepFormActions(
      this.props.workflowForm.id
    );
    const caseOrganizationTypeId = wfStepActions.find(
      (item) => item.actionType?.code === WorkflowFormActionTypeCode.CASE_ALLOCATION
    )?.workflowPartnerType?.organizationTypeId;

    const wfTransitionStepAction =
      wfStepActions.find(
        (item) =>
          item.actionType?.code === WorkflowFormActionTypeCode.STATUS_TRANSITION &&
          caseStatusId === item.fromStatusId
      ) || ({} as WorkflowFormAction);

    const workflowPartners = await this.workflowService.GetUserWorkflowPartners(
      this.props.caseSettingsState.case!.caseWorkflow.workflowId
    );

    const workflowPartnerIds = workflowPartners.map((x) => x.partnerId);

    const partnerOrganizations = await this.organizationService.GetUserOrganizationsByIds(
      workflowPartnerIds
    );

    const [attachmentTypes] = await Promise.all([this.appReferentialServie.Get(ref!.baseUrl)]);
    workflowPartners.forEach((wp) => {
      const partner = partnerOrganizations.find((item) => item?.id === wp.partnerId);
      wp.partner = isNullOrUndefined(partner) ? null : partner;
    });

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      this.props.caseSettingsState.case!.caseStatus.caseStatusId
    );

    let organizationBranches = [] as OrganizationBranch[];
    let partners = workflowPartners.map((x) => x.partner);
    if (caseOrganizationTypeId) {
      partners = partners.filter(
        (partner) => partner?.organizationTypeId === caseOrganizationTypeId
      );
    }
    for (const x of partners) {
      organizationBranches.push({
        id: x!.id,
        organizationId: x!.id,
        organizationName: x!.name,
        organizationAddress: x!.address,
        children: [],
        parentId: x!.parentId,
        path: null,
        label: x?.name + (x?.address ? ' ' + x?.address : ''),
        checked: false
      });
    }

    const parentIds = workflowPartners.map((x) => x.partnerId);

    const organizationHierarchy = await this.organizationService.GetOrganizationDescendents(
      parentIds
    );

    const descendants =
      organizationHierarchy.map((x) => {
        return {
          id: x.id,
          organizationId: x.id,
          organizationName: x.name,
          organizationAddress: x.address,
          children: [],
          parentId: x.parentId,
          path: null,
          label: x.name + (x.address ? ' ' + x.address : ''),
          checked: false
        } as OrganizationBranch;
      }) || [];

    descendants.forEach((x) => {
      organizationBranches.push(x);
    });

    const extendedWorkflowPartners: ExtendedWorkflowPartner[] = [];

    for (const ob of organizationBranches) {
      const parent = this.findTopParent(ob.id, organizationBranches);
      const parentPartner = workflowPartners.find((x) => x.partnerId === parent.id);

      extendedWorkflowPartners.push({
        workflowId: parentPartner!.workflowId,
        partnerId: ob.organizationId,
        partner: ob.organization || null,
        isActive: parentPartner!.isActive,
        id: parentPartner!.id,
        organizationBranch: ob
      });
    }

    const arrMap = organizationBranches.reduce((acc: any, el: any, i) => {
      acc[el.id] = i;
      return acc;
    }, {});

    const roots = [] as OrganizationBranch[];

    // Push each element to parent's children array
    organizationBranches.forEach((el) => {
      if (el.parentId === null || arrMap[el.parentId] == null) {
        roots.push(el);
      } else {
        organizationBranches[arrMap[el.parentId]].children.push(el);
      }
    });

    organizationBranches = roots;
    this.assignObjectPaths(organizationBranches, '');

    this.setState(
      {
        backToServiceFormData: caseRequest,
        organizationBranches,
        organizations: extendedWorkflowPartners,
        isLoading: false,
        attachType: attachmentTypes.find((item) => item.code === AttachmentTypeCode.OTHER)!,
        hasRights: hasRights,
        wfTransitionStepAction
      },
      await this.setCaseAllocation
    );
  };

  onDropDownChange = (currentNode: any, selectedNodes: any) => {
    // react-dropdown-tree-select doesn't have an intrinsic mechanism for keeping track of selected items, only provides what is selected
    // So we have to manually check/uncheck the leaves from this tree used by this component
    let newSelectionOrganizationBranch: OrganizationBranch | null = null;
    if (this.state.selectedOrganization) {
      const oldSelectionOrganizationBranch = this.findRecursive(
        this.state.organizationBranches,
        this.state.selectedOrganization.organizationBranch!.organizationId
      );
      if (oldSelectionOrganizationBranch) {
        oldSelectionOrganizationBranch.checked = false;
      }
    }

    if (selectedNodes.length == 1) {
      const newPartialSelectionOrganizationBranch =
        this.findRecursive(
          this.state.organizationBranches,
          (selectedNodes[0] as OrganizationBranch)?.organizationId
        ) || null;

      if (newPartialSelectionOrganizationBranch) {
        newPartialSelectionOrganizationBranch.checked = true;
        newSelectionOrganizationBranch = newPartialSelectionOrganizationBranch;
      }
    }

    let selectedWorkflowPartner: ExtendedWorkflowPartner | undefined = undefined;

    if (newSelectionOrganizationBranch) {
      const parent = this.findTopParent(
        newSelectionOrganizationBranch.id,
        this.state.organizations.map((o) => o.organizationBranch!)
      );

      const parentPartner = this.state.organizations.find((x) => x.partnerId === parent.id);

      selectedWorkflowPartner = {
        workflowId: parentPartner!.workflowId,
        partnerId: newSelectionOrganizationBranch.id,
        partner: newSelectionOrganizationBranch.organization || null,
        isActive: parentPartner!.isActive,
        id: parentPartner!.id,
        organizationBranch: newSelectionOrganizationBranch
      };
    }

    this.setState(
      {
        ...this.state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        selectedOrganization: selectedWorkflowPartner || null,
        selectedUser: null,
        users: [],
        isLoading2: true
      },
      async () => {
        await this.loadUsers(
          !selectedWorkflowPartner ? null : selectedWorkflowPartner.partnerId,
          this.state.userRoleId
        );
      }
    );
  };

  findRecursive = (
    arr: OrganizationBranch[],
    organizationId: number
  ): OrganizationBranch | null => {
    let result: OrganizationBranch | null = null;
    for (const entry in arr) {
      if (arr[entry].organizationId == organizationId) {
        result = arr[entry];
        break;
      }
      if (arr[entry].children?.length) {
        const partialResult = this.findRecursive(arr[entry].children, organizationId);
        if (partialResult != null) {
          result = partialResult;
          break;
        }
      }
    }
    return result;
  };

  setCaseAllocation = async () => {
    const allocationResult = await FormHelpers.ParseAllocations(
      this.props.workflowForm.workflowFormActions,
      this.state.organizations,
      this.appUserService,
      this.props.appState.appUser!
    );
    if (isNullOrUndefined(allocationResult)) {
      const partner = this.state.organizations.find(
        (item) => item.partnerId === this.props.caseSettingsState.case!.caseStatus.assignedTo!.hoId
      );

      if (partner) {
        const oldSelectionOrganizationBranch = this.findRecursive(
          this.state.organizationBranches,
          partner.organizationBranch!.id
        );

        if (oldSelectionOrganizationBranch) {
          oldSelectionOrganizationBranch.checked = true;
        }
      }

      this.setState(
        {
          selectedOrganization: isNullOrUndefined(partner) ? null : partner,
          selectedUser: this.props.caseSettingsState.case!.caseStatus.assignedTo,
          organizations: this.state.organizations,
          isUsersDisabled: false,
          isOrganizationsDisabled: false
        },
        async () =>
          await this.loadUsers(isNullOrUndefined(partner) ? null : partner.partnerId, null)
      );
      return;
    }
    if (allocationResult.isLastUser || allocationResult.caseStatusId != null) {
      this.setState({ isUserHidden: true });
      return;
    }

    if (allocationResult.partner) {
      const oldSelectionOrganizationBranch = this.findRecursive(
        this.state.organizationBranches,
        allocationResult.partner.organizationBranch!.id
      );

      if (oldSelectionOrganizationBranch) {
        oldSelectionOrganizationBranch.checked = true;
      }
    }

    this.setState(
      {
        selectedOrganization: allocationResult.partner,
        selectedUser: allocationResult.user,
        // organizations: allocationResult.partners,
        organizationBranches: allocationResult.partner?.organizationBranch
          ? [allocationResult.partner.organizationBranch]
          : this.state.organizationBranches,
        isUsersDisabled: !isNullOrUndefined(allocationResult.user),
        // isOrganizationsDisabled: !isNullOrUndefined(allocationResult.partner),
        isOrganizationsDisabled: !isNullOrUndefined(allocationResult.user),
        userRoleId: allocationResult.roleId
      },
      async () => {
        if (allocationResult.partner === null) {
          return;
        }
        await this.loadUsers(allocationResult.partner.partnerId, allocationResult.roleId);
      }
    );
  };

  loadUsers = async (organizationId: number | null, roleId: number | null) => {
    if (organizationId === null) {
      this.setState({
        isLoading2: false
      });
    } else {
      let orgIds = '';
      orgIds = `${organizationId}`;

      const users = await this.appUserService.GetUserAppUsers('', orgIds);

      this.setState({
        users: users.users.filter(
          (user) =>
            (user.organization!.hoId === organizationId ||
              user.organizationId === organizationId) &&
            ((roleId !== null && user.roleId === roleId) || roleId === null)
        ),
        isLoading2: false
      });
    }
  };

  handleUploadAttachment = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const attachments = [...this.state.attachments];
      if (isNullOrUndefined(e.target.files)) {
        return;
      }
      for (let i = 0; i < e.target.files!.length; i++) {
        attachments.push({
          id: 0,
          caseId: this.props.caseSettingsState.case!.id,
          attachmentTypeId: this.state.attachType.id,
          attachmentType: this.state.attachType,
          file: e.target.files![i],
          fileName: e.target.files![i].name,
          uploadDate: new Date(),
          carPhotos: false,
          originalFileName: e.target.files![i].name,
          caseRequestId: null,
          paymentId: null,
          serviceAppointmentId: null,
          caseClientId: null,
          isSelection: false
        });
      }

      this.setState({
        attachments: attachments
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleDeleteAttachment = async (id: number, fileName: string) => {
    try {
      const attachments = [...this.state.attachments];
      const index = attachments.findIndex((item) => item.id === id);
      attachments.splice(index, 1);

      this.setState({
        attachments: attachments
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  downloadFile = async (fileName: string, origFileName: string) => {
    const fileBlob = await this.caseService.GetCaseAttachment(fileName);
    FileSaver.saveAs(fileBlob, origFileName);
  };

  renderAttachmentBox = () => {
    return (
      <Grid container spacing={2} className="mt-2">
        <Grid item xs={9} className="attachment-container">
          {this.state.attachments.length !== 0
            ? this.state.attachments.map((attach, index) => {
                return (
                  <div key={index} className="d-flex flex-row w-100">
                    <div className="w-75 text-left">
                      <Button
                        color="primary"
                        className="text-primary text-left"
                        disabled={!this.state.hasRights}
                        //onClick={(e) => this.downloadFile(attach.fileName, attach.originalFileName)}
                      >
                        <span className="crop">{attach.originalFileName}</span>
                      </Button>
                    </div>
                    <div className="w-25">
                      <IconButton
                        aria-label="delete"
                        color="inherit"
                        className="text-danger"
                        size="small"
                        onClick={(e) => this.handleDeleteAttachment(attach.id, attach.fileName)}
                        disabled={!this.state.hasRights}
                      >
                        <DeleteOutlineTwoToneIcon />
                      </IconButton>
                    </div>
                  </div>
                );
              })
            : null}
        </Grid>
        <Grid item xs={3} container justify="flex-end" direction="column" className='attachment-container-button'>
          <input
            className="d-none"
            id={'contained-button-file'}
            disabled={!this.state.hasRights}
            multiple
            onChange={(e) => {
              return this.handleUploadAttachment(e);
            }}
            type="file"
          />
          <label htmlFor={'contained-button-file'}>
            <Button
              variant="contained"
              color="primary"
              disabled={!this.state.hasRights}
              className="d-block text-center shadow-none mt-3"
              component="div"
            >
              {this.translatorService.Tranlate('CASE_BACK_TO_SERVICE_FORM_ATTACH', 'Ataseaza')}
            </Button>
          </label>
        </Grid>
      </Grid>
    );
  };

  sendEmail = async () => {
    try {
      this.setState({ executing: true });
      //Dosar Evaluare
      const step = this.getWorkflowSectionStep(this.props.workflowForm.workflowSectionStepId);
      if (this.props.caseSettingsState.caseSettings!.workflow.isEvaluationWorkflow &&
        this.props.caseSettingsState?.case?.caseType?.code === CaseTypeCode.AQUISITION &&
        aquisitionWorkflowSectionSteps.includes(step?.workflowStep?.code ?? "") &&
        aquisitionSteps.includes(this.props.workflowForm.displayName.toUpperCase())
      ) {
        console.log('Dosar Evaluare');
        const vin = VehicleHelper.GetVIN(
          this.props.caseSettingsState.case,
          this.props.vehicleState.vehicle
        );
        const request = {
          vin: vin
        } as AcquisitionCaseRequest;
        const vinAquisitionCases = (await this.caseService.GetAcquisitionCases(request)).filter(x => x.id !== this.props.caseSettingsState.case!.id);
        if (vinAquisitionCases.length > 0) {
          this.props.enqueueSnackbar(
            (this.translatorService.Tranlate(
              'ALREADY_AQUISITION_CASE',
              'Exista deja un dosar de achizitie deschis, va rugam sa verificati!'
            ) + '\n' +
            (vinAquisitionCases.length == 1 ? this.translatorService.Tranlate('CASES_ID_LABEL', 'Id-ul dosarului este: ') : this.translatorService.Tranlate('CASES_IDS', 'Id-urile dosarelor sunt: ')) +
            vinAquisitionCases.map((item) => item.id).join(', ')),
            {
              variant: 'warning',
              style: { whiteSpace: 'pre-line' },
              autoHideDuration: 6000
            }
          );
  
          this.setState({ executing: false });
          return;
        }
      }

      //Pasul Predare masina client
      if (this.props.caseSettingsState.caseSettings!.workflow.isCarfixIntegration && smartBillInvoiceWorkflowSectionSteps.includes(step?.workflowStep?.code ?? "")) {
        const smartBillInvoiceRequest = {
          caseId: this.props.caseSettingsState.case!.id,
          workflowId: this.props.caseSettingsState.caseSettings!.workflow.id, 
          template: 'GTM_INVOICE'
        } as GenerateInvoiceRequest;
        const smartBillInvoice = await this.caseService.GenerateSmartBillInvoice(smartBillInvoiceRequest);
        console.log('smartBillInvoice', smartBillInvoice);
      }

      let request = this.state.backToServiceFormData;
      const { toWorkflowId, toStatusId } = this.state.wfTransitionStepAction;
      const userId = this.props.appState?.appUser?.id || this.state.selectedUser?.id;

      if (isNullOrUndefined(request)) {
        return;
      }

      this.setState({ executing: true, formSubmitted: true });

      request!.id = 0;
      request!.attachments = this.state.attachments;
      if (!this.state.isUserHidden) {
        request.userIdTo = this.state.selectedUser!.id!;
        request.organizationId = this.state.selectedOrganization!.partnerId;
      }

      if (toWorkflowId && toStatusId) {
        await this.caseService.ChangeCaseWorkflow(
          toWorkflowId,
          request.caseId,
          userId!,
          toStatusId
        );
      } else {
        request = await this.caseService.AddCaseRequest(request);
      }

      await this.submitForm(request.caseId, request.caseSectionStepFormId, this.state.selectedUser);

      this.setState(
        {
          backToServiceFormData: {
            id: 0,
            caseId: this.props.caseSettingsState.case!.id,
            text: '',
            subject: '',
            to: '',
            userId: '',
            organizationId: null,
            attachments: [] as CaseAttachment[],
            caseSectionStepFormId: this.props.workflowForm.id
          } as CaseRequest,
          formSubmitted: false,
          attachments: [],
          selectedUser: null,
          selectedOrganization: null
        },
        this.setCaseAllocation
      );
      if (toWorkflowId && toStatusId) {
        this.props.SetCaseSettings(request.caseId);
      }
      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (ex) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    } finally {
      this.setState({ executing: false });
    }
  };

  getWorkflowStep = (code: string): IReferential | null => {
    const sections = this.props.caseSettingsState.caseSettings!.workflow.workflowSections!;
    for (const section of sections) {
      for (const step of section.workflowSectionSteps) {
        if (!isNullOrUndefined(step.workflowStep) && step.workflowStep!.code == code) {
          return step.workflowStep;
        }
      }
    }
    return null;
  };

  getWorkflowSectionStep = (workflowSectionStepId: number): WorkflowSectionStep | null => {
    const sections = this.props.caseSettingsState.caseSettings!.workflow.workflowSections!;
    for (const section of sections) {
      for (const step of section.workflowSectionSteps) {
        if (step.id == workflowSectionStepId) {
          return step;
        }
      }
    }
    return null;
  };

  submitForm = async (caseId: number, caseSectionStepFormId: number, appuser: AppUser | null) => {
    const prevCaseStatus = this.props.caseSettingsState.case!.caseStatus;
    let newStatus = await this.caseService.SubmitForm(caseId, caseSectionStepFormId, appuser);

    if (newStatus === null) {
      newStatus = this.props.caseSettingsState.case!.caseStatus;
    }
    console.log('prevCaseStatus', prevCaseStatus);
    console.log('newStatus', newStatus);

    const vehicleId = this.props.caseSettingsState.case?.vehicleId;

    if (newStatus.caseStatus?.code === 'OF_REFUZ') {
      if (vehicleId) {
        await this.vehicleService.ChangeVehicleStatus({
          vehicleId,
          statusId: VehicleStatusCodeEnum.AcquisitionRefused,
          comment: ''
        });
      }
    }

    if (newStatus.caseStatus?.code === 'CLOSED') {
      if (vehicleId) {
        await this.vehicleService.ChangeVehicleStatus({
          vehicleId,
          statusId: VehicleStatusCodeEnum.Sold,
          comment: ''
        });
      }
      const workflowStep = this.getWorkflowStep(FormCode.CASE_VEHICLE_PB_ANUNT);
      if (workflowStep && vehicleId) {
        await this.vehicleService.DeleteAllAdvertWhenCaseStatusBecameClosed(caseId, vehicleId);
      }
    }

    if (prevCaseStatus.caseStatus?.code != newStatus.caseStatus?.code) {
      if (prevCaseStatus.caseStatus?.code === 'OF_REFUZ') {
        if (vehicleId) {
          await this.vehicleService.ChangeVehicleStatus({
            vehicleId,
            statusId: VehicleStatusCodeEnum.Aquisition,
            comment: ''
          });
        }
      }
    }

    this.props.SetCaseStatus(newStatus);
    if (appuser !== null) {
      this.props.AddPartner(appuser!.hoId === null ? appuser!.organizationId : appuser!.hoId);
    }

    const hasRights = FormHelpers.HasRights(
      this.props.workflowForm.workflowFormPermissions,
      this.props.appState.appUser!,
      newStatus.caseStatusId
    );
    this.setState({ hasRights: hasRights });
  };

  handleOrganizationChange = (newValue: WorkflowPartner | null) => {
    this.setState(
      {
        selectedOrganization: newValue,
        selectedUser: null,
        users: [],
        isLoading2: true
      },
      async () => {
        await this.loadUsers(newValue === null ? null : newValue.partnerId, this.state.userRoleId);
      }
    );
  };

  handleUserChange = (newValue: AppUser | null) => {
    this.setState({ selectedUser: newValue });
  };

  rendeBackToServiceForm = () => {
    return isNullOrUndefined(this.state.backToServiceFormData) ? null : (
      <ValidatorForm
        onSubmit={(e) => {
          this.sendEmail();
        }}
      >
        <div className="m-3 text-center">
          <TextValidator
            fullWidth
            disabled={!this.state.hasRights}
            id="textComment"
            name="textComment"
            multiline
            rows={8}
            placeholder={this.translatorService.Tranlate(
              'CASE_BACK_TO_SERVICE_FORM_WRITE_MESSAGE_PLACEHOLDER',
              'Write your message...'
            )}
            value={this.state.backToServiceFormData.text}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              this.setState({
                backToServiceFormData: {
                  ...this.state.backToServiceFormData,
                  text: e.target.value
                }
              });
            }}
            variant="outlined"
            validators={['required']}
            errorMessages={[
              this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
            ]}
          />
          {this.renderAttachmentBox()}
          {this.renderAssignementForm()}
        </div>

        <Button
          className="m-2"
          variant="contained"
          color="primary"
          type="submit"
          onClick={() => this.setState({ formSubmitted: true })}
          disabled={!this.state.hasRights || this.state.executing}
        >
          {this.translatorService.Tranlate('CASE_COMMENT_SEND', 'Trimite')}
        </Button>
      </ValidatorForm>
    );
  };

  renderAssignementForm = () => {
    if (this.state.isUserHidden) {
      return null;
    }

    const label = this.translatorService.Tranlate(
      'CASE_BACK_TO_SERVICE_FORM_ORGANIZATION_LABEL',
      'Organizatie'
    );

    return (
      <React.Fragment>
        <div
          className={clsx('text-left', {
            'tree-error': this.state.formSubmitted && !this.state.selectedOrganization
          })}
        >
          <DropdownTreeSelect
            className="m-2"
            disabled={this.state.isOrganizationsDisabled || !this.state.hasRights}
            id="workflowPartner"
            data={this.state.organizationBranches}
            onChange={this.onDropDownChange}
            mode="radioSelect"
            texts={{
              placeholder: label,
              label
            }}
          ></DropdownTreeSelect>
        </div>
        {this.state.formSubmitted && !this.state.selectedOrganization && (
          <FormHelperText error style={{ marginTop: '-10px', marginLeft: '10px' }}>
            {this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')}
          </FormHelperText>
        )}
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading2} />
        </div>
        {!this.state.isLoading2 ? (
          <Autocomplete
            disabled={this.state.isUsersDisabled || !this.state.hasRights}
            id="user"
            className="m-2"
            options={this.state.users.sort(function (a, b) {
              return a.userName.localeCompare(b.userName);
            })}
            value={this.state.selectedUser}
            onChange={(e: any, newValue: AppUser | null) => this.handleUserChange(newValue)}
            getOptionLabel={(option: AppUser) => option.userName || ''}
            renderInput={(params) => (
              <TextValidator
                {...params}
                name="user"
                value={this.state.selectedUser}
                label={this.translatorService.Tranlate(
                  'CASE_BACK_TO_SERVICE_FORM_USER_LABEL',
                  'Utilizator'
                )}
                fullWidth
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
              />
            )}
          />
        ) : null}
      </React.Fragment>
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.caseService = (this.context as AppContext).caseService;
    this.workflowService = (this.context as AppContext).workflowService;
    this.appUserService = (this.context as AppContext).appUserService;
    this.organizationService = (this.context as AppContext).organizationService;
    this.appReferentialServie = (this.context as AppContext).referentialService;
    this.vehicleService = (this.context as AppContext).vehicleService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <div className="text-center m-2">{this.rendeBackToServiceForm()}</div>
        ) : null}
        <Divider></Divider>
        {this.state.executing ? null : (
          <CaseDetailsCaseRequests
            {...this.props}
            formId={this.props.workflowForm.id}
            caseRequests={null}
            carfixOfferComments={false}
          ></CaseDetailsCaseRequests>
        )}
      </Fragment>
    );
  }
}

const mergeProps = (
  stateProps: any,
  dispatchProps: any,
  ownProps: ExternalCaseDetailsCaseTabBackToServiceFormProps
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps
});

export default connect(
  (state: ApplicationState) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app
  }),
  CaseSettingsActionCreators,
  mergeProps
)(withSnackbar(CaseDetailsCaseTabBackToServiceForm as any));
