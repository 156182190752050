import React, { useState } from 'react';
import { Box, Button, CircularProgress, IconButton, Modal, TextField, Typography } from '@material-ui/core';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {  generateArrayOfHours, getDay, getHour, getStatusList } from '../../utils/appointments';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TranslatorService } from '../../services/TranslatorService';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import { BigCalendar } from '../agenda/BigCalendar';
import { getAppointmentsRequest } from '../agenda/agenda.requests';
import { ResponseAppointmentsData } from '../../types/Agenda';
import { useAppDispatch, useAppSelector } from '../../store/configureStore';
import { AGENDA_SET_APPOINTMENTS, AGENDA_SET_SELECTED_RANGE, AGENDA_SET_STATUS_LIST } from '../../store/actions/ActionTypes';
import { useSnackbar } from 'notistack';

interface CustomStyleProps{
  calendarStyle?:any,
  hourDropdownStyle?:any
}

interface AppointmentCalendarProps{
  day:Date | null,
  setDay: (val:Date | null) => void, 
  hour:string | null, 
  setHour: (val:string | null) => void,
  disabled?:boolean,
  customStyle?:CustomStyleProps
  minDate?:any
  showDefaultCalendar:boolean
}

export const AppointmentCalendar = ({ day, setDay, hour, setHour, disabled, customStyle, minDate, showDefaultCalendar }: AppointmentCalendarProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [showBigCalendar, setShowBigCalendar] = useState<boolean>(false);
  const hours = generateArrayOfHours()
  const translatorService = new TranslatorService();
  const [localAppointments, setLocalAppoitments] = useState<Array<any>>([]);
  const [localLoading, setLocalLoading] = useState<boolean>(false);
  const [defaultView, setDefaultView] = useState<'month' | 'week' | 'day' | 'agenda'>('week');
  const [defaultDay, setDefaultDay] = useState<any>()
  const dispatch = useAppDispatch();
  const [showDropdown, setShowDropdown] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dateFormat = useAppSelector((state) => state.app?.dateFormat);
  const statusList = useAppSelector((state) => state.agenda?.statusList);
  const [isLoadingDate, setIsLoadingDate] = useState(false);
  const [isLoadingHour, setIsLoadingHour] = useState(false);

  React.useEffect(() => {
    if (Object.keys(statusList).length === 0)  {
      getStatusList(setIsLoading, (value:any)=>{
        dispatch({
          type: AGENDA_SET_STATUS_LIST,
          payload: value
        });
      }, enqueueSnackbar);
    }
  }, []);
  
  const handleSelectOption = (ora:string) => {
    setHour(ora)
    setShowDropdown(false);
  };

  const getSyles = (generalStyle:any, customStyle:any)=>{
    if(customStyle){
      return {...generalStyle, ...customStyle}
    }
    return {...generalStyle}
  }

  const fetchAppointments = async (filters: any, onlyOneDay?: boolean) => {
    !onlyOneDay && setLocalLoading(true)
    const response = await getAppointmentsRequest(filters);

    const responseData = response.data as ResponseAppointmentsData;

    if (!responseData?.appointments) {
      !onlyOneDay && setLocalLoading(false)
      return
    }

    if (onlyOneDay) {
      setLocalAppoitments(responseData?.appointments)
    } else {
      dispatch({
        type: AGENDA_SET_APPOINTMENTS,
        payload: responseData.appointments,
      });
    }

    !onlyOneDay && setLocalLoading(false)
  };

  const onSelectSlot = (slotInfo: any) => {
    const selectedDate = new Date(slotInfo.start);
    if (defaultView === 'month') {
      setDefaultDay(selectedDate);
      setDefaultView('day')
    } else {
      const currentDate = new Date();
      if (selectedDate < currentDate) {
        enqueueSnackbar(translatorService.Tranlate('PAST_DATE', 'You cannot select a date from the past for scheduling'), {
          variant: 'error'
        });
        return;
      }
      const selectedHour = selectedDate.getHours();
      const selectedMinute = selectedDate.getMinutes();
      setDay(selectedDate);
      setHour(`${selectedHour < 10 ? `0${selectedHour}` : selectedHour}:${selectedMinute < 10 ? `0${selectedMinute}` : selectedMinute}`);
      setShowBigCalendar(false)
    }
  }

  const renderCalendar = () => {
    return <div>
      <Modal open={showBigCalendar} onClose={() => { setShowBigCalendar(false) }}>
        <div className="reschedule-calendar-modal">
          <div className="text-right">
            <IconButton onClick={() => { setShowBigCalendar(false) }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography variant="h3" style={{ textAlign: 'center', marginTop: -16 }}>
            {translatorService.Tranlate('APPOINTMENT_RESCHUDELE_CALENDAR_TITLE', 'Select the date and time for rescheduling')}
          </Typography >
          <BigCalendar
            getAppointments={fetchAppointments}
            defaultView={defaultView}
            setDefaultView={setDefaultView}
            defaultDay={defaultDay}
            handleSlotSelect={onSelectSlot}
          />
        </div>
      </Modal>
    </div>
  }

  const mapAppointments = async () => {
    const currentDate = defaultDay == null ? new Date() : defaultDay;              
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    lastDayOfMonth.setHours(23, 59, 59, 999);
    
    dispatch({
      type: AGENDA_SET_SELECTED_RANGE,
      payload: {
        start_date: firstDayOfMonth,
        end_date: lastDayOfMonth
      }
    });

    await fetchAppointments({
      dateFilterStartDate: moment(firstDayOfMonth).format('YYYY-MM-DDTHH:mm:ss'),
      datefilterEndDate: moment(lastDayOfMonth).format('YYYY-MM-DDTHH:mm:ss')
    });

    setDefaultView('month');
  }

  return (
    <Box>
      <Typography variant="h5">
        {translatorService.Tranlate('APPOINTMENT_CALENDAR_TITLE', 'Schedule')}
      </Typography>
      <Box style={{ display: 'flex', gap: '20px', flexWrap:'wrap', marginTop:-6 }}>
        <Box>
          <TextField
            className="calendar-text-field id-calendar"
            disabled={disabled}
            label={translatorService.Tranlate('APPOINTMENT_CALENDAR_DATE_LABEL', 'Date')}
            variant="outlined"
            margin="normal"
            value={day != null ? getDay(day, dateFormat || 'DD/MM/YYYY') : ''}
            placeholder={translatorService.Tranlate('APPOINTMENT_SELECT_PLACEHOLDER', 'Selecteaza')}
            onClick={async ()=>{
              if(!showDefaultCalendar){
                setIsLoadingDate(true);
                await mapAppointments();
                setIsLoadingDate(false);
                setShowBigCalendar(true);
                return;
              }
              if (showDropdown) { setShowDropdown(false) } setShowCalendar(!showCalendar)
            }}
            InputProps={{
              endAdornment: (
                <Box display="flex" alignItems="center">
                  {isLoadingDate && (
                    <CircularProgress
                      style={{ marginLeft: 8, marginRight: 8 }}
                      size={12}
                    />
                  )}
                  <FontAwesomeIcon icon={['fas', 'calendar-alt']} style={{ color: 'gray', fontSize: '1rem' }} />
                </Box>
              ),
              style: { cursor: 'pointer' } 
            }}
          />

          {showCalendar && showDefaultCalendar ? <Box style={getSyles({ position: 'absolute', zIndex: 1000, }, customStyle?.calendarStyle)}><Calendar
            value={day}
            minDate={minDate}
            showNeighboringMonth={false}
            locale={moment.locale()}
            onChange={(date: any) => {setDay(date); setShowCalendar(false)}}
          /> </Box>: null}
          {showBigCalendar && !showDefaultCalendar ? renderCalendar():null}
        </Box>
        <Box>
          <TextField
            className="calendar-text-field id-hour"
            disabled={disabled}
            value={hour != null ? getHour(hour) : ''}
            style={{width:120}}
            label={translatorService.Tranlate('APPOINTMENT_CALENDAR_TIME_LABEL', 'Time')}
            variant="outlined"
            margin="normal"
            onClick={async () => {
              if (!showDefaultCalendar) {
                setIsLoadingHour(true);
                await mapAppointments();
                setIsLoadingHour(false);
                setShowBigCalendar(true);
                return;
              }
              if(showCalendar){setShowCalendar(false)} setShowDropdown(!showDropdown)
            }}
            placeholder={translatorService.Tranlate('APPOINTMENT_SELECT_PLACEHOLDER', 'Selecteaza')}
            InputProps={{
              endAdornment: (
                <Box display="flex" alignItems="center">
                  {isLoadingHour && (
                    <CircularProgress
                      style={{ marginLeft: 8, marginRight: 8 }}
                      size={12}
                    />
                  )}
                  <FontAwesomeIcon icon={['fas', 'clock']} style={{ color: 'gray', fontSize: '1rem' }} />
                </Box>
              ),
              style: { cursor: 'pointer' }
            }}
            />

          {showDropdown && showDefaultCalendar && (
            <div style={getSyles({  maxHeight: 190,overflowY: 'auto', paddingTop:8, width:120, position: 'absolute', zIndex:1000, border: '1px solid #ced4da', borderRadius: '4px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', backgroundColor:'white' }, customStyle?.hourDropdownStyle)}>
              {hours.map((ora:string) => (
                <Box key={ora} onClick={() => handleSelectOption(ora)} style={{ marginBottom:8, textAlign:'center'}}>
                  {ora}
                </Box>
              ))}
            </div>
          )}
        
        </Box>

      </Box>
    </Box>
  );
};
