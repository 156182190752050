import { API } from '../helpers/API';
import { IVehicleService } from './Interfaces/IVehicleService';
import {
  ChangeVehicleStatusRequest,
  CurrencyRate,
  Vehicle,
  VehicleAdvert,
  VehicleFilters,
  VehicleFiltersResult,
  VehicleOffer,
  VehicleQualityControlCert,
  VehicleStatusHistory,
  AdvertiserResult,
  VehicleExtensionList,
  ImportVehicleResult,
  ImportXlsxResult,
  SimpleVehicle,
  VehicleCostRequest,
  Cost,
  InvoiceRange,
  Provenance,
  ExchangeRate,
  AlcorErrorLog,
  FinancialParameterInfo,
  SellerData,
  MavoVehicleFilters,
  MavoVehicleList,
  MavoVehicleFiltersResult,
  ImportMavoVehiclesRequestDto,
  ImportMavoVehicleResult,
  MavoVehicle,
  MavoVehicleAttachment,
  MavoVehicleOption,
  MavoVehicleStatusHistory,
  ChangeMavoVehicleStatusRequest,
  MavoVehicleAuctionResult,
  MavoVehicleAuctionsFilters,
  MavoVehicleAuctionsList,
  MavoVehicleAuctionsFiltersResult,
  MavoVehicleAuction,
  MavoVehicleAuctionOffer,
  MavoVehicleOrder,
  MavoAdminAuctionsFilters,
  MavoAdminAuctionsList,
  MavoAdminAuctionsFiltersResult,
  CloseAuctionRequestDto,
  MavoVehicleUserAuctionsFilters,
  MavoVehicleUserAuctionsList,
  MavoVehicleUserAuctionsFiltersResult,
  MavoOrdersFltersResult,
  MavoOrdersFilters,
  MavoOrdersList,
  MavoVoInvoiceFltersResult,
  MavoVoInvoicesList,
  MavoVoInvoiceFilters,
  DeclineMavoVehicleOrderRequest,
  DeclineMavoVehicleOrderResponse,
  MavoVehicleNoticeResponse,
  MavoVehicleInvoiceResponse,
  MavoVehicleOrderRCICloseRequest,
  MavoVehicleOrderRCICloseResponse,
  MavoVoInvoiceExportFilters,
  MavoVehicleCreateNoticeRequestDto,
  MavoVehicleCreateInvoiceRequestDto,
  AlcorParameterInfo,
  SimpleVehicleCarfix,
  SendVehicleToCarfixResponse,
  PublishAdvertRcr,
  PublishAdvertsRcrResponse,
  PayloadAddManualVehicle,
  AuctionOfferResponseDto
} from '../interfaces/Vehicle';
import { CRUDService } from './CRUDService';
import { ICRUDService } from './Interfaces/ICRUDService';
import * as R from 'ramda';
import { CaseAttachmentDto, CaseAttachments } from '../interfaces/Case';
import { MarketDataFilter } from '../interfaces/Radar/MarketDataFilter';
import { MarketData } from '../interfaces/Radar/MarketData';
import { VehicleUpdateHistory } from '../interfaces/VehicleUpdateHistory';
import { VehicleFilter } from '../interfaces/Dashboard/VehicleFilter';
import { VehicleResult } from '../interfaces/Dashboard/VehicleResult';
import { CancelToken } from 'axios';
import { AcquisitionFilter } from '../interfaces/Dashboard/AcquisitionFilter';
import { AcquisitionResult } from '../interfaces/Dashboard/AcquisitionResult';
import { SalesResult } from '../interfaces/Dashboard/SalesResult';
import { SalesFilter } from '../interfaces/Dashboard/SalesFilter';
import { IReferential } from '../interfaces/IReferential';
import { ReportParameter } from '../interfaces/Reports';
import { ImportVehicle } from '../components/vehicles/VehicleImportOneCarModal';

export class VehicleService implements IVehicleService {
  private baseController = '/vehicle';

  VehicleQualityControlCert: ICRUDService<VehicleQualityControlCert>;

  constructor() {
    this.VehicleQualityControlCert = new CRUDService(
      this.baseController,
      'VehicleQualityControlCert'
    );
  }

  public async GetVehicle(id: number): Promise<Vehicle | null> {
    if(!id){
      return null;
    }
    return (await API.GetAsync<Vehicle>(`${this.baseController}/${id}`)).data;
  }

  public async GetVehicleList(ids: number[]): Promise<Vehicle[]> {
    if (ids.length === 0) {
      return [];
    }
    const idsList = ids.join(',');

    return (await API.GetAsync<Vehicle[]>(`${this.baseController}/list/${idsList}`)).data;
  }

  public async GetVehicles(filters: VehicleFilters): Promise<VehicleExtensionList> {
    return (
      await API.Post2Async<VehicleFilters, VehicleExtensionList>(
        `${this.baseController}/filter`,
        filters
      )
    ).data;
  }

  public async GetVehiclesFilters(vehicleHistory: boolean): Promise<VehicleFiltersResult> {
    return (
      await API.GetAsync<VehicleFiltersResult>(
        `${this.baseController}/filters?vehicleHistory=${vehicleHistory}`
      )
    ).data;
  }

  public async AddVehicle(vehicle: Vehicle, caseId: number): Promise<Vehicle> {
    return (await API.PostAsync<Vehicle>(`${this.baseController}/${caseId}`, vehicle)).data;
  }

  public async UpdateVehicle(vehicle: Vehicle): Promise<void> {
    await API.PutAsync<Vehicle>(`${this.baseController}`, vehicle);
  }

  public async SaveImportedVehicles(vehicles: SimpleVehicle[]): Promise<ImportVehicleResult> {
    return (
      await API.Post2Async<SimpleVehicle[], ImportVehicleResult>(
        `${this.baseController}/import`,
        vehicles
      )
    ).data;
  }

  public async SaveImportedXlsxVehicles(file: File): Promise<ImportXlsxResult> {
    const formData = new FormData();

    formData.append('importContent', file);

    return (
      await API.Post2Async<FormData, ImportXlsxResult>(
        `${this.baseController}/rcr-import`,
        formData
      )
    ).data;
  }

  public async SaveManualdVehicles(data:PayloadAddManualVehicle): Promise<any> {
    return (
      await API.Post2Async<PayloadAddManualVehicle, any>(
        `${this.baseController}/rcr-manual-import`,
        data
      )
    ).data;
  }

  public async SendVehicleToCarfix(
    vehicle: SimpleVehicleCarfix
  ): Promise<SendVehicleToCarfixResponse> {
    return (
      await API.Post2Async<SimpleVehicleCarfix, SendVehicleToCarfixResponse>(
        `${this.baseController}/rcr-sync-vehicle`,
        vehicle
      )
    ).data;
  }

  public async SendPublishedAdvertsToRcr(
    request: PublishAdvertRcr
  ): Promise<PublishAdvertsRcrResponse> {
    return (
      await API.Post2Async<PublishAdvertRcr, PublishAdvertsRcrResponse>(
        `${this.baseController}/rcr-sync-publish-ad`,
        request
      )
    ).data;
  }

  public async SaveVehicleOffer(offer: VehicleOffer): Promise<VehicleOffer> {
    return (
      await API.PostAsync<VehicleOffer>(
        `${this.baseController}/vehicleoffer/${offer.caseId}`,
        offer
      )
    ).data;
  }

  public async SaveVehicleOfferWithoutCaseId(offer: VehicleOffer): Promise<VehicleOffer> {
    return (await API.PostAsync<VehicleOffer>(`${this.baseController}/vehicleoffer`, offer)).data;
  }

  public async ChangeVehicleStatus(
    request: ChangeVehicleStatusRequest
  ): Promise<VehicleStatusHistory> {
    return (
      await API.Post2Async<ChangeVehicleStatusRequest, VehicleStatusHistory>(
        `${this.baseController}/status`,
        request
      )
    ).data;
  }

  public async GetVehicleStatuses(vehicleId: number): Promise<VehicleStatusHistory[]> {
    return (
      await API.GetAsync<VehicleStatusHistory[]>(`${this.baseController}/${vehicleId}/statuses`)
    ).data;
  }

  public async GetVehicleFieldsHistory(vehicleId: number): Promise<VehicleUpdateHistory[]> {
    return (
      await API.GetAsync<VehicleUpdateHistory[]>(
        `${this.baseController}/${vehicleId}/updateHistory`
      )
    ).data;
  }

  public async SaveVehicleAdvert(vehicleAdvert: VehicleAdvert): Promise<VehicleAdvert> {
    return (
      await API.PostAsync<VehicleAdvert>(`${this.baseController}/VehicleAdvert`, vehicleAdvert)
    ).data;
  }

  public async GetVehicleAdvert(
    vehicleId: number | null,
    caseId: number
  ): Promise<VehicleAdvert | null> {
    if (R.isNil(vehicleId)) return null;
    return (
      await API.GetAsync<VehicleAdvert>(
        `${this.baseController}/${vehicleId}/${caseId}/vehicleadvert/current`
      )
    ).data;
  }

  public async GetVehicleOffers(
    vehicleId: number,
    caseId: number,
    typeId: number
  ): Promise<VehicleOffer[]> {
    return (
      await API.GetAsync<VehicleOffer[]>(
        `${this.baseController}/${vehicleId}/${caseId}/vehicleOffer/${typeId}`
      )
    ).data;
  }

  public async GetCurrencyRates(): Promise<CurrencyRate[]> {
    return (await API.GetAsync<CurrencyRate[]>(`${this.baseController}/currencyRates`)).data;
  }

  public async PublishAdvert(
    vehicleAdvertId: number,
    advertiserCodes: string[],
    caseAttachments: CaseAttachmentDto[]
  ): Promise<AdvertiserResult> {
    const data = {
      advertiserCodes: advertiserCodes,
      attachments: caseAttachments
    };

    return (
      await API.Post2Async<typeof data, AdvertiserResult>(
        `${this.baseController}/VehicleAdvert/${vehicleAdvertId}/publish`,
        data
      )
    ).data;
  }

  public async DeleteAdvert(
    vehicleAdvertId: number,
    advertiserCodes: string[]
  ): Promise<AdvertiserResult> {
    return (
      await API.Post2Async<string[], AdvertiserResult>(
        `${this.baseController}/VehicleAdvert/${vehicleAdvertId}/delete`,
        advertiserCodes
      )
    ).data;
  }

  public async GetVehicleAdvertHistory(
    vehicleId: number | null,
    caseId: number
  ): Promise<VehicleAdvert[]> {
    if (R.isNil(vehicleId)) return [];
    return (
      await API.GetAsync<VehicleAdvert[]>(
        `${this.baseController}/${vehicleId}/${caseId}/vehicleadvert/history`
      )
    ).data;
  }

  public async GetCaseAttachmentsForVehicle(vehicleId: number): Promise<CaseAttachments[]> {
    return (
      await API.GetAsync<CaseAttachments[]>(`${this.baseController}/${vehicleId}/caseAttachments`)
    ).data;
  }

  public async GetVehicleMarketData(filter: MarketDataFilter): Promise<MarketData[]> {
    return (await API.GetAsync<MarketData[]>(`${this.baseController}/market-data`, filter)).data;
  }

  public async GetDashboardData(
    filter: VehicleFilter,
    cancelToken: CancelToken
  ): Promise<VehicleResult> {
    return (
      await API.Post2WithCancelAsync<VehicleFilter, VehicleResult>(
        `${this.baseController}/dashboard-data`,
        filter,
        cancelToken
      )
    ).data;
  }

  public async GetDashboardAquisitionData(
    filter: AcquisitionFilter,
    cancelToken: CancelToken
  ): Promise<AcquisitionResult> {
    return (
      await API.Post2WithCancelAsync<AcquisitionFilter, AcquisitionResult>(
        `${this.baseController}/dashboard-acquisition-data`,
        filter,
        cancelToken
      )
    ).data;
  }
  public async GetDashboardSalesData(
    filter: SalesFilter,
    cancelToken: CancelToken
  ): Promise<SalesResult> {
    return (
      await API.Post2WithCancelAsync<SalesFilter, SalesResult>(
        `${this.baseController}/dashboard-sales-data`,
        filter,
        cancelToken
      )
    ).data;
  }

  public async DeleteAllAdvertWhenCaseStatusBecameClosed(
    caseId: number,
    vehicleId: number
  ): Promise<AdvertiserResult> {
    return (
      await API.Post2Async<any, AdvertiserResult>(
        `${this.baseController}/VehicleAdvert/${caseId}/${vehicleId}/deleteAllVehicleAdvert`,
        null
      )
    ).data;
  }

  public async GetCostRequests(
    caseId: number,
    formId: number | null
  ): Promise<VehicleCostRequest[]> {
    return (
      await API.GetAsync<VehicleCostRequest[]>(
        `${this.baseController}/vehicleCostRequests/${caseId}/${formId === null ? '' : formId}`
      )
    ).data;
  }

  public async GetVehicleCostAttachment(fileName: string): Promise<Blob> {
    return (await API.GetFileAsync<Blob>(`${this.baseController}/costAttachment/${fileName}`)).data;
  }

  public async GetVehicleCostTypes(): Promise<IReferential[]> {
    return (await API.GetAsync<IReferential[]>(`${this.baseController}/Referential/CostType`)).data;
  }

  public async AddCost(cost: Cost): Promise<Cost> {
    const formData = new FormData();
    formData.append('caseId', cost.caseId);
    if (!R.isNil(cost.vehicleId)) {
      formData.append('vehicleId', cost.vehicleId.toString());
    }
    if (!R.isNil(cost.costTypeId)) {
      formData.append('costTypeId', cost.costTypeId.toString());
    }
    formData.append('costTypeCode', cost.costTypeCode);
    if (cost.files) {
      cost.files.forEach((attach) => {
        formData.append('files', attach.file);
      });
    }
    if (!R.isNil(cost.currencyRate)) {
      formData.append('currencyRate', cost.currencyRate.toString());
    }
    if (!R.isNil(cost.value)) {
      formData.append('value', cost.value.toString());
    }
    formData.append('currency', cost.currency?.toString());

    if (!R.isNil(cost.secondValue)) {
      formData.append('secondValue', cost.secondValue.toString());
    }
    formData.append('secondCurrency', cost.secondCurrency);

    if (!R.isNil(cost.caseSectionStepFormId)) {
      formData.append('caseSectionStepFormId', cost.caseSectionStepFormId.toString());
    }
    return (await API.Post2Async<FormData, Cost>(`${this.baseController}/cost/add`, formData)).data;
  }

  public async GetVehicleCosts(vehicleId: number): Promise<Cost[]> {
    return (await API.GetAsync<Cost[]>(`${this.baseController}/vehicleCost/${vehicleId}`)).data;
  }

  public async GetInvoiceRanges(): Promise<InvoiceRange[]> {
    return (await API.GetAsync<InvoiceRange[]>(`${this.baseController}/InvoiceRange/list`)).data;
  }

  public async AddInvoiceRange(invoiceRange: InvoiceRange): Promise<InvoiceRange> {
    return (await API.PostAsync<InvoiceRange>(`${this.baseController}/InvoiceRange`, invoiceRange))
      .data;
  }

  public async RemoveInvoiceRange(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/InvoiceRange/${id}`)).data;
  }

  public async UpdateInvoiceRange(invoiceRange: InvoiceRange): Promise<void> {
    await API.PutAsync<InvoiceRange>(`${this.baseController}/InvoiceRange`, invoiceRange);
  }

  public async GetProvenances(): Promise<Provenance[]> {
    return (await API.GetAsync<Provenance[]>(`${this.baseController}/Provenance/list`)).data;
  }

  public async AddProvenance(provenance: Provenance): Promise<Provenance> {
    return (await API.PostAsync<Provenance>(`${this.baseController}/Provenance`, provenance)).data;
  }

  public async RemoveProvenance(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/Provenance/${id}`)).data;
  }

  public async UpdateProvenance(provenance: Provenance): Promise<void> {
    await API.PutAsync<Provenance>(`${this.baseController}/Provenance`, provenance);
  }

  public async GetExchangeRates(): Promise<ExchangeRate[]> {
    return (await API.GetAsync<ExchangeRate[]>(`${this.baseController}/ExchangeRate`)).data;
  }

  public async AddExchangeRate(exchangeRate: ExchangeRate): Promise<ExchangeRate> {
    return (await API.PostAsync<ExchangeRate>(`${this.baseController}/ExchangeRate`, exchangeRate))
      .data;
  }

  public async UpdateExchangeRate(exchangeRate: ExchangeRate): Promise<void> {
    await API.PutAsync<ExchangeRate>(`${this.baseController}/ExchangeRate`, exchangeRate);
  }

  public async RemoveExchangeRate(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/ExchangeRate/${id}`)).data;
  }

  public async GetAlcorErrorLogs(): Promise<AlcorErrorLog[]> {
    return (await API.GetAsync<AlcorErrorLog[]>(`${this.baseController}/AlcorErrorLog/list`)).data;
  }

  public async GetFinancialParameterInfoList(): Promise<FinancialParameterInfo[]> {
    return (
      await API.GetAsync<FinancialParameterInfo[]>(
        `${this.baseController}/FinancialParameterInfo/list`
      )
    ).data;
  }

  public async AddFinancialParameterInfo(
    financialParameterInfo: FinancialParameterInfo
  ): Promise<FinancialParameterInfo> {
    return (
      await API.PostAsync<FinancialParameterInfo>(
        `${this.baseController}/FinancialParameterInfo`,
        financialParameterInfo
      )
    ).data;
  }

  public async RemoveFinancialParameterInfo(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/FinancialParameterInfo/${id}`))
      .data;
  }

  public async UpdateFinancialParameterInfo(
    financialParameterInfo: FinancialParameterInfo
  ): Promise<void> {
    await API.PutAsync<FinancialParameterInfo>(
      `${this.baseController}/FinancialParameterInfo`,
      financialParameterInfo
    );
  }

  public async GetSellerDataList(): Promise<SellerData[]> {
    return (await API.GetAsync<SellerData[]>(`${this.baseController}/SellerData/list`)).data;
  }

  public async AddSellerData(sellerData: SellerData): Promise<SellerData> {
    return (await API.PostAsync<SellerData>(`${this.baseController}/SellerData`, sellerData)).data;
  }

  public async RemoveSellerData(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/SellerData/${id}`)).data;
  }

  public async UpdateSellerData(sellerData: SellerData): Promise<void> {
    await API.PutAsync<SellerData>(`${this.baseController}/SellerData`, sellerData);
  }

  public async AddLogo(id: number, file: File): Promise<void> {
    const formData = new FormData();

    formData.append('logo', file);

    await API.PostAsync<FormData>(`${this.baseController}/SellerData/${id}/Logo`, formData);
  }

  public async GetLogo(id: number): Promise<Blob> {
    return (await API.GetFileAsync<Blob>(`${this.baseController}/SellerData/${id}/Logo`)).data;
  }

  public async GetMavoVehicles(filters: MavoVehicleFilters): Promise<MavoVehicleList> {
    return (
      await API.Post2Async<MavoVehicleFilters, MavoVehicleList>(
        `${this.baseController}/mavo/filter`,
        filters
      )
    ).data;
  }

  public async GetMavoVehiclesFilters(): Promise<MavoVehicleFiltersResult> {
    return (await API.GetAsync<MavoVehicleFiltersResult>(`${this.baseController}/mavo/filters`))
      .data;
  }

  public async SaveImportedMavoVehicles(
    request: ImportMavoVehiclesRequestDto
  ): Promise<ImportMavoVehicleResult> {
    const formData = new FormData();
    formData.append('importContent', request.attachments[0].file);
    return (
      await API.Post2Async<FormData, ImportMavoVehicleResult>(
        `${this.baseController}/mavo-import`,
        formData
      )
    ).data;
  }

  public async GetMavoVehicle(id: number): Promise<MavoVehicle> {
    return (await API.GetAsync<MavoVehicle>(`${this.baseController}/mavo/${id}`)).data;
  }

  public async GetMavoVehicleByVin(vin: string): Promise<MavoVehicle> {
    return (await API.GetAsync<MavoVehicle>(`${this.baseController}/mavo/byVin/${vin}`)).data;
  }

  public async AddMavoVehicle(vehicle: MavoVehicle): Promise<MavoVehicle> {
    return (await API.PostAsync<MavoVehicle>(`${this.baseController}/mavo/add`, vehicle)).data;
  }

  public async AddMavoVehicleAttachments(
    mavoVehicleAttachments: MavoVehicleAttachment[]
  ): Promise<MavoVehicleAttachment[]> {
    const formData = new FormData();
    formData.append('mavoVehicleId', mavoVehicleAttachments[0].mavoVehicleId.toString());
    formData.append('attachmentTypeId', mavoVehicleAttachments[0].attachmentTypeId.toString());
    formData.append('attachmentTypeCode', mavoVehicleAttachments[0].attachmentType!.code!);
    mavoVehicleAttachments.forEach((item) => {
      formData.append('files', item.file);
    });
    return (
      await API.Post2Async<FormData, MavoVehicleAttachment[]>(
        `${this.baseController}/mavo/attachment`,
        formData
      )
    ).data;
  }

  public async GetMavoVehicleAttachments(mavoVehicleId: number): Promise<MavoVehicleAttachment[]> {
    return (
      await API.GetAsync<MavoVehicleAttachment[]>(
        `${this.baseController}/mavo/attachments/${mavoVehicleId}`
      )
    ).data;
  }

  public async GetMavoVehicleAttachment(fileName: string): Promise<Blob> {
    return (await API.GetFileAsync<Blob>(`${this.baseController}/mavo/attachment/${fileName}`))
      .data;
  }

  public async RemoveMavoVehicleAttachment(fileName: string): Promise<void> {
    await API.DeleteAsync<void>(`${this.baseController}/mavo/attachment/${fileName}`);
  }

  public async DeleteMavoVehicle(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/mavo/${id}`)).data;
  }

  public async RemoveMavoVehicleOption(id: number): Promise<void> {
    await API.DeleteAsync<void>(`${this.baseController}/mavo/option/${id}`);
  }

  public async AddMavoVehicleOption(
    mavoVehicleOption: MavoVehicleOption
  ): Promise<MavoVehicleOption> {
    return (
      await API.PostAsync<MavoVehicleOption>(
        `${this.baseController}/mavo/option/add`,
        mavoVehicleOption
      )
    ).data;
  }

  public async UpdateMavoVehicleOption(
    mavoVehicleOption: MavoVehicleOption
  ): Promise<MavoVehicleOption> {
    return (
      await API.PostAsync<MavoVehicleOption>(
        `${this.baseController}/mavo/option/update`,
        mavoVehicleOption
      )
    ).data;
  }

  public async ChangeMavoVehicleStatus(
    request: ChangeMavoVehicleStatusRequest
  ): Promise<MavoVehicleStatusHistory> {
    return (
      await API.Post2Async<ChangeMavoVehicleStatusRequest, MavoVehicleStatusHistory>(
        `${this.baseController}/mavo/status`,
        request
      )
    ).data;
  }

  public async AddMavoVehicleOrder(request: MavoVehicleOrder): Promise<MavoVehicleOrder> {
    return (
      await API.Post2Async<MavoVehicleOrder, MavoVehicleOrder>(
        `${this.baseController}/mavo/order/add`,
        request
      )
    ).data;
  }

  public async AddMavoVehicleAuction(
    request: MavoVehicleAuction
  ): Promise<MavoVehicleAuctionResult> {
    return (
      await API.Post2Async<MavoVehicleAuction, MavoVehicleAuctionResult>(
        `${this.baseController}/mavo/auction/add`,
        request
      )
    ).data;
  }

  public async GetAuctions(filters: MavoVehicleAuctionsFilters): Promise<MavoVehicleAuctionsList> {
    return (
      await API.Post2Async<MavoVehicleAuctionsFilters, MavoVehicleAuctionsList>(
        `${this.baseController}/mavo/auction-vehicle-filter`,
        filters
      )
    ).data;
  }

  public async GetAuctionsFilters(): Promise<MavoVehicleAuctionsFiltersResult> {
    return (
      await API.GetAsync<MavoVehicleAuctionsFiltersResult>(
        `${this.baseController}/mavo/auction-vehicle-filters`
      )
    ).data;
  }

  public async AddMavoAuctionOffer(
    mavoVehicleAuctionOffer: MavoVehicleAuctionOffer
  ): Promise<AuctionOfferResponseDto> {
    return (
      await API.Post2Async<MavoVehicleAuctionOffer, AuctionOfferResponseDto>(
        `${this.baseController}/mavo/auction/offer/add`,
        mavoVehicleAuctionOffer
      )
    ).data;
  }

  public async GetUserAuctions(
    filters: MavoVehicleUserAuctionsFilters
  ): Promise<MavoVehicleUserAuctionsList> {
    return (
      await API.Post2Async<MavoVehicleUserAuctionsFilters, MavoVehicleUserAuctionsList>(
        `${this.baseController}/mavo/user-auction-vehicle-filter`,
        filters
      )
    ).data;
  }

  public async GetUserAuctionsFilters(): Promise<MavoVehicleUserAuctionsFiltersResult> {
    return (
      await API.GetAsync<MavoVehicleUserAuctionsFiltersResult>(
        `${this.baseController}/mavo/user-auction-vehicle-filters`
      )
    ).data;
  }

  public async GetAdminAuctions(filters: MavoAdminAuctionsFilters): Promise<MavoAdminAuctionsList> {
    return (
      await API.Post2Async<MavoAdminAuctionsFilters, MavoAdminAuctionsList>(
        `${this.baseController}/mavo/admin-auction-filter`,
        filters
      )
    ).data;
  }

  public async GetAdminAuctionsFilters(): Promise<MavoAdminAuctionsFiltersResult> {
    return (
      await API.GetAsync<MavoAdminAuctionsFiltersResult>(
        `${this.baseController}/mavo/admin-auction-filters`
      )
    ).data;
  }

  public async CloseAuction(request: CloseAuctionRequestDto): Promise<MavoVehicleAuction> {
    return (
      await API.Post2Async<CloseAuctionRequestDto, MavoVehicleAuction>(
        `${this.baseController}/mavo/auction/close`,
        request
      )
    ).data;
  }

  public async GetMavoOrders(filters: MavoOrdersFilters): Promise<MavoOrdersList> {
    return (
      await API.Post2Async<MavoOrdersFilters, MavoOrdersList>(
        `${this.baseController}/mavo/orders-filter`,
        filters
      )
    ).data;
  }

  public async GetMavoOrdersFilters(): Promise<MavoOrdersFltersResult> {
    return (
      await API.GetAsync<MavoOrdersFltersResult>(`${this.baseController}/mavo/orders-filters`)
    ).data;
  }

  public async GetMavoInvoices(filters: MavoVoInvoiceFilters): Promise<MavoVoInvoicesList> {
    return (
      await API.Post2Async<MavoVoInvoiceFilters, MavoVoInvoicesList>(
        `${this.baseController}/mavo/invoices-filter`,
        filters
      )
    ).data;
  }

  public async GetMavoInvoicesFilters(): Promise<MavoVoInvoiceFltersResult> {
    return (
      await API.GetAsync<MavoVoInvoiceFltersResult>(`${this.baseController}/mavo/invoices-filters`)
    ).data;
  }

  public async ConfirmMavoVehicleOrder(
    code: string,
    request: MavoVehicleCreateNoticeRequestDto
  ): Promise<MavoVehicleNoticeResponse> {
    return (
      await API.Post2Async<MavoVehicleCreateNoticeRequestDto, MavoVehicleNoticeResponse>(
        `${this.baseController}/mavo/report/${code}`,
        request
      )
    ).data;
  }

  public async CancelNoticeMavoVehicle(
    code: string,
    request: MavoVehicleCreateNoticeRequestDto
  ): Promise<MavoVehicleNoticeResponse> {
    return (
      await API.Post2Async<MavoVehicleCreateNoticeRequestDto, MavoVehicleNoticeResponse>(
        `${this.baseController}/mavo/report/${code}/cancel`,
        request
      )
    ).data;
  }

  public async DeclineMavoVehicleOrder(
    request: DeclineMavoVehicleOrderRequest
  ): Promise<DeclineMavoVehicleOrderResponse> {
    return (
      await API.Post2Async<DeclineMavoVehicleOrderRequest, DeclineMavoVehicleOrderResponse>(
        `${this.baseController}/mavo/order/decline`,
        request
      )
    ).data;
  }

  public async PrintMavoVehicleNotice(id: number): Promise<Blob> {
    return (await API.GetFileAsync<Blob>(`${this.baseController}/mavo/print-notice/${id}`)).data;
  }

  public async GenerateInvoiceReport(
    code: string,
    request: MavoVehicleCreateInvoiceRequestDto
  ): Promise<MavoVehicleInvoiceResponse> {
    return (
      await API.Post2Async<MavoVehicleCreateInvoiceRequestDto, MavoVehicleInvoiceResponse>(
        `${this.baseController}/mavo/reportInvoice/${code}`,
        request
      )
    ).data;
  }

  public async CloseMavoVehicleOrderRCI(
    request: MavoVehicleOrderRCICloseRequest
  ): Promise<MavoVehicleOrderRCICloseResponse> {
    return (
      await API.Post2Async<MavoVehicleOrderRCICloseRequest, MavoVehicleOrderRCICloseResponse>(
        `${this.baseController}/mavo/order/close-RCI`,
        request
      )
    ).data;
  }

  public async PrintMavoVehicleNoticeBasedInvoiceId(id: number): Promise<Blob> {
    return (
      await API.GetFileAsync<Blob>(`${this.baseController}/mavo/print-notice-based-invoiceId/${id}`)
    ).data;
  }

  public async PrintInvoice(id: number): Promise<Blob> {
    return (await API.GetFileAsync<Blob>(`${this.baseController}/mavo/print-invoice/${id}`)).data;
  }

  public async ExportInvoices(mavoVoInvoiceFilters: MavoVoInvoiceExportFilters): Promise<Blob> {
    return (
      await API.PostFileAsync<MavoVoInvoiceExportFilters, Blob>(
        `${this.baseController}/mavo/export-invoices`,
        mavoVoInvoiceFilters
      )
    ).data;
  }

  public async ExportMavoVehicles(filters: MavoVehicleFilters): Promise<Blob> {
    return (
      await API.PostFileAsync<MavoVehicleFilters, Blob>(
        `${this.baseController}/mavo/export-data`,
        filters
      )
    ).data;
  }

  public async CancelInvoiceMavoVehicle(
    code: string,
    request: MavoVehicleCreateInvoiceRequestDto
  ): Promise<MavoVehicleInvoiceResponse> {
    return (
      await API.Post2Async<MavoVehicleCreateInvoiceRequestDto, MavoVehicleInvoiceResponse>(
        `${this.baseController}/mavo/reportInvoice/${code}/cancel`,
        request
      )
    ).data;
  }

  public async GetAlcorParameterInfoList(): Promise<AlcorParameterInfo[]> {
    return (
      await API.GetAsync<AlcorParameterInfo[]>(`${this.baseController}/AlcorParameterInfo/list`)
    ).data;
  }

  public async AddAlcorParameterInfo(
    alcorParameterInfo: AlcorParameterInfo
  ): Promise<AlcorParameterInfo> {
    return (
      await API.PostAsync<AlcorParameterInfo>(
        `${this.baseController}/AlcorParameterInfo`,
        alcorParameterInfo
      )
    ).data;
  }

  public async RemoveAlcorParameterInfo(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/AlcorParameterInfo/${id}`)).data;
  }

  public async UpdateAlcorParameterInfo(alcorParameterInfo: AlcorParameterInfo): Promise<void> {
    await API.PutAsync<AlcorParameterInfo>(
      `${this.baseController}/AlcorParameterInfo`,
      alcorParameterInfo
    );
  }

  public async GetMavoAuction(id: number): Promise<MavoVehicleAuction> {
    return (
      await API.GetAsync<MavoVehicleAuction>(
        `${this.baseController}/mavo/auction/${id}`
      )
    ).data;
  }

  public async ExportAuctionOffers(mavoAuctionAdminFilters: MavoAdminAuctionsFilters): Promise<Blob> {
    return (
      await API.PostFileAsync<MavoAdminAuctionsFilters, Blob>(
        `${this.baseController}/mavo/export-auction-offers`,
        mavoAuctionAdminFilters
      )
    ).data;
  }

}
