import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import {
  Button,
  FormControlLabel,
  Grid,
  Switch
} from '@material-ui/core';
import { ScaleLoader } from 'react-spinners';
import _ from 'lodash';
import { ApplicationState, CaseSettingsActionCreators, VehicleActionCreators } from '../../store';
import { MavoVehicleActionCreators } from '../../store/actions/MavoVehicle';
import { AppContext, ApplicationContext } from '../../context/Contexts';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { IReferential } from '../../interfaces/IReferential';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import * as R from 'ramda';
import MomentUtils from '@date-io/moment';
import { ITranslatorService } from '../../services/Interfaces/ITranslatorService';
import { Utils } from '../../helpers/Utils';
import NumberFormat from 'react-number-format';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { IVehicleService } from '../../services/Interfaces/IVehicleService';
import { IMavoStockDataEditContainerProps } from '../cases/vehicleDetails/IMavoStockDataEditContainerProps';
import { MavoVehicle } from '../../interfaces/Vehicle';
import { MavoVehicleStockDetailsEditProps } from '../cases/vehicleDetails/MavoVehicleStockDetailsEditProps';

interface IMavoStockDataEditContainerState {
  isLoading: boolean;
  executing: boolean;
}


class LocalizedUtils extends MomentUtils {
  // dateFormat = "DD MM YYYY";
}

const maxNumberOfCharactersObservations = 300;

class MavoStockDataEditContainer extends React.PureComponent<
  IMavoStockDataEditContainerProps,
  IMavoStockDataEditContainerState
> {
  private translatorService!: ITranslatorService;
  private vehicleService!: IVehicleService;
  
  static contextType = ApplicationContext;
  static displayName = 'MavoStockDataEditContainer';

  state = {
    isLoading: false,
    executing: false,
    isValuationOpen: false,
    isOpen: false,
    dialogVisible: false
  } as IMavoStockDataEditContainerState;

  public componentDidMount() {
    this.setState(
      {
        isLoading: false
      }
    );

    ValidatorForm.addValidationRule('maxLength', (value) => {
      if (!value) {
        return true;
      }

      if (value.length <= maxNumberOfCharactersObservations) {
        return true;
      }

      return false;
    });
  }

  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={0}
      />
    );
  };

    
  NumberDecimalFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            },
            currentTarget: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={2}
      />
    );
  };

  NumberDecimalCurrencyFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            },
            currentTarget: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={4}
      />
    );
  };

  onChange =
    (fieldName: string, fieldNameId?: string) => (e: any, newValue?: IReferential | null) => {
      !R.isNil(fieldNameId)
        ? 
          this.props.setMavoVehicleObject({ 
            ...this.props.mavoVehicleState.mavoVehicle, 
            [fieldName]: newValue || e.target.value,
            [fieldNameId]: Utils.GetIdOrNull(newValue)
          })
        : 
          this.props.setMavoVehicleObject({ 
            ...this.props.mavoVehicleState.mavoVehicle, 
            [fieldName]: newValue || e.target.value
          })
  };

  handleNirDateChange = (
    date: MaterialUiPickersDate | null,
    value?: string | null
  ) => {
    if (date === null || !date.isValid) {
      return;
    }

    const selectedDate = date ? date.format('YYYY-MM-DD') : undefined;

    this.props.setMavoVehicleObject({
      ...this.props.mavoVehicleState.mavoVehicle,
      nirDate: selectedDate || ''
    });
  };

  
  handleSwitchValue = async (checked: boolean) => {
    this.props.setMavoVehicleObject({
      ...this.props.mavoVehicleState.mavoVehicle,
      isReserved: checked
    });
  };

  isDisabled = (): boolean => {
    return this.props.hasRights ? false : true;
  };

  submitMavoVehicleData = async () => {
    try {
      this.setState({ executing: true, isLoading: true });
      let newVehicle = { ...this.props.mavoVehicleState.mavoVehicle } as MavoVehicle;
      newVehicle = await this.vehicleService.AddMavoVehicle({
        ...newVehicle,
        vehicleOptions: []
      });

      this.props.setMavoVehicleObject(newVehicle);
      this.setState({ executing: false, isLoading: false });

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (ex) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    } 
  };

  renderVehicleDetails = () => {
    return (
      <Grid item xs={12} className="align-left m-3">
        <Grid container spacing={0}>
          <Grid item xs={3} className="d-inline-flex"> 
            <div className="text-black font-weight-bold ml-2">
              {this.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_VIN_CODE', 'Cod VIN')}: 
            </div>
            <div>
              &nbsp;{this.props.mavoVehicleState.mavoVehicle.vin}
            </div>
          </Grid>
          <Grid item xs={3} className="d-inline-flex">
            <div className="text-black font-weight-bold">
              {this.translatorService.Tranlate('VEHICLES_VEHICLE_MAKE', 'Marca')}:
            </div>
            <div>
              &nbsp;{this.props.mavoVehicleState.mavoVehicle.make ? this.props.mavoVehicleState.mavoVehicle.make.displayName : ''}
            </div>
          </Grid>
          <Grid item xs={3} className="d-inline-flex">
            <div className="text-black font-weight-bold">
              {this.translatorService.Tranlate('VEHICLES_VEHICLE_MODEL', 'Model')}:
            </div>
            <div>
              &nbsp;{this.props.mavoVehicleState.mavoVehicle.model ? this.props.mavoVehicleState.mavoVehicle.model.displayName : ''}
            </div>
          </Grid>
          <Grid item xs={3} className="d-inline-flex">
            <div className="text-black font-weight-bold">
              {this.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_STATE', 'Stare')}:
            </div>
            <div>
              &nbsp;{!R.isNil(this.props.mavoVehicleState.mavoVehicle?.currentStatus?.status) ? this.props.mavoVehicleState.mavoVehicle?.currentStatus?.status?.displayName || '' : ''}
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderStockDataForm = () => {
    return (
      <ValidatorForm
        onSubmit={() => {
          this.submitMavoVehicleData();
        }}
      >
      <div className="m-3 text-center">
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider
              libInstance={moment}
              utils={LocalizedUtils}
              locale={this.props.appState.language}
            >
              <DatePicker
                fullWidth
                className="mt-2 ml-2 pr-2"
                openTo="date"
                views={['year', 'month', 'date']}
                variant="inline"
                disabled={this.isDisabled()}
                format={this.props.appState.dateFormat.toUpperCase()}
                margin="normal"
                id="nirDate"
                label={this.translatorService.Tranlate(
                  'MAVO_VEHICLES_VEHICLE_NIR_DATE',
                  'Data NIR'
                )}
                value={this.props.mavoVehicleState.mavoVehicle.nirDate || null}
                onChange={this.handleNirDateChange}
                autoOk={true}
                // minDate={activeButton === 1 ? new Date() : this.state.minDate}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <TextValidator
              fullWidth
              disabled={this.isDisabled()}
              id="nir-number"
              className="m-2 pr-3"
              name="nirNumber"
              placeholder={this.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_NIR_NUMBER', 'Nr NIR')}
              value={this.props.mavoVehicleState.mavoVehicle.nirNumber || ''}
              onChange={(e: any) => this.onChange('nirNumber')(e)}
              label={this.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_NIR_NUMBER', 'Nr NIR')}
              InputProps={{
                inputComponent: this.NumberFormatCustom
              }}
            />
          </Grid>
        </Grid>
        {/* <Grid container spacing={0}>
          <Grid item xs={6}>
            <TextValidator
              fullWidth
              disabled={this.isDisabled()}
              id="supplier"
              className="m-2 pr-3"
              name="supplier"
              placeholder={this.translatorService.Tranlate(
                'MAVO_VEHICLES_VEHICLE_SUPPLIER',
                'Furnizor'
              )}
              value={this.props.mavoVehicleState.mavoVehicle.supplier || ''}
              onChange={(e: any) => this.onChange('supplier')(e)}
              label={this.translatorService.Tranlate(
                'MAVO_VEHICLES_VEHICLE_SUPPLIER',
                'Furnizor'
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <TextValidator
              fullWidth
              disabled={this.isDisabled()}
              id="evaluation-price"
              className="m-2 pr-3"
              name="evaluationPrice"
              placeholder={this.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_INITIAL_PRICE', 'Pret intrare')}
              value={this.props.mavoVehicleState.mavoVehicle.ttcEvaluationEurPrice || ''}
              onChange={(e: any) => this.onChange('ttcEvaluationEurPrice')(e)}
              label={this.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_INITIAL_PRICE', 'Pret intrare')}
              validators={['minNumber:1']}
              InputProps={{
                inputComponent: this.NumberDecimalFormatCustom
              }}
            />
          </Grid>
        </Grid> */}
        <Grid container spacing={0}>
            <Grid item xs={5}>
              <TextValidator
                fullWidth
                disabled={this.isDisabled()}
                id="supplier"
                className="m-2 pr-3"
                name="supplier"
                placeholder={this.translatorService.Tranlate(
                  'MAVO_VEHICLES_VEHICLE_SUPPLIER',
                  'Furnizor'
                )}
                value={this.props.mavoVehicleState.mavoVehicle.supplier || ''}
                onChange={(e: any) => this.onChange('supplier')(e)}
                label={this.translatorService.Tranlate(
                  'MAVO_VEHICLES_VEHICLE_SUPPLIER',
                  'Furnizor'
                )}
              />
            </Grid>
          <Grid item xs={5}>
            <TextValidator
              fullWidth
              disabled={this.isDisabled()}
              id="location"
              className="m-2 pr-3"
              name="location"
              placeholder={this.translatorService.Tranlate(
                'MAVO_VEHICLES_VEHICLE_LOCATION',
                'Locatie'
              )}
              value={this.props.mavoVehicleState.mavoVehicle.location || ''}
              onChange={(e: any) => this.onChange('location')(e)}
              label={this.translatorService.Tranlate(
                'MAVO_VEHICLES_VEHICLE_LOCATION',
                'Locatie'
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <div className="text-left">
              <FormControlLabel
                name="reserved"
                control={
                  <Switch
                    disabled={this.isDisabled()}
                    checked={this.props.mavoVehicleState.mavoVehicle.isReserved}
                    onChange={(e) => {
                      this.handleSwitchValue(e.target.checked)
                    }}
                    color="primary"
                  />
                }
                label={this.translatorService.Tranlate(
                  'MAVO_VEHICLE_RESERVED',
                  'Rezervat'
                )}
                labelPlacement="end"
                className="mt-4 ml-0 mr-0"
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <TextValidator
              fullWidth
              disabled={this.isDisabled()}
              id="reservations"
              className="m-2 pr-3"
              name="reservations"
              placeholder={this.translatorService.Tranlate(
                'MAVO_VEHICLES_OBSERVATIONS',
                'Observatii'
              )}
              value={this.props.mavoVehicleState.mavoVehicle.reservations || ''}
              onChange={(e: any) => this.onChange('reservations')(e)}
              label={this.translatorService.Tranlate(
                'MAVO_VEHICLES_OBSERVATIONS',
                'Observatii'
              )}
              validators={['maxLength']}
              errorMessages={[
                this.translatorService.Tranlate(
                  'VALIDATORS_MAX_LENGTH',
                  'Numar maxim de caractere: '
                ) + maxNumberOfCharactersObservations
              ]}
              multiline
              variant="outlined"
              rows={6}
            />
          </Grid>
        </Grid>
      </div>
      <div className="m-3 text-center">
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider
              libInstance={moment}
              utils={LocalizedUtils}
              locale={this.props.appState.language}
            >
              <DatePicker
                fullWidth
                className="mt-2 ml-2 pr-2"
                openTo="date"
                views={['year', 'month', 'date']}
                variant="inline"
                disabled={true}
                format={this.props.appState.dateFormat.toUpperCase()}
                margin="normal"
                id="noticeDate"
                label={this.translatorService.Tranlate(
                  'MAVO_VEHICLES_VEHICLE_NOTICE_DATE',
                  'Data aviz'
                )}
                value={this.props.mavoVehicleState.mavoVehicle.noticeDate || null}
                onChange={this.handleNirDateChange}
                autoOk={true}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <TextValidator
              fullWidth
              disabled={true}
              id="notice-number"
              className="m-2 pr-3"
              name="noticeNr"
              placeholder={this.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_NOTICE_NUMBER', 'Nr aviz')}
              value={this.props.mavoVehicleState.mavoVehicle.noticeNr || ''}
              onChange={(e: any) => this.onChange('noticeNr')(e)}
              label={this.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_NOTICE_NUMBER', 'Nr aviz')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={3}>
            <MuiPickersUtilsProvider
              libInstance={moment}
              utils={LocalizedUtils}
              locale={this.props.appState.language}
            >
              <DatePicker
                fullWidth
                className="mt-2 ml-2 pr-2"
                openTo="date"
                views={['year', 'month', 'date']}
                variant="inline"
                disabled={true}
                format={this.props.appState.dateFormat.toUpperCase()}
                margin="normal"
                id="invoiceDate"
                label={this.translatorService.Tranlate(
                  'MAVO_VEHICLES_VEHICLE_INVOICE_DATE',
                  'Data factura'
                )}
                value={this.props.mavoVehicleState.mavoVehicle.invoiceDate || null}
                onChange={this.handleNirDateChange}
                autoOk={true}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={3}>
            <TextValidator
              fullWidth
              disabled={true}
              id="invoice-number"
              className="m-2 pr-3"
              name="invoiceNr"
              placeholder={this.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_INVOICE_NUMBER', 'Nr factura')}
              value={this.props.mavoVehicleState.mavoVehicle.invoiceNr || ''}
              onChange={(e: any) => this.onChange('invoiceNr')(e)}
              label={this.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_INVOICE_NUMBER', 'Nr factura')}
            />
          </Grid>
          <Grid item xs={3}>
            <TextValidator
              fullWidth
              disabled={true}
              id="invoice-price"
              className="m-2 pr-3"
              name="invoicePrice"
              placeholder={this.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_INVOICE_PRICE', 'Pret vanzare')}
              value={this.props.mavoVehicleState.mavoVehicle.salePrice || ''}
              onChange={(e: any) => this.onChange('salePrice')(e)}
              label={this.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_INVOICE_PRICE', 'Pret vanzare')}
            />
          </Grid>
          <Grid item xs={3}>
            <TextValidator
              fullWidth
              disabled={true}
              id="currencyRate"
              className="m-2 pr-3"
              name="currencyRate"
              placeholder={this.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_INVOICE_CURRENCY', 'Curs vanzare')}
              value={this.props.mavoVehicleState.mavoVehicle.currencyRate || ''}
              onChange={(e: any) => this.onChange('currencyRate')(e)}
              label={this.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_INVOICE_CURRENCY', 'Curs vanzare')}
              InputProps={{
                inputComponent: this.NumberDecimalCurrencyFormatCustom
              }}
            />
          </Grid>
        </Grid>
      </div>
      <div className="m-3 text-center">
        <Button
          className="m-2"
          variant="contained"
          color="primary"
          type="submit"
          disabled={this.isDisabled()}
        >
          {this.translatorService.Tranlate('SAVE', 'Salveaza')}
        </Button>
      </div> 
    </ValidatorForm>
    );
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.vehicleService = (this.context as AppContext).vehicleService;

    return (
      <Fragment>
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
        </div>
        {!this.state.isLoading ? (
          <>
            <div className="m-2">
              {this.renderVehicleDetails()}
            </div>
            <div className="text-center m-2">
                {this.renderStockDataForm()}
            </div>
          </>
        ) : null}
      </Fragment>
    );
  }
}

export default connect(
  (
    state: ApplicationState,
    ownProps: MavoVehicleStockDetailsEditProps
  ) => ({
    caseSettingsState: state.caseSettings,
    appState: state.app,
    vehicleState: state.vehicle,
    mavoVehicle: state.mavoVehicle,
    ...ownProps
  }),
  { ...CaseSettingsActionCreators, ...VehicleActionCreators, ...MavoVehicleActionCreators }
)(withSnackbar(MavoStockDataEditContainer as any));
