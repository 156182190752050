import * as R from 'ramda';
import { IReferential } from '../interfaces/IReferential';
import { CurrencyRate, Provenance } from '../interfaces/Vehicle';
import { CountryCodeEnum, ValuationLaguageId } from './Constants';
import Moment from 'moment';
import { isNullOrUndefined } from 'util';
export class Utils {
  public static GetValuationLanguageId(language: string): number {
    if (language === 'en') {
      return ValuationLaguageId.en;
    }
    if (language === 'ro') {
      return ValuationLaguageId.ro;
    }
    if (language === 'nl') {
      return ValuationLaguageId.en;
    }

    return ValuationLaguageId.ro;
  }

  public static GetCurrencyRate(currencyRates: CurrencyRate[], currencyCode: string): number {
    const crrRate = currencyRates.find((item) => item.currency === currencyCode);
    if (R.isNil(crrRate)) {
      return 1;
    }

    return currencyRates.find((item) => item.currency === currencyCode)!.rate;
  }

  public static ConvertCurrencyWithRoundedRate(
    currencyRates: CurrencyRate[],
    fromCurrencyCode: string,
    toCurrencyCode: string,
    fromRate: number,
    value: number
  ): number {
    if (toCurrencyCode === fromCurrencyCode) {
      return value;
    }
    const toRate = parseFloat(this.GetCurrencyRate(currencyRates, toCurrencyCode).toFixed(2));
    return Number.parseFloat((value * (fromRate / toRate)).toFixed(2));
  }

  public static ConvertCurrency(
    currencyRates: CurrencyRate[],
    fromCurrencyCode: string,
    toCurrencyCode: string,
    value: number
  ): number {
    if (toCurrencyCode === fromCurrencyCode) {
      return value;
    }

    const fromRate = this.GetCurrencyRate(currencyRates, fromCurrencyCode);
    const toRate = this.GetCurrencyRate(currencyRates, toCurrencyCode);

    return Number.parseFloat((value * (fromRate / toRate)).toFixed(2));
  }

  /* eslint eqeqeq: 0 */
  public static GetIdOrNull = (model: IReferential | undefined | null): number | null =>
    model != undefined ? model.id : null;
  
  public static GetProvenanceIdOrNull = (model: Provenance | undefined | null): number | null =>
    model != undefined ? model.id : null;

  public static FormatDateString = (dateString: string): string => {
    return Moment.utc(dateString).local().format('YYYY/MM/DD hh:mm:ss');
  };
  public static FormatDate = (date: Date): string => {
    return Moment.utc(date).local().format('YYYY/MM/DD hh:mm:ss');
  };

  public static AddObjectToFormData = (formData: FormData, data: any, parentKey: string) => {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach((key) => {
        Utils.AddObjectToFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? '' : data;

      formData.append(parentKey, value);
    }
  };

  public static getPriceWithoutVat = (priceWithVat: number, vatRate: number): number => {
    if (vatRate <= 0) {
      return priceWithVat;
    }

    return priceWithVat / (1 + vatRate / 100);
  };

  public static getPriceWithVat = (price: number, vatRate: number): number => {
    if (vatRate <= 0) {
      return price;
    }

    return price * (1 + vatRate / 100);
  };

  public static getPriceWithMarginVat = (
    sellingPrice: number,
    purchasePrice: number,
    vatRate: number
  ) => {
    if (sellingPrice <= purchasePrice) {
      return sellingPrice;
    }

    return sellingPrice + (sellingPrice - purchasePrice) * (vatRate / 100);
  };

  public static getMarginVat = (sellingPrice: number, purchasePrice: number, vatRate: number) => {
    if (sellingPrice <= purchasePrice) {
      return 0;
    }

    return (sellingPrice - purchasePrice) * (vatRate / 100);
  };

  public static removeVatFromPriceLabel = (label: string): string => {
    return label.replace(/ *\([^)]*\) */g, '').trim();
  };

  public static validatePlateNumber(plateNumber:string):boolean {
    const plateRegex = /^[A-Z]{1,2}[0-9]{2,3}[A-Z]{3}$/;

    return plateRegex.test(plateNumber);
  }

  
  public static countryNumberFormat(numString: string, country: IReferential | null): string {
    const countryCode = country?.code ?? CountryCodeEnum.RO.toString();
    if (countryCode == CountryCodeEnum.CZ.toString()) { 
      // Convert the string to a decimal number
      const number = parseFloat(numString);      
      // Check if conversion was successful
      if (isNaN(number)) {
        return "Invalid number";
      }
  
      // Specify options for number formatting
      const options: Intl.NumberFormatOptions = {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };

      return number.toLocaleString('cs-CZ', options);
    }
    
    return numString;
  } 
}
