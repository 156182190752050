import * as React from 'react';
import { ApplicationState, AppState } from '../../store';
import { ApplicationContext, AppContext } from '../../context/Contexts';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import {
  Card,
  Grid,
  Tab,
  Tabs,
  AppBar,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Paper,
  DialogActions,
  Modal,
  DialogContentText,
  Box
} from '@material-ui/core';
import TabPanel from '../_shared/Tabs';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import { MUITranslations } from '../../helpers/MUITableTranslations';
import { IReferential } from '../../interfaces/IReferential';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withSnackbar, ProviderContext } from 'notistack';
import {
  ImportMavoVehiclesRequestDto,
  ImportVehicleAttachment,
  MavoVehicle,
  MavoVehicleFilters,
  MavoVehicleImportedDto,
  MavoVehicleList,
  Provenance,
  MavoVehicleAuction,
  MavoVehicleOrder
} from '../../interfaces/Vehicle';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Loader from '../Loader';
import noop from 'lodash/noop';
import _ from 'lodash';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import MaterialTable from 'material-table';
import { getLocalization, MaterialTableIcons } from '../_shared/TableSettings';
import { ScaleLoader } from 'react-spinners';
import { disabledNewMavoVehicleStatuses, MavoPersonTypeEnum, MavoVehicleProvenanceType, MavoVehicleStatusCodeEnum } from '../../helpers/Constants';
import { MavoVehicleActionCreators } from '../../store/actions/MavoVehicle';
import { MavoVehicleState } from '../../store/reducers/MavoVehicle';
import MavoVehicleOrderDialog from './MavoVehicleOrderDialog';
import MavoVehicleAuctionDialog from './MavoVehicleAuctionDialog';
import { AppUser } from '../../interfaces/AppUser';
import FileSaver from 'file-saver';
import {MuiThemeProvider, createMuiTheme } from '@material-ui/core';

class LocalizedUtils extends MomentUtils {
  // dateFormat = "DD MM YYYY";
}

type MavoVehiclesProps = {
  appState: AppState;
  mavoVehicleState: MavoVehicleState;
} &
  typeof MavoVehicleActionCreators &
  ProviderContext &
  RouteComponentProps;

interface IMavoVehiclesState {
  vehiclesList: MavoVehicleList;
  makeEntities: IReferential[];
  selectedMakeEntities: IReferential[];
  modelEntities: IReferential[];
  selectedModelEntities: IReferential[];
  statusEntities: IReferential[];
  selectedStatusEntities: IReferential[];
  provenanceEntities: Provenance[];
  selectedProvenanceEntities: Provenance[];
  selectedStartDate: Date | null;
  selectedEndDate: Date | null;
  selectedTab: number;
  isLoading: boolean;
  isLoadingVehicles: boolean;
  count: number;
  page: number;
  pageSize: number;
  vehicleImportDialogOpenend: boolean;
  isLoadingDialog: boolean;
  vin: string;
  isDialogOpen: boolean;
  isDialogOpenAuction: boolean,
  isDialogOpenOrder: boolean,
  isLoadingImportVehicles: boolean;
  attachments: ImportVehicleAttachment[];
  importedVehicles: MavoVehicleImportedDto[];
  isOpen: boolean;
  dialogVisible: boolean;
  selectedVehicle: MavoVehicle | null;
  auctionForm: MavoVehicleAuction;
  orderForm: MavoVehicleOrder;
  executing: boolean;
  isLoadingExport: boolean;
}

class MavoVehicles extends React.PureComponent<MavoVehiclesProps, IMavoVehiclesState> {
  private cbContext!: AppContext;
  static contextType = ApplicationContext;

  state = {
    vehiclesList: {} as MavoVehicleList,
    makeEntities: [],
    selectedMakeEntities: [],
    modelEntities: [],
    selectedModelEntities: [],
    statusEntities: [],
    selectedStatusEntities: [],
    provenanceEntities: [],
    selectedProvenanceEntities: [],
    selectedTab: 0,
    isLoading: false,
    isLoadingVehicles: false,
    count: 1,
    page: 0,
    pageSize: 10,
    vehicleImportDialogOpenend: false,
    isCarSoldDialogOpen: false,
    vehiclesCaseList: [],
    salesWokflowForm: null,
    paramList: [],
    isLoadingDialog: false,
    vin: '',
    selectedStartDate: null,
    selectedEndDate: null,
    isDialogOpen: false,
    isDialogOpenAuction: false,
    isDialogOpenOrder: false,
    isLoadingImportVehicles: false,
    attachments: [] as ImportVehicleAttachment[],
    importedVehicles: [] as MavoVehicleImportedDto[],
    isOpen: false,
    dialogVisible: false,
    selectedVehicle: null,
    auctionForm: {
      id: 0,
      currentPrice: 0,
    } as MavoVehicleAuction,
    orderForm: {
      personTypeId: MavoPersonTypeEnum.PF
    } as MavoVehicleOrder,
    executing: false,
    isLoadingExport: false
  } as IMavoVehiclesState;

  public async componentDidMount() {
    const mavoVehiclesFilters = {
      makeIds: [],
      modelIds: [],
      statusIds: [],
      provenanceIds: [],
      dateFilterStartDate: null,
      dateFilterEndDate: null,
      page: this.state.page + 1,
      pageSize: this.state.pageSize,
      vin: ''
    } as MavoVehicleFilters;

    this.setState({
      isLoading: true
    });

    const [vehicles] = await Promise.all([
      this.cbContext.vehicleService.GetMavoVehicles(mavoVehiclesFilters)
    ]);

    this.setState(
      {
        vehiclesList: vehicles,
        count: vehicles.total,
        page: vehicles.page - 1
      },
      async () => {
        await this.reloadFilters();
      }
    );
  }

  isInternal = (): boolean | null => {
    if (this.state.selectedTab === 0) {
      return null;
    }
    if (this.state.selectedTab === 1) {
      return false;
    }

    return true;
  };

  loadVehicles = async (page: number, rowsPerPage: number) => {
    this.setState({
      isLoadingVehicles: true,
      selectedVehicle: null
    });

    const mavoVehiclesFilters = {
      makeIds: this.state.selectedMakeEntities.map((item) => item.id),
      modelIds: this.state.selectedModelEntities.map((item) => item.id),
      statusIds: this.state.selectedStatusEntities.map((item) => item.id),
      provenanceIds: this.state.selectedProvenanceEntities.map((item) => item.id),
      dateFilterStartDate: this.state.selectedStartDate
      ? new Date(this.state.selectedStartDate.setHours(0, 0, 0, 0))
      : null,
      dateFilterEndDate: this.state.selectedEndDate
      ? new Date(this.state.selectedEndDate.setHours(23, 59, 59, 999))
      : null,
      page: page + 1,
      pageSize: rowsPerPage,
      vin: this.state.vin
    } as MavoVehicleFilters;

    const vehicleList = await this.cbContext.vehicleService.GetMavoVehicles(mavoVehiclesFilters);
    this.setState({
      vehiclesList: vehicleList,
      page: vehicleList.page - 1,
      pageSize: rowsPerPage,
      count: vehicleList.total,
      isLoadingVehicles: false
    });
  };

  reloadFilters = async () => {
    const filters = await this.cbContext.vehicleService.GetMavoVehiclesFilters();
    this.setState({
      makeEntities: filters.makeList,
      modelEntities: filters.modelList,
      statusEntities: filters.vehicleStatuses,
      provenanceEntities: filters.provenanceList,
      isLoading: false
    });
  };


  makeSelectedVehicle =  (isSelected: boolean, id: number) => {
    const vehicles = _.cloneDeep(this.state.vehiclesList.vehicles);
    const vehicle = vehicles.find((item) => item.id === id);
    if (vehicle) {
      vehicle.isSelected = isSelected;
    }

    if (isSelected) {
      vehicles.forEach((item) => {
        if (item.id !== id) {
          item.isSelected = false;
        }   
      });
    }
    this.setState({
      vehiclesList: {
        ...this.state.vehiclesList,
        vehicles: vehicles
      },
      selectedVehicle: isSelected ? (vehicle ? vehicle : null): null
    });
  }

  getMuiTheme = () => createMuiTheme({
    overrides: {
      MuiTableCell: {
        head: {
          paddingLeft: "5px",
          paddingRight: "5px",
          paddingTop: "5px",
          paddingBottom: "5px"
        },
      },
      MUIDataTableBodyCell: {
        stackedCommon: {
          paddingLeft: "5px",
          paddingRight: "5px",
          paddingTop: "0px",
          paddingBottom: "0px",
          height: "60px !important"
        }
      },
      MuiCheckbox: {
        colorSecondary: {
          '&.Mui-checked': {
            color: "#3d4977 !important",
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.03) !important'
            },
          },
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.03) !important'
          }
        },
      }
    } as any
  });

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'id',
        label: ' ',
        options: {
          sort: false,
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            const vehicle = this.state.vehiclesList.vehicles.find((item) => item.id === tableMeta.rowData[0]);
            return (
              <Grid container direction="row">
                <Grid item xs={12}>
                  <div className="font-size-sm">
                    <FormControlLabel
                      className="m-2"
                      control={
                        <Checkbox
                          checked={vehicle!.isSelected || false}
                          onChange={(e) => {
                            //vehicle!.isSelected = e.target.checked;
                            this.makeSelectedVehicle(e.target.checked, vehicle!.id);
                          }}
                        ></Checkbox>
                        }
                        label=''
                    />
                  </div>
                </Grid>
              </Grid>
            );
          }
        }
      },
      {
        name: 'vin',
        label: this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_VIN_CODE', 'Cod VIN'),
        options: {
          sort: false
        }
      },
      {
        name: 'make.displayName',
        label: this.cbContext.translatorService.Tranlate('VEHICLES_VEHICLE_MAKE', 'Marca'),
        options: {
          sort: false
        }
      },
      {
        name: 'model.displayName',
        label: this.cbContext.translatorService.Tranlate('VEHICLES_VEHICLE_MODEL', 'Model'),
        options: {
          sort: false
        }
      },
      {
        name: 'fabricationYear',
        label: this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_FABRICATION_YEAR', 'AnFab'),
        options: {
          sort: false
        }
      },
      {
        name: 'ttcEvaluationEurPrice',
        label: this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_EVALUATION_PRICE', 'Pret evaluare'),
        options: {
          sort: false
        }
      },
      {
        name: 'fuelType.displayName',
        label: this.cbContext.translatorService.Tranlate('CAR_DETAILS_FUEL_TYPE', 'Combustibil'),
        options: {
          sort: false
        }
      },
      {
        name: 'nirNumber',
        label: this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_NIR_NUMBER', 'Nr NIR'),
        options: {
          sort: false
        }
      },
      {
        name: 'nirDate',
        label: this.cbContext.translatorService.Tranlate(
          'MAVO_VEHICLES_VEHICLE_NIR_DATE',
          'Data NIR'
        ),
        options: {
          sort: false,
          customBodyRender: (value: any) => {
            return (
              <Grid container direction="row">
                <Grid item xs={12}>
                  <div className="font-size-sm">
                    {value
                      ? moment
                          .utc(value)
                          .local()
                          .format(this.props.appState.dateFormat)
                      : null}
                  </div>
                </Grid>
              </Grid>
            );
          }
        }
      },
      {
        name: 'dateCreation',
        label: this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_IMPORTED_DATE', 'Data import'),
        options: {
          sort: false,
          customBodyRender: (value: any) => {
            return (
              <Grid container direction="row">
                <Grid item xs={12}>
                  <div className="font-size-sm">
                    {value
                      ? moment
                          .utc(value)
                          .local()
                          .format(this.props.appState.dateFormat)
                      : null}
                  </div>
                </Grid>
              </Grid>
            );
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'currentStatus.status.displayName',
        label: this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_STATE', 'Stare'),
        options: {
          sort: false
        }
      },      
      {
        name: 'provenance.type',
        label: this.cbContext.translatorService.Tranlate(
          'MENU_NOMENCLATURES_TAB_PROVENANCE',
          'Provenienta'
        ),
        options: {
          sort: false
        }
      }
    ];

    return columns;
  };

  sortFunction = (a: any, b: any) => {
    return a.order - b.order;
  };

  onTabChange = (event: any, newValue: number) => {
    this.setState(
      {
        selectedTab: newValue,
        page: 0
      },
      async () => await this.loadVehicles(0, this.state.pageSize)
    );
  };

  handleMakeEntitiesChange = (newValue: any[]) => {
    const selectedModels = [...this.state.selectedModelEntities];
    const selectedMakeIds = newValue.map(({ id }) => id);

    const models = selectedModels.filter(({ dependencyId }) =>
      selectedMakeIds.includes(dependencyId)
    );

    this.setState(
      {
        selectedMakeEntities: newValue,
        selectedModelEntities: models
      }
    );
  };

  handleModelEntitiesChange = (newValue: any[]) => {
    this.setState(
      {
        selectedModelEntities: newValue
      }
    );
  };

  handleStatusEntitiesChange = (newValue: any[]) => {
    this.setState(
      {
        selectedStatusEntities: newValue
      }
    );
  };

  handleProvenanceEntitiesChange = (newValue: any[]) => {
    this.setState(
      {
        selectedProvenanceEntities: newValue
      }
    );
  };

  handleStartDateChange = async (date: MaterialUiPickersDate | null, value?: string | null) => {
    if (date === null || !date.isValid) {
      return;
    }
    this.setState(
      {
        selectedStartDate: date!.toDate(),
        isLoading: false
      }
    );
  };

  handleEndDateChange = async (date: MaterialUiPickersDate | null, value?: string | null) => {
    if (date === null || !date.isValid) {
      return;
    }

    this.setState(
      {
        selectedEndDate: date!.toDate(),
        isLoading: false
      }
    );
  };

  handleImportDialogOpen = () => {
    this.setState({
      isDialogOpen: true
    });
  };

  handleImportDialogClose = () => {
    this.setState({
      isDialogOpen: false
    }, async () => {
      await this.loadVehicles(this.state.page, this.state.pageSize);
    });
  };

  // Check if vehicle is valid for specific actions: Order, Auction
  isVehicleValid = (vehicle: MavoVehicle, action: string): boolean => {
    if (!vehicle.provenance) {
      return false;
    }
    if (vehicle.provenance!.type !== MavoVehicleProvenanceType.RCI && (!vehicle.nirDate || !vehicle.nirNumber)) {
      return false;
    }
  
    if (vehicle.provenance!.type === MavoVehicleProvenanceType.RCI && vehicle.nirNumber) {
      return false;
    }
  
    if (disabledNewMavoVehicleStatuses.includes(String(vehicle.currentStatus.status!.code))) {
      return false;
    }
  
    if (action === 'auction' && vehicle.isReserved) {
      return false;
    }
  
    return true;
  }

  handleDialogOpen = (action: string) => {
    if (!this.state.selectedVehicle) {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('PLEASE_SELECT_VEHICLE', 'Va rugam sa selectati un vehicul!'), {
        variant: 'warning'
      });

      return false;
    }

    if (!this.isVehicleValid(this.state.selectedVehicle, action)) {
      switch (action) {
        case 'auction':
          this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('VEHICLE_NOT_VALID_FOR_AUCTION', 'Vehiculul nu este valid pentru licitatie!'), {
            variant: 'warning',
            autoHideDuration: 5000,
            style: { whiteSpace: 'pre-line' }
          });
          break;
        case 'order':
          this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('VEHICLE_NOT_VALID_FOR_ORDER', 'Vehiculul nu este valid pentru comanda!'), {
            variant: 'warning',
            autoHideDuration: 5000,
            style: { whiteSpace: 'pre-line' }
          });
          break;
        default:
          break;
      }

      return false;
    }
    
    return true;
  }

  handleStockDialog = () => {
    if (this.state.selectedVehicle) {
     this.props.setMavoVehicleObject(null);
     this.props.history.push('/mavoVehicleStock/' + this.state.selectedVehicle.id);

    } else {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('PLEASE_SELECT_VEHICLE', 'Va rugam sa selectati un vehicul!'), {
        variant: 'warning'
      });
    }
  };

  handleDeleteDialog = () => {
    if (!this.state.selectedVehicle) {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('PLEASE_SELECT_VEHICLE', 'Va rugam sa selectati un vehicul!'), {
        variant: 'warning'
      });

      return;
    }

    this.setState({
      isOpen: true,
      dialogVisible: true
    });
  }

  handleDeleteVehicle = async () => {
    if (!this.state.selectedVehicle) {
      return;
    }

    try {
      const mavoVehicleId = this.state.selectedVehicle!.id;
      //Indisponibil
      if (this.state.selectedVehicle.currentStatus.status!.code != MavoVehicleStatusCodeEnum.INDISPONIBIL) {
        this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('ERROR_MSG_DELETE_MAVO_VEHICLE', 'Se pot sterge doar masini in stare indisponibila!'), {
          variant: 'warning'
        });

        this.handleCloseConfirmationDialog();

        return;
      }

      await this.cbContext.vehicleService.DeleteMavoVehicle(mavoVehicleId);

      const vehicles = [...this.state.vehiclesList.vehicles];
      const index = vehicles.findIndex((item) => item.id === mavoVehicleId);
      vehicles.splice(index, 1);

      this.setState({
        vehiclesList: {
          ...this.state.vehiclesList,
          vehicles: vehicles
        }
      }, () => {
        this.handleCloseConfirmationDialog();
      });

      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  
  handleExportMavoVehicles = async () => {
    try {
      this.setState({
        isLoadingExport: true
      });

      const mavoVehiclesFilters = {
        makeIds: this.state.selectedMakeEntities.map((item) => item.id),
        modelIds: this.state.selectedModelEntities.map((item) => item.id),
        statusIds: this.state.selectedStatusEntities.map((item) => item.id),
        provenanceIds: this.state.selectedProvenanceEntities.map((item) => item.id),
        dateFilterStartDate: this.state.selectedStartDate
        ? new Date(this.state.selectedStartDate.setHours(0, 0, 0, 0))
        : null,
        dateFilterEndDate: this.state.selectedEndDate
        ? new Date(this.state.selectedEndDate.setHours(23, 59, 59, 999))
        : null,
        vin: this.state.vin
      } as MavoVehicleFilters;

      const fileBlob = await this.cbContext.vehicleService.ExportMavoVehicles(mavoVehiclesFilters);
      FileSaver.saveAs(fileBlob, 'Evidenta vehicule' + '.xlsx');

      this.setState({
        isLoadingExport: false
      });
      
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (err: any) {
      this.setState({
        isLoadingExport: false
      });
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  }
  

  renderMavoVehicleButtons() {
    return (
      <div className="row m-0 mt-3">
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={this.handleImportDialogOpen}
          >
            {this.cbContext.translatorService.Tranlate(
              'MAVO_VEHICLES_VEHICLE_IMPORT_VEHICLES',
              'Import vehicule'
            )}
          </Button>
        </div>
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => {
              this.props.setMavoVehicleObject(null);
              this.props.history.push('/mavoVehicle/new');
            }}
          >
            {this.cbContext.translatorService.Tranlate(
              'MAVO_VEHICLES_VEHICLE_ADD_NEW',
              'Noua inregistrare'
            )}
          </Button>
        </div>
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => {this.handleStockDialog()}}
          >
            {this.cbContext.translatorService.Tranlate(
              'MAVO_VEHICLES_VEHICLE_MODIFIED_STOCK_DATA',
              'Editare/Vizualizare date stoc'
            )}
          </Button>
        </div>
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => {this.handleDeleteDialog()}}
          >
            {this.cbContext.translatorService.Tranlate(
              'MAVO_VEHICLES_VEHICLE_DELETE_VEHICLE',
              'Stergere inregistrare'
            )}
          </Button>
        </div>
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => {
              this.handleDialogOpen('auction') && this.setState({
                isDialogOpenAuction: true,
                auctionForm: {
                  ...this.state.auctionForm,
                  startDate: moment(new Date()).format("YYYY-MM-DD"),
                  mavoVehicleId: this.state.selectedVehicle!.id
                }
              });
            }}
          >
            {this.cbContext.translatorService.Tranlate(
              'MAVO_VEHICLES_VEHICLE_DISPOSITION_IN_THE_AUCTION',
              'Dispunere in licitatie'
            )}
          </Button>
        </div>
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => {
              this.handleDialogOpen('order') && this.setState({ isDialogOpenOrder: true, orderForm: {
                ...this.state.orderForm,
                personTypeId: MavoPersonTypeEnum.PF,
                mavoVehicleId: this.state.selectedVehicle!.id
              }});
            }}
          >
            {this.cbContext.translatorService.Tranlate(
              'MAVO_VEHICLES_VEHICLE_PLACE_ORDER',
              'Lansare comanda'
            )}
          </Button>
        </div>
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={this.handleExportMavoVehicles}
          >
            {this.cbContext.translatorService.Tranlate(
              'MAVO_VEHICLES_VEHICLE_EXPORT_DATA',
              'Export Date'
            )}
          </Button>
        </div>
      </div>

    );
  }

  renderSearchButtons() {
    return (
      <div className="row m-0 mt-3">
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => this.loadVehicles(this.state.page, this.state.pageSize)}
          >
            {this.cbContext.translatorService.Tranlate(
              'MAVO_VEHICLES_VEHICLE_SEARCH',
              'Cauta'
            )}
          </Button>
        </div>
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => this.resetFilters()}
          >
            {this.cbContext.translatorService.Tranlate(
              'MAVO_VEHICLES_VEHICLE_RESET',
              'Reset'
            )}
          </Button>
        </div>
      </div>

    );
  }

  resetFilters = async () => {
    this.setState({
      selectedMakeEntities: [],
      selectedModelEntities: [],
      selectedStatusEntities: [],
      selectedProvenanceEntities: [],
      selectedStartDate: null,
      selectedEndDate: null,
      vin: ''
    }, async () => {
      await this.loadVehicles(this.state.page, this.state.pageSize);
    });
  };

  renderFilters = () => {
    const selectedMakes = this.state.selectedMakeEntities;
    let models = this.state.modelEntities;

    if (selectedMakes.length) {
      const makeIds = selectedMakes.map(({ id }) => id);
      models = this.state.modelEntities.filter(({ dependencyId }) => {
        return makeIds.includes(dependencyId!);
      });
    }

    return (
      <div className="my-1">
        <ValidatorForm onSubmit={noop}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Autocomplete
                id="make"
                size="small"
                className="m-0 mt-2"
                multiple
                options={this.state.makeEntities.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedMakeEntities}
                onChange={(e: any, newValue: any | null) => this.handleMakeEntitiesChange(newValue)}
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="makeEntities"
                    value={this.state.selectedMakeEntities}
                    label={this.cbContext.translatorService.Tranlate(
                      'VEHICLES_VEHICLE_MAKE',
                      'Marca'
                    )}
                    fullWidth
                  />
                )}
              />

              <Autocomplete
                id="status"
                size="small"
                className="m-0 mt-2"
                multiple
                options={this.state.statusEntities.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedStatusEntities}
                onChange={(e: any, newValue: any | null) =>
                  this.handleStatusEntitiesChange(newValue)
                }
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="statusEntities"
                    value={this.state.selectedStatusEntities}
                    label={this.cbContext.translatorService.Tranlate(
                      'VEHICLES_VEHICLE_STATUS',
                      'Status'
                    )}
                    fullWidth
                  />
                )}
              />
             
              <MuiPickersUtilsProvider
                libInstance={moment}
                utils={LocalizedUtils}
                locale={this.props.appState.language}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  className="m-0 mt-2"
                  disableToolbar
                  variant="inline"
                  format={this.props.appState.dateFormat.toUpperCase()}
                  margin="normal"
                  label={this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_FILTER_START_DATE', 'Data start import')}
                  value={this.state.selectedStartDate}
                  onChange={this.handleStartDateChange}
                  autoOk={true}
                  // minDate={activeButton === 1 ? new Date() : this.state.minDate}
                />
              </MuiPickersUtilsProvider>
              <div className="mt-2">
                <TextValidator
                  fullWidth
                  id="vin"
                  name="color"
                  placeholder={this.cbContext.translatorService.Tranlate(
                    'CALCULATION_DETAILS_VIN',
                    'Vin'
                  )}
                  value={this.state.vin}
                  onChange={(e: any) => {
                    this.setState({
                      vin: e.target.value
                    });
                  }}
                  validators={['required']}
                  label={this.cbContext.translatorService.Tranlate('CALCULATION_DETAILS_VIN', 'Vin')}
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                id="model"
                size="small"
                className="m-0 mt-2"
                multiple
                options={models.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedModelEntities}
                onChange={(e: any, newValue: any | null) =>
                  this.handleModelEntitiesChange(newValue)
                }
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="modelEntities"
                    value={this.state.selectedModelEntities}
                    label={this.cbContext.translatorService.Tranlate(
                      'VEHICLES_VEHICLE_MODEL',
                      'Model'
                    )}
                    fullWidth
                  />
                )}
              />

              <Autocomplete
                id="provenance"
                size="small"
                className="m-0 mt-2"
                multiple
                options={this.state.provenanceEntities.sort(function (a, b) {
                  return a.type.localeCompare(b.type);
                })}
                value={this.state.selectedProvenanceEntities}
                onChange={(e: any, newValue: any | null) =>
                  this.handleProvenanceEntitiesChange(newValue)
                }
                getOptionLabel={(option: Provenance) => option.type || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="provenanceEntities"
                    value={this.state.selectedProvenanceEntities}
                    label={this.cbContext.translatorService.Tranlate(
                      'MENU_NOMENCLATURES_TAB_PROVENANCE',
                      'Provenienta'
                    )}
                    fullWidth
                  />
                )}
              />
      
              <MuiPickersUtilsProvider
                libInstance={moment}
                utils={LocalizedUtils}
                locale={this.props.appState.language}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  className="m-0 mt-2"
                  disableToolbar
                  variant="inline"
                  format={this.props.appState.dateFormat.toUpperCase()}
                  margin="normal"
                  label={this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_FILTER_END_DATE', 'Data stop import')}
                  value={this.state.selectedEndDate}
                  onChange={this.handleEndDateChange}
                  autoOk={true}
                />
              </MuiPickersUtilsProvider>           
            </Grid>
          </Grid>
        </ValidatorForm>
      </div>
    );
  };

  closeDialog = async () => {
    this.setState(
      { 
        isDialogOpen: false,
        attachments: [],
        importedVehicles: []
      },
      () => {
        this.loadVehicles(this.state.page, this.state.pageSize);
      }
    );
  };

  closeDialogAuction = () => {
    this.setState(
      { 
        isDialogOpenAuction: false,
        auctionForm: { 
          id: 0
        } as MavoVehicleAuction
      },
      async () => {
        await this.loadVehicles(this.state.page, this.state.pageSize)
      }
    );
  };

  closeDialogOrder = async () => {
    this.setState(
      {
        isDialogOpenOrder: false,
        orderForm: {
          id: 0
        } as MavoVehicleOrder  
      },
      async () => {
        await this.loadVehicles(this.state.page, this.state.pageSize)
      }
    );
  };

  onRowDoubleClick = (
    dataIndex: number,
    rowIndex: number,
    columnIndex: number
  ) => {
    if (columnIndex == 1) {
      return;
    }
    const vehicle = this.state.vehiclesList.vehicles[dataIndex];
    this.props.history.push('/mavoVehicle/' + vehicle.id);
  };

  tableOptions = () => {
    return {
      filter: false,
      search: false,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.cbContext.translatorService),
      elevation: 0,
      serverSide: true,
      count: this.state.count,
      rowsPerPage: this.state.pageSize,
      page: this.state.page,
      onCellClick: (colData: any, cellMeta: any) => {
        if (cellMeta && cellMeta.colIndex !== null && cellMeta.rowIndex !== null) {
          // Set up a double-click event listener on the cell
          if (cellMeta.event.detail === 2) {
            this.onRowDoubleClick(cellMeta.dataIndex, cellMeta.rowIndex, cellMeta.colIndex);
          }
        }
      },
      onTableChange: async (action: any, tableState: any) => {
        switch (action) {
          case 'changePage':
            await this.loadVehicles(tableState.page, tableState.rowsPerPage);
            break;
          case 'changeRowsPerPage':
            await this.loadVehicles(tableState.page, tableState.rowsPerPage);
            break;
          case 'sort':
            break;
          default:
        }
      }
    };
  };

  importedTableOptions = () => {
    return {
      sort: true,
      filter: false,
      search: false,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.cbContext.translatorService),
      elevation: 0,
      count: this.state.count,
      rowsPerPage: this.state.pageSize,
      page: this.state.page
    };
  };

  handleUploadAttachment = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const attachments = [...this.state.attachments];

      attachments.push({
        id: 0,
        file: e.target.files![0],
        fileName: e.target.files![0].name,
        uploadDate: new Date(),
        originalFileName: e.target.files![0].name
      });

      e.target.value = '';

      this.setState({
        attachments: attachments
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  handleDeleteAttachment = async (id: number, fileName: string) => {
    try {
      const attachments = [...this.state.attachments];
      const index = attachments.findIndex((item) => item.id === id);
      attachments.splice(index, 1);

      this.setState({
        attachments: attachments
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  canUpload = (): boolean => {
    const files = this.state.attachments;

    if (files.length >= 1) {
      return false;
    }

    return true;
  };

  handleImport = async () => {
    if (this.state.attachments.length === 0) {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('ERROR_MSG_FILE_NOT_SELECTED', 'Va rugam sa selectati un fisier!'), {
        variant: 'error'
      });
      return;
    }
    this.setState({
      isLoadingImportVehicles: true,
      executing: true
    });


    const request = {
      attachments: this.state.attachments
    } as ImportMavoVehiclesRequestDto;

    try {
      const result = await this.cbContext.vehicleService.SaveImportedMavoVehicles(request);
      this.props.enqueueSnackbar(
        <div>
          <p>
            {this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_DATA_PROCESSED', 'Date tratate: ')}{' '}
            {result.totalVehiclesNum}
          </p>
          <p>
            {this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_TREATED_SUCCESSFULLY', 'Tratate cu succes: ')}{' '}
            {result.importedVehiclesNum}
          </p>
        </div>,
        {
          variant: 'info',
          autoHideDuration: 6000
        }
      );
      this.setState({
        importedVehicles: result.mavoVehicles,
        isLoadingImportVehicles: false,
        executing: false
      });

    } catch {
      this.props.enqueueSnackbar('There was an unexpected error while importing the vehicles', {
        variant: 'error',
        autoHideDuration: 6000
      });
      this.setState({
        isLoadingImportVehicles: false,
        executing: false
      });
    }
  };


  getImportedColumns = () => {
    return [
      {
        field: 'vin',
        title: this.cbContext.translatorService.Tranlate(
          'CALCULATION_DETAILS_VIN',
          'Vin'
        ),
      },
      {
        field: 'voEnterDate',
        title: this.cbContext.translatorService.Tranlate(
          'MAVO_VEHICLES_VEHICLE_VO_ENTER_DATE',
          'Data intrare la VO'
        )
      },
      {
        field: 'makeName',
        title: this.cbContext.translatorService.Tranlate(
          'VEHICLES_VEHICLE_MAKE',
          'Marca'
        )
      },
      {
        field: 'modelName',
        title: this.cbContext.translatorService.Tranlate(
          'VEHICLES_VEHICLE_MODEL',
          'Model'
        )
      },
      {
        field: 'versionName',
        title: this.cbContext.translatorService.Tranlate(
          'MAVO_VEHICLES_VEHICLE_VERSION',
          'Versiune'
        )
      },
      {
        field: 'colorName',
        title: this.cbContext.translatorService.Tranlate(
          'MAVO_VEHICLES_VEHICLE_COLOR',
          'Culoare'
        )
      },
      {
        field: 'fabricationYear',
        title: this.cbContext.translatorService.Tranlate(
          'MAVO_VEHICLES_VEHICLE_FABRICATION_YEAR_FULL',
          'An de fabricatie'
        )
      },
      {
        field: 'kilometersNr',
        title: this.cbContext.translatorService.Tranlate(
          'MAVO_VEHICLES_VEHICLE_KM',
          'Km'
        )
      },
      {
        field: 'cylinderCapacity',
        title: this.cbContext.translatorService.Tranlate(
          'MAVO_VEHICLES_VEHICLE_CYLINDER_CAPACITY',
          'Capacitate cilindrica'
        )
      },
      {
        field: 'horsePower',
        title: this.cbContext.translatorService.Tranlate(
          'CAR_DETAILS_HORSE_POWER',
          'Putere (CP)'
        )
      },
      {
        field: 'supplier',
        title: this.cbContext.translatorService.Tranlate(
          'MAVO_VEHICLES_VEHICLE_SUPPLIER',
          'Furnizor'
        )
      },
      {
        field: 'provenanceType',
        title: this.cbContext.translatorService.Tranlate(
          'MENU_NOMENCLATURES_TAB_PROVENANCE',
          'Provenienta'
        )
      },
      {
        field: 'location',
        title: this.cbContext.translatorService.Tranlate(
          'MAVO_VEHICLES_VEHICLE_LOCATION',
          'Locatie'
        )
      },
      {
        field: 'htPurchaseRonPrice',
        title: this.cbContext.translatorService.Tranlate(
          'MAVO_VEHICLES_VEHICLE_HT_PURCHASE_RON_PRICE',
          'Pret cumparare HT'
        )
      },
      {
        field: 'ttcEvaluationEurPrice',
        title: this.cbContext.translatorService.Tranlate(
          'MAVO_VEHICLES_VEHICLE_EVALUATION_TTC_PRICE',
          'Pret evaluare TTC'
        )
      },
      {
        field: 'reservations',
        title: this.cbContext.translatorService.Tranlate(
          'MAVO_VEHICLES_VEHICLE_RESERVATIONS',
          'Rezervari'
        )
      },
      {
        field: 'nirNumber',
        title: this.cbContext.translatorService.Tranlate(
          'MAVO_VEHICLES_VEHICLE_NIR_NUMBER',
          'Nr NIR'
        )
      },
      {
        field: 'importMessage',
        title: this.cbContext.translatorService.Tranlate(
          'MAVO_VEHICLES_VEHICLE_IMPORT_MESSAGE',
          'Rezultat import'
        )
      }
    ];
  }
  renderImportedResultBox = () => {
    return this.state.importedVehicles.length > 0 ? (
      <MaterialTable
        columns={this.getImportedColumns()}
        data={this.state.importedVehicles}
        title=""
        icons={MaterialTableIcons}
        localization={getLocalization(this.cbContext.translatorService)}
        options={{
          actionsColumnIndex: -1,
          addRowPosition: 'first',
          maxBodyHeight: 400,
          paging: false,
          sorting: false,
          search: false
        }}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />
        }}
      />
    ) : '';
  }

  renderAttachmentBox = () => {
    return (
      <Grid container spacing={2} className="mt-2 mb-5">
        <Grid item xs={9}>
          {this.state.attachments.length !== 0
            ? this.state.attachments.map((attach, index) => {
                return (
                  <div key={index} className="d-flex flex-row w-100">
                    <div className="w-75 text-left">
                      <Button
                        color="primary"
                        className="text-primary text-left pl-0"
                        disabled={false}
                        onClick={(e) => {
                          console.log('download');
                        }}
                      >
                        <span className="crop">{attach.originalFileName}</span>
                      </Button>
                    </div>
                    <div className="w-25">
                      <IconButton
                        aria-label="delete"
                        color="inherit"
                        className="text-danger"
                        size="small"
                        onClick={(e) => {
                          this.handleDeleteAttachment(attach.id, attach.fileName);
                        }}
                        disabled={false}
                      >
                        <DeleteOutlineTwoToneIcon />
                      </IconButton>
                    </div>
                  </div>
                );
              })
            : null}
        </Grid>
        <Grid item xs={3} container justify="flex-end" direction="column">
          <Button
            variant="outlined"
            size="small"
            color="primary"
            className="d-block text-center shadow-none"
            component="div"
            disabled={!this.canUpload()}
          >
            <input
              accept=".xls, .xlsx"
              className="d-none"
              id={'contained-button-file'}
              onChange={(e) => {
                return this.handleUploadAttachment(e);
              }}
              type="file"
              disabled={!this.canUpload()}
            />
            <label
              htmlFor={'contained-button-file'}
              className="w-100 mb-0"
            >
               {this.cbContext.translatorService.Tranlate(
                'MAVO_VEHICLES_UPLOAD_FILE_BTN_LABEL',
                'Uploadeaza fisier'
              )}
            </label>
          </Button>
        </Grid>
      </Grid>
    );
  };

  public renderImportContent() {
    return (
      <ValidatorForm onSubmit={this.handleImport} instantValidate={true}>
        {this.renderAttachmentBox()}
        <div className="d-flex flex-row text-center flex-wrap justify-content-center">
          <ScaleLoader color={'var(--primary)'} loading={this.state.isLoadingImportVehicles} />
        </div>
        {this.renderImportedResultBox()}
        <div className="text-right">
          <Button
            className="m-2"
            variant="outlined"
            color="primary"
            onClick={this.closeDialog}
          >
            {this.cbContext.translatorService.Tranlate('MAVO_VEHICLE_IMPORT_CLOSE_BTN', 'Renunta')}
          </Button>
          <Button className="m-2" variant="contained" color="primary" type="submit" 
            disabled={this.state.executing}>
            {this.cbContext.translatorService.Tranlate('MAVO_VEHICLE_IMPORT_BTN', 'Importa')}
          </Button>
        </div>
      </ValidatorForm>
    );
  }

  handleCloseConfirmationDialog = () => {
    this.setState({
      isOpen: false,
      dialogVisible: false
    });
  };

  handleOpenConfirmationDialog = () => {
    this.setState({
      isOpen: true,
      dialogVisible: true
    });
  };

  handleCloseModal = () => {
    this.setState({
      isOpen: false,
      dialogVisible: false
    });
  };

  handleChangeAuction = (fieldName: string, isNumber: boolean) => (e: any, newValue?: IReferential | null) => {
    isNumber
      ? this.setState({
        auctionForm: {
            ...this.state.auctionForm,
            [fieldName]: parseFloat(newValue || e.target.value)
          }
        })
      : this.setState({
        auctionForm: {
            ...this.state.auctionForm,
            [fieldName]: newValue || e.target.value
          }
        });
  }

  handleChangeOrder = (fieldName: string, isNumber: boolean) => (e: any, newValue?: IReferential | null) => {
    isNumber
      ? this.setState({
        orderForm: {
            ...this.state.orderForm,
            [fieldName]: parseFloat(newValue || e.target.value)
          }
        })
      : this.setState({
        orderForm: {
            ...this.state.orderForm,
            [fieldName]: newValue || e.target.value
          }
        });
  }

    
  handleUserChange = (newValue: AppUser | null) => {
    this.setState({
      orderForm: {
        ...this.state.orderForm,
        user: newValue,
        userId: newValue ? newValue!.id : null
     }
    });
  };
  
  handleOrderDateChange = (
    date: MaterialUiPickersDate | null,
    value?: string | null
  ) => {
    if (date === null || !date.isValid) {
      return;
    }

    const selectedDate = date ? date.format('YYYY-MM-DD') : undefined;

    this.setState({
      orderForm: {
        ...this.state.orderForm,
        orderDate: selectedDate || ''
     }
    });
  };

  handleAuctionStartDateChange = (
    date: MaterialUiPickersDate | null,
    value?: string | null
  ) => {
    if (date === null || !date.isValid) {
      return;
    }

    const selectedDate = date ? date.format('YYYY-MM-DD') : undefined;

    this.setState({
      auctionForm: {
        ...this.state.auctionForm,
        startDate: selectedDate || ''
     }
    });
  };

  handleAuctionEndDateChange = (
    date: MaterialUiPickersDate | null,
    value?: string | null
  ) => {
    if (date === null || !date.isValid) {
      return;
    }

    const selectedDate = date ? date.format('YYYY-MM-DD') : undefined;

    this.setState({
      auctionForm: {
        ...this.state.auctionForm,
        endDate: selectedDate || ''
     }
    });
  };
  
  public render() {
    this.cbContext = this.context as AppContext;

    if (this.state.isLoading) {
      return <Loader />;
    }

    return (
      <React.Fragment>
        <Card>
          <AppBar position="static" elevation={0} color="secondary">
            <Tabs
              TabIndicatorProps={{
                style: {
                  height: '4px'
                }
              }}
              value={this.state.selectedTab}
              onChange={this.onTabChange}
              variant="fullWidth"
              centered={true}
              indicatorColor="primary"
              aria-label="simple tabs example"
            >
              <Tab
                label={this.cbContext.translatorService.Tranlate('MENU_MAVO_VEHICLES_RECORD', 'Evidenta vehicule')}
              />
            </Tabs>
          </AppBar>

          <div>
            <TabPanel value={this.state.selectedTab} index={0}>
              <div className="m-3">
                {this.renderMavoVehicleButtons()}
                <div className="d-flex flex-row text-center flex-wrap justify-content-center">
                  <ScaleLoader color={'var(--primary)'} loading={this.state.isLoadingExport} />
                </div>
                {this.renderFilters()}
                {this.renderSearchButtons()}
                {this.state.isLoadingVehicles ? (
                  <Loader />
                ) : (
                  <MuiThemeProvider theme={this.getMuiTheme()}>
                    <MUIDataTable
                      title=""
                      data={this.state.vehiclesList.vehicles}
                      columns={this.getColumns()}
                      options={this.tableOptions()}
                    />
                  </MuiThemeProvider>
                )}
              {this.state.isDialogOpen ? (
                <Dialog
                  onClose={this.closeDialog}
                  aria-labelledby="customized-dialog-title"
                  open={this.state.isDialogOpen}
                  fullScreen={true}
                  fullWidth={true}
                  >
                  <DialogTitle
                    id="customized-dialog-title"
                    style={{
                      color: '#ffffff',
                      backgroundColor: '#1F2857',
                      textAlign: 'center'
                    }}
                  >
                    <span style={{ fontSize: '20px', lineHeight: '24px' }}>
                      {this.cbContext.translatorService.Tranlate(
                      'MAVO_VEHICLES_VEHICLE_IMPORT_VEHICLES',
                      'Import vehicule'
                      )}                    
                    </span>
                    <IconButton
                      aria-label="close"
                      className="position-absolute"
                      style={{ right: 12, top: 12, color: '#ffffff' }}
                      onClick={this.closeDialog}
                      size={'small'}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent dividers>
                    <Box m={1} p={1}>
                      {this.renderImportContent()}
                    </Box>
                  </DialogContent>
                </Dialog>
                ) : (
                  ''
                )}
              {this.state.isDialogOpenAuction ? (
                <MavoVehicleAuctionDialog
                  isDialogOpenAuction={this.state.isDialogOpenAuction}
                  closeDialogAuction={this.closeDialogAuction}
                  auctionForm={this.state.auctionForm}
                  handleChangeAuction={this.handleChangeAuction}
                  appState={this.props.appState}
                  vehicleId={this.state.selectedVehicle?.id}
                  handleStartDateChange={this.handleAuctionStartDateChange}
                  handleEndDateChange={this.handleAuctionEndDateChange}
                />
              ) : (
                ''
              )}
              {this.state.isDialogOpenOrder ? (
                <MavoVehicleOrderDialog
                  isDialogOpenOrder={this.state.isDialogOpenOrder}
                  closeDialogOrder={this.closeDialogOrder}
                  orderForm={this.state.orderForm}
                  handleChangeOrder={this.handleChangeOrder}
                  handleUserChange={this.handleUserChange}
                  handleOrderDateChange={this.handleOrderDateChange}
                  appState={this.props.appState}
                  vehicleId={this.state.selectedVehicle?.id}
                />
              ) : (
                ''
              )} 

              {this.state.selectedVehicle ? (
                <Modal open={this.state.isOpen} onClose={this.handleCloseModal}>
                  <Dialog open={this.state.dialogVisible} onClose={this.handleCloseConfirmationDialog}>
                    <DialogContent>
                      <DialogContentText style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                        {`${this.cbContext.translatorService.Tranlate(
                          'MAVO_VEHICLE_DELETE_CONFIRMATION_MESSAGE',
                          'Ati selectat vehiculul '
                        )} `}
                        {this.state.selectedVehicle.vin!}
                        {`${this.cbContext.translatorService.Tranlate(
                          'MAVO_VEHICLE_DELETE_CONFIRMATION_MESSAGE_1',
                          ' pentru stergere. Autorizati stergerea sa din baza de date?'
                        )} `}         
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        autoFocus
                        onClick={() => {
                          this.handleDeleteVehicle();
                        }}
                      >
                        {this.cbContext.translatorService.Tranlate('VEHICLE_SEARCH_FORM_VEHICLE_YES', 'Da')}
                      </Button>
                      <Button onClick={() => this.handleCloseConfirmationDialog()}>
                        {this.cbContext.translatorService.Tranlate('VEHICLE_SEARCH_FORM_VEHICLE_NO', 'Nu')}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Modal>
                ): ''}
               
              </div>
            </TabPanel>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}
export default connect(
  (state: ApplicationState) => ({
    appState: state.app,
    mavoVehicleState: state.mavoVehicle
  }),
{ ...MavoVehicleActionCreators }
)(withSnackbar(MavoVehicles as any));