import { Action, Reducer } from 'redux';
import * as ActionTypes from '../actions/ActionTypes';
import { MavoVehicle, MavoVehicleAttachment, MavoVehicleOption } from '../../interfaces/Vehicle';
import { MavoVehicleAction } from '../actions/MavoVehicle';

export interface MavoVehicleState {
  mavoVehicle: MavoVehicle;
}

const initialState: MavoVehicleState = {
  mavoVehicle: {
    id: 0,
    vin: '',
    make: null,
    makeId: null,
    model: null,
    modelId: null,
    versionItem: null,
    versionId: null,
    color: null,
    colorId: null,
    fuelType: null,
    fuelTypeId: null,
    bodyTypeId: null,
    bodyType: null,
    transmissionTypeId: null,
    transmissionType: null,
    provenance: null,
    provenanceId: null,
    firstRegistrationDate: null,
    fabricationYear: null,
    kilometersNr: null,
    power: null,
    horsePower: null,
    engineManufactureCode: null,
    accessories: null,
    isReserved: false,
    reservations: null,
    cylinderCapacity: null,
    supplier: null,
    location: null,
    htPurchaseRonPrice: null,
    ttcEvaluationEurPrice: null,
    nirNumber: null,
    nirDate: null,
    currentStatus: null,
    vehicleOptions: [] as MavoVehicleOption[],
    mavoVehicleAttachments: [] as MavoVehicleAttachment[],
    isMavoVehicle: true
  } as unknown as MavoVehicle
};

export const MavoVehicleReducer: Reducer<MavoVehicleState | undefined> = (
  state: MavoVehicleState | undefined,
  incomingAction: Action
): MavoVehicleState | undefined => {
  if (state === undefined) {
    return initialState;
  }

  const action = incomingAction as MavoVehicleAction;

  switch (action.type) {
    case ActionTypes.MAVO_VEHICLE_SET_VEHICLE:
      if (action.mavoVehicle === null)
        return {
          ...state,
          mavoVehicle: initialState.mavoVehicle
        };

      return {
        ...state,
        mavoVehicle: {
          ...action.mavoVehicle
        }
      };
    case ActionTypes.MAVO_VEHICLE_SET_VEHICLE_STATUS:
      return {
        ...state,
        mavoVehicle: {
          ...state.mavoVehicle!,
          currentStatus: { ...action.status }
        }
      };
  }

  return state;
};
