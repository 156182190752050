import * as React from 'react';
import { ApplicationState, AppState } from '../../store';
import { ApplicationContext, AppContext } from '../../context/Contexts';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import {
  Card,
  Grid,
  Tab,
  Tabs,
  AppBar,
  TextField,
  Button,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import TabPanel from '../_shared/Tabs';
import MUIDataTable, {
  MUIDataTableColumnOptions,
  MUIDataTableMeta,
  SelectableRows
} from 'mui-datatables';
import { MUITranslations } from '../../helpers/MUITableTranslations';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withSnackbar, ProviderContext } from 'notistack';
import {
  MavoVoInvoiceFilters,
  MavoVoInvoicesList,
  MavoVehicleInvoice,
  MavoVoInvoiceExportFilters,
  MavoVehicleCreateInvoiceRequestDto
} from '../../interfaces/Vehicle';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Loader from '../Loader';
import noop from 'lodash/noop';
import _ from 'lodash';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MavoVehicleActionCreators } from '../../store/actions/MavoVehicle';
import { MavoVehicleState } from '../../store/reducers/MavoVehicle';
import NumberFormat from 'react-number-format';
import { VehicleAuctionActionCreators } from '../../store/actions/VehicleAuction';
import { AppUser } from '../../interfaces/AppUser';
import { IReferential } from '../../interfaces/IReferential';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import FileSaver from 'file-saver';
import { ScaleLoader } from 'react-spinners';
import { MavoVehicleInvoiceStatusEnum, ReportTypeCode, RoleClaimsEnum } from '../../helpers/Constants';
import {MuiThemeProvider, createMuiTheme } from '@material-ui/core';

class LocalizedUtils extends MomentUtils {
  // dateFormat = "DD MM YYYY";
}

type VoInvoiceHistoryProps = {
  appState: AppState;
  mavoVehicleState: MavoVehicleState
} &
  typeof MavoVehicleActionCreators & typeof VehicleAuctionActionCreators &
  ProviderContext &
  RouteComponentProps;

interface IVoInvoiceHistoryState {
  invoicesList: MavoVoInvoicesList;
  selectedAgentEntities: AppUser[];
  agentList: AppUser[];
  statusEntities: IReferential[];
  selectedStatusEntities: IReferential[];
  selectedStartDate: Date | null;
  selectedEndDate: Date | null;
  billNr: string;
  selectedTab: number;
  isLoading: boolean;
  isLoadingInvoices: boolean;
  count: number;
  page: number;
  pageSize: number;
  executing: boolean;
  selectedInvoice: MavoVehicleInvoice | null;
  isLoadingExport: boolean;
}

class VoInvoiceHistory extends React.PureComponent<VoInvoiceHistoryProps, IVoInvoiceHistoryState> {
  private cbContext!: AppContext;
  static contextType = ApplicationContext;

  state = {
    invoicesList: {} as MavoVoInvoicesList,
    selectedAgentEntities: [],
    agentList: [],
    provenanceEntities: [],
    selectedProvenanceEntities: [],
    statusEntities: [],
    selectedStatusEntities: [],
    selectedStartDate: null,
    selectedEndDate: null,
    billNr: '',
    selectedTab: 0,
    isLoading: false,
    isLoadingInvoices: false,
    count: 1,
    page: 0,
    pageSize: 10,
    executing: false,
    isDialogOpen: false,
    isOfferDialogOpen: false,
    selectedInvoice: null,
    isLoadingExport: false
  } as IVoInvoiceHistoryState;

  public async componentDidMount() {
    const mavoInvoicesFilters = {
      agentIds: [],
      statusIds: [],
      startDate: null,
      endDate: null,
      billNr: '',
      page: this.state.page + 1,
      pageSize: this.state.pageSize
    }as MavoVoInvoiceFilters;

    this.setState({
      isLoading: true
    });

    const [invoicesList] = await Promise.all([
      this.cbContext.vehicleService.GetMavoInvoices(mavoInvoicesFilters)
    ]);

    this.setState(
      {
        invoicesList: invoicesList,
        count: invoicesList.total,
        page: invoicesList.page - 1
      },
      async () => {
        await this.reloadFilters();
      }
    );
  }

  isInternal = (): boolean | null => {
    if (this.state.selectedTab === 0) {
      return null;
    }
    if (this.state.selectedTab === 1) {
      return false;
    }

    return true;
  };

  loadInvoices = async (page: number, rowsPerPage: number) => {
    this.setState({
      isLoadingInvoices: true,
      selectedInvoice: null 
    });

    const mavoInvoicesFilters = {
      statusIds: this.state.selectedStatusEntities.map((item) => item.id),
      agentIds: this.state.selectedAgentEntities.map((item) => item.id),
      startDate: this.state.selectedStartDate
      ? new Date(this.state.selectedStartDate.setHours(0, 0, 0, 0))
      : null,
      endDate: this.state.selectedEndDate
      ? new Date(this.state.selectedEndDate.setHours(23, 59, 59, 999))
      : null,
      billNr: this.state.billNr,
      page: page + 1,
      pageSize: rowsPerPage
    } as MavoVoInvoiceFilters;

    const invoicesList = await this.cbContext.vehicleService.GetMavoInvoices(mavoInvoicesFilters);
    
    this.setState({
      invoicesList: invoicesList,
      page: invoicesList.page - 1,
      pageSize: rowsPerPage,
      count: invoicesList.total,
      isLoadingInvoices: false
    });
  };

  reloadFilters = async () => {
    const filters = await this.cbContext.vehicleService.GetMavoInvoicesFilters();
    const createdByList =  await this.cbContext.appUserService.GetUsersInfo(filters.agentList);
    this.setState({
      statusEntities: filters.statusList,
      agentList: createdByList,
      isLoading: false
    });
  };


  makeSelectedInvoice =  (isSelected: boolean, id: number) => {
    const invoices = _.cloneDeep(this.state.invoicesList.invoices);
    const invoice = invoices.find((item) => item.id === id);
    if (invoice) {
      invoice.isSelected = isSelected;
    }

    if (isSelected) {
      invoices.forEach((item) => {
        if (item.id !== id) {
          item.isSelected = false;
        }   
      });
    }
    this.setState({
      invoicesList: {
        ...this.state.invoicesList,
        invoices: invoices
      },
      selectedInvoice: isSelected ? (invoice ? invoice : null): null
    });
  }

  getMuiTheme = () => createMuiTheme({
    overrides: {
      MuiTableCell: {
        head: {
          paddingLeft: "5px",
          paddingRight: "5px",
          paddingTop: "5px",
          paddingBottom: "5px"
        },
      },
      MUIDataTableBodyCell: {
        stackedCommon: {
          paddingLeft: "5px",
          paddingRight: "5px",
          paddingTop: "0px",
          paddingBottom: "0px",
          height: "60px !important"
        }
      },
      MuiCheckbox: {
        colorSecondary: {
          '&.Mui-checked': {
            color: "#3d4977 !important",
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.03) !important'
            },
          },
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.03) !important'
          }
        },
      }
    } as any
  });

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'id',
        label: ' ',
        options: {
          sort: false,
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            const invoice = this.state.invoicesList.invoices.find((item) => item.id === tableMeta.rowData[0]);
            return (
              <Grid container direction="row">
                <Grid item xs={12}>
                  <div className="font-size-sm">
                    <FormControlLabel
                      className="m-2"
                      control={
                        <Checkbox
                          checked={invoice!.isSelected || false}
                          onChange={(e) => {
                            this.makeSelectedInvoice(e.target.checked, invoice!.id);
                          }}
                        ></Checkbox>
                        }
                        label=''
                    />
                  </div>
                </Grid>
              </Grid>
            );
          }
        }
      },
      {
        name: 'mavoVehicle.vin',
        label: this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_VIN_CODE', 'Cod VIN'),
        options: {
          sort: false
        }
      },
      {
        name: 'mavoVehicle.make.displayName',
        label: this.cbContext.translatorService.Tranlate('VEHICLES_VEHICLE_MAKE', 'Marca'),
        options: {
          sort: false
        }
      },
      {
        name: 'mavoVehicle.model.displayName',
        label: this.cbContext.translatorService.Tranlate('VEHICLES_VEHICLE_MODEL', 'Model'),
        options: {
          sort: false
        }
      },     
      {
        name: 'mavoVehicle.fabricationYear',
        label: this.cbContext.translatorService.Tranlate(
          'MAVO_VEHICLE_INVOICE_FABRICATION_YEAR',
          'An fabr'
        ),
        options: {
          sort: false
        }
      }, 
      {
        name: 'ronPriceWithoutVat',
        label: this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_INVOICE_RON_PRICE', 'Pret vanzare (lei)'),
        options: {
          sort: false,
          customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
            const invoice = this.state.invoicesList.invoices.find((item) => item.id === tableMeta.rowData[0]);
            return (
              <Grid container direction="row">
                <Grid item xs={12}>
                  {invoice != null ? (invoice.ronPriceWithoutVat + invoice.vatAmount).toFixed(2) : null}
                </Grid>
              </Grid>
            )
          }
        }
      },
      {
        name: 'billNr',
        label: this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_INVOICE_NUMBER', 'Nr factura'),
        options: {
          sort: false
        }
      },
      {
        name: 'invoiceDate',
        label: this.cbContext.translatorService.Tranlate(
          'MAVO_VEHICLES_VEHICLE_INVOICE_DATE',
          'Data factura'
        ),
        options: {
          sort: false,
          customBodyRender: (value: any) => {
            return (
              <Grid container direction="row">
                <Grid item xs={12}>
                  <div className="font-size-sm">
                    {value
                      ? moment
                          .utc(value)
                          .local()
                          .format(this.props.appState.dateFormat)
                      : null}
                  </div>
                </Grid>
              </Grid>
            );
          }
        }
      }, 
      {
        name: 'currentStatus.invoiceStatus.displayName',
        label: this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_INVOICE_STATE', 'Stare factura'),
        options: {
          sort: false
        }
      },    
    ];

    return columns;
  };

  sortFunction = (a: any, b: any) => {
    return a.order - b.order;
  };

  onTabChange = (event: any, newValue: number) => {
    this.setState(
      {
        selectedTab: newValue,
        page: 0
      },
      async () => await this.loadInvoices(0, this.state.pageSize)
    );
  };



  renderSearchButtons() {
    return (
      <div className="row m-0 mt-3">
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => this.loadInvoices(this.state.page, this.state.pageSize)}
          >
            {this.cbContext.translatorService.Tranlate(
              'MAVO_VEHICLES_VEHICLE_SEARCH',
              'Cautare'
            )}
          </Button>
        </div>
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => this.resetFilters()}
          >
            {this.cbContext.translatorService.Tranlate(
              'MAVO_VEHICLES_VEHICLE_RESET',
              'Reset'
            )}
          </Button>
        </div>
      </div>

    );
  }

  resetFilters = async () => {
    this.setState({
      selectedStatusEntities: [],
      selectedAgentEntities: [],
      selectedStartDate: null,
      selectedEndDate: null,
      billNr: ''
    }, async () => {
      await this.loadInvoices(this.state.page, this.state.pageSize);
    });
  };

  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            },
            currentTarget: {
              value: values.value
            }
          });
        }}
        allowNegative={false}
        thousandSeparator={false}
        decimalScale={2}
      />
    );
  };
  
  handleAgentChange = (newValue: any[]) => {
    this.setState(
      {
        selectedAgentEntities: newValue
      }
    );
  };

  handleStatusEntitiesChange = (newValue: any[]) => {
    this.setState(
      {
        selectedStatusEntities: newValue
      }
    );
  };

  handleStartDateChange = async (date: MaterialUiPickersDate | null, value?: string | null) => {
    if (date === null || !date.isValid) {
      return;
    }
    this.setState(
      {
        selectedStartDate: date!.toDate(),
        isLoading: false
      }
    );
  };

  handleEndDateChange = async (date: MaterialUiPickersDate | null, value?: string | null) => {
    if (date === null || !date.isValid) {
      return;
    }

    this.setState(
      {
        selectedEndDate: date!.toDate(),
        isLoading: false
      }
    );
  };

  renderFilters = () => {
    return (
      <div className="my-1">
        <ValidatorForm onSubmit={noop}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Autocomplete
                id="agent"
                className="m-0 mt-2"
                size="small"
                multiple
                options={this.state.agentList.sort(function (a, b) {
                  return a.userName.localeCompare(b.userName);
                })}
                value={this.state.selectedAgentEntities}
                onChange={(e: any, newValue: any | null) => this.handleAgentChange(newValue)}
                getOptionLabel={(option: AppUser) => option.userName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="agent"
                    value={this.state.selectedAgentEntities}
                    label={this.cbContext.translatorService.Tranlate(
                      'MAVO_ORDER_AGENT',
                      'Agent'
                    )}
                    fullWidth
                  />
                )}
              />
              <MuiPickersUtilsProvider
                libInstance={moment}
                utils={LocalizedUtils}
                locale={this.props.appState.language}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  className="m-0 mt-2"
                  disableToolbar
                  variant="inline"
                  format={this.props.appState.dateFormat.toUpperCase()}
                  margin="normal"
                  label={this.cbContext.translatorService.Tranlate('AUCTIONS_FILTER_START_DATE', 'Data start')}
                  value={this.state.selectedStartDate}
                  onChange={this.handleStartDateChange}
                  autoOk={true}
                  // minDate={activeButton === 1 ? new Date() : this.state.minDate}
                />
              </MuiPickersUtilsProvider> 

              <div className="mt-2">
                <TextValidator
                  fullWidth
                  id="billNumber"
                  name="billNumber"
                  placeholder={this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_INVOICE_NUMBER', 'Nr factura')}
                  value={this.state.billNr}
                  onChange={(e: any) => {
                    this.setState({
                      billNr: e.target.value
                    });
                  }}
                  validators={['required']}
                  label={this.cbContext.translatorService.Tranlate('MAVO_VEHICLES_VEHICLE_INVOICE_NUMBER', 'Nr factura')}
                />
              </div> 
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="status"
                size="small"
                className="m-0 mt-2"
                multiple
                options={this.state.statusEntities.sort(function (a, b) {
                  return a.displayName.localeCompare(b.displayName);
                })}
                value={this.state.selectedStatusEntities}
                onChange={(e: any, newValue: any | null) =>
                  this.handleStatusEntitiesChange(newValue)
                }
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="statusEntities"
                    value={this.state.selectedStatusEntities}
                    label={this.cbContext.translatorService.Tranlate(
                      'MAVO_INVOICE_STATUS',
                      'Stare'
                    )}
                    fullWidth
                  />
                )}
              />
              <MuiPickersUtilsProvider
                libInstance={moment}
                utils={LocalizedUtils}
                locale={this.props.appState.language}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  className="m-0 mt-2"
                  disableToolbar
                  variant="inline"
                  format={this.props.appState.dateFormat.toUpperCase()}
                  margin="normal"
                  label={this.cbContext.translatorService.Tranlate('AUCTIONS_FILTER_END_DATE', 'Data stop')}
                  value={this.state.selectedEndDate}
                  onChange={this.handleEndDateChange}
                  autoOk={true}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </ValidatorForm>
      </div>
    );
  };


  tableOptions = () => {
    return {
      sort: true,
      filter: false,
      search: false,
      viewColumns: false,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.cbContext.translatorService),
      elevation: 0,
      serverSide: true,
      count: this.state.count,
      rowsPerPage: this.state.pageSize,
      page: this.state.page,
      onTableChange: async (action: any, tableState: any) => {
        switch (action) {
          case 'changePage':
            await this.loadInvoices(tableState.page, tableState.rowsPerPage);
            break;
          case 'changeRowsPerPage':
            await this.loadInvoices(tableState.page, tableState.rowsPerPage);
            break;
          case 'sort':
            break;
          default:
        }
      }
    };
  };

  handleBackToStock = async () => {
    if (!this.state.selectedInvoice) {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('PLEASE_SELECT_LINE', 'Va rugam sa selectati o linie!'), {
        variant: 'warning'
      });
      return;
    }

    if (!this.state.selectedInvoice.currentStatus) {
      return;
    }

    if (this.state.selectedInvoice.currentStatus.invoiceStatus!.code === MavoVehicleInvoiceStatusEnum.STORNO.toString() 
      || this.state.selectedInvoice.currentStatus.invoiceStatus!.code === MavoVehicleInvoiceStatusEnum.STORNATA.toString()) {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('INVOICE_BACK_TO_STOCK_WARNING', 'Operatia este disponibila doar pentru facturile in stare Valida!'), {
        variant: 'warning'
      });
      return;
    }


    try {
      const request = {
        mavoVehicleOrderId: this.state.selectedInvoice.mavoVehicleOrderId,
        userId: this.state.selectedInvoice.mavoVehicleOrder.userId ?? null,
        invoiceId: this.state.selectedInvoice.id
      } as MavoVehicleCreateInvoiceRequestDto;
      
      await this.cbContext.vehicleService.CancelInvoiceMavoVehicle(ReportTypeCode.RENAULT_FACTURA_LIVRARE, request);

      this.loadInvoices(this.state.page, this.state.pageSize);

      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  }

  handlePrintNotice = async () => {
    if (!this.state.selectedInvoice) {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('PLEASE_SELECT_LINE', 'Va rugam sa selectati o linie!'), {
        variant: 'warning'
      });
      return;
    }

    if (!this.state.selectedInvoice.currentStatus) {
      return;
    }

    if (this.state.selectedInvoice.currentStatus.invoiceStatus!.code === MavoVehicleInvoiceStatusEnum.STORNO.toString()) {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('INVOICE_PRINT_NOTICE_FOR_STORNO_INVOICE', 'Se pot tipari doar avizele care au factura Valida sau Stornata!'), {
        variant: 'warning'
      });
      return;
    }

    try {
      const fileBlob = await this.cbContext.vehicleService.PrintMavoVehicleNoticeBasedInvoiceId(this.state.selectedInvoice.id);
      const file = new File([fileBlob], this.cbContext.translatorService.Tranlate('PRINT_NOTICE_NAME', 'Avizare plata bancara') + '.pdf', { type: 'application/pdf' });
      
      FileSaver.saveAs(fileBlob, "AvizarePlataBancara" + '.pdf');

      const url = URL.createObjectURL(file);
      window.open(url, '_blank');
    } catch {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  }

  handlePrintInvoice = async () => {
    if (!this.state.selectedInvoice) {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('PLEASE_SELECT_LINE', 'Va rugam sa selectati o linie!'), {
        variant: 'warning'
      });
      return;
    }
    if (!this.state.selectedInvoice.currentStatus) {
      return;
    }

    if (this.state.selectedInvoice.currentStatus.invoiceStatus!.code === MavoVehicleInvoiceStatusEnum.STORNATA.toString()) {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('INVOICE_PRINT_FOR_STORNATA_INVOICE', 'Nu pot fi tiparite decat facturi cu stare Valida sau Storno!'), {
        variant: 'warning'
      });
      return;
    }

    const isStorno = this.state.selectedInvoice.currentStatus.invoiceStatus!.code === MavoVehicleInvoiceStatusEnum.STORNO.toString();
    try {
      const fileBlob = await this.cbContext.vehicleService.PrintInvoice(this.state.selectedInvoice.id);
      const file = new File([fileBlob], this.cbContext.translatorService.Tranlate('PRINT_INVOICE_NAME', 'Factura livrare') + '.pdf', { type: 'application/pdf' });
      
      FileSaver.saveAs(fileBlob, isStorno ? "FacturaLivrareStorno" : "FacturaLivrare" + '.pdf');

      const url = URL.createObjectURL(file);
      window.open(url, '_blank');
    } catch {
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  }

  handleExportInvoices = async () => {
    try {
      this.setState({
        isLoadingExport: true
      });
      const mavoInvoicesFilters = {
        startDate: this.state.selectedStartDate
        ? new Date(this.state.selectedStartDate.setHours(0, 0, 0, 0))
        : null,
        endDate: this.state.selectedEndDate
        ? new Date(this.state.selectedEndDate.setHours(23, 59, 59, 999))
        : null,
        billNr: this.state.billNr
      } as MavoVoInvoiceExportFilters;
  
      const fileBlob = await this.cbContext.vehicleService.ExportInvoices(mavoInvoicesFilters);

      FileSaver.saveAs(fileBlob, 'Export VO Facturat' + '.xlsx');
      this.setState({
        isLoadingExport: false
      });
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (err: any) {
      this.setState({
        isLoadingExport: false
      });
      this.props.enqueueSnackbar(this.cbContext.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  }

  public hasRight = (right: string): boolean => {
    return this.props.appState!.user!.profile.role.includes(right);
  };

  
  renderMavoInvoicesButtons() {
    return (
      <div className="row m-0 mt-3">
        <div className='mb-3 mr-2'>
          {this.hasRight(RoleClaimsEnum.MavoAdministrator) ? (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={this.handleBackToStock}
            >
              {this.cbContext.translatorService.Tranlate(
                'RETURN_STOCK',
                'Retur Stoc'
              )}
            </Button>
          ) : ''}
        </div>
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={this.handlePrintNotice}
          >
            {this.cbContext.translatorService.Tranlate(
              'PRINT_NOTICE',
              'Tiparire aviz'
            )}
          </Button>
        </div>
        <div className='mb-3 mr-2'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={this.handlePrintInvoice}
          >
            {this.cbContext.translatorService.Tranlate(
              'PRINT_INVOICE',
              'Tiparire factura'
            )}
          </Button>
        </div>
        <div className='mb-3 mr-2'>
          {this.hasRight(RoleClaimsEnum.MavoAdministrator) ?  (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={this.handleExportInvoices}
            >
              {this.cbContext.translatorService.Tranlate(
                'EXPORT_DATA',
                'Export'
              )}
            </Button>
          ) : ''}
        </div>
      </div>
    );
  }


  public render() {
    this.cbContext = this.context as AppContext;

    if (this.state.isLoading) {
      return <Loader />;
    }

    return (
      <React.Fragment>
        <Card>
          <AppBar position="static" elevation={0} color="secondary">
            <Tabs
              TabIndicatorProps={{
                style: {
                  height: '4px'
                }
              }}
              value={this.state.selectedTab}
              onChange={this.onTabChange}
              variant="fullWidth"
              centered={true}
              indicatorColor="primary"
              aria-label="simple tabs example"
            >
              <Tab
                label={this.cbContext.translatorService.Tranlate('VO_INVOICE_TITLE', 'Cautare facturi VO')}
                />
            </Tabs>
          </AppBar>

          <div>
            <TabPanel value={this.state.selectedTab} index={0}>
              <div className="m-3">
                {this.renderMavoInvoicesButtons()}
                <div className="d-flex flex-row text-center flex-wrap justify-content-center">
                  <ScaleLoader color={'var(--primary)'} loading={this.state.isLoadingExport} />
                </div>
                {this.renderFilters()}
                {this.renderSearchButtons()}
                {this.state.isLoadingInvoices ? (
                  <Loader />
                ) : (
                  <MuiThemeProvider theme={this.getMuiTheme()}>
                    <MUIDataTable
                      title=""
                      data={this.state.invoicesList.invoices}
                      columns={this.getColumns()}
                      options={this.tableOptions()}
                    />
                  </MuiThemeProvider>
                )}       
              </div>
            </TabPanel>
          </div>
        </Card> 
      </React.Fragment>
    );
  }
}
export default connect(
  (state: ApplicationState) => ({
    appState: state.app,
    mavoVehicleState: state.mavoVehicle
  }),
{ ...MavoVehicleActionCreators }
)(withSnackbar(VoInvoiceHistory as any));