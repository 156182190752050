import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  AppUserAdminActionCreators,
  ApplicationState,
  AppActionCreators,
  AppState
} from '../../../store';
import { withSnackbar, ProviderContext } from 'notistack';
import {
  Button,
  Card,
  Tabs,
  Tab,
  CardContent,
  Box,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  DialogContent,
  Switch,
  TextField
} from '@material-ui/core';
import { ITranslatorService } from '../../../services/Interfaces/ITranslatorService';
import { IOrganizationService } from '../../../services/Interfaces/IOrganizationService';
import { ApplicationContext, AppContext } from '../../../context/Contexts';
import { Workflow, WorkflowParameter } from '../../../interfaces/Workflow';
import {
  Organization,
  OrganizationCaseType,
  Language,
  OrganizationParameter
} from '../../../interfaces/Organization';
import { IWorkflowService } from '../../../services/Interfaces/IWorkflowService';
import { RouteComponentProps } from 'react-router';
import { ScaleLoader } from 'react-spinners';
import MUIDataTable, {
  SelectableRows,
  MUIDataTableColumnOptions,
  MUIDataTableMeta
} from 'mui-datatables';
import { MUITranslations } from '../../../helpers/MUITableTranslations';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import CloseIcon from '@material-ui/icons/Close';
import { isNullOrUndefined } from 'util';
import moment from 'moment';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { IReferentialService } from '../../../services/Interfaces/IReferentialService';
import Referentials from '../../../helpers/Referentials.json';
import { ReferentialCode, RoleClaimsEnum, RoleCodeEnum } from '../../../helpers/Constants';
import { IReferential, ITranslation } from '../../../interfaces/IReferential';
import Autocomplete from '@material-ui/lab/Autocomplete';
import NumberFormat from 'react-number-format';

interface IWorkflowsState {
  selectedTab: number;
  workflows: Workflow[];
  isLoading: boolean;
  organizationId: number;
  isDialogOpen: boolean;
  newWorkflow: Workflow;
  caseTypeList: IReferential[];
}

type IWorkflowsProps = { appState: AppState } & ProviderContext &
  RouteComponentProps<{ id: string }>;

class Workflows extends React.PureComponent<IWorkflowsProps, IWorkflowsState> {
  private translatorService!: ITranslatorService;
  private organizationService!: IOrganizationService;
  private appReferentialService!: IReferentialService;
  private workflowService!: IWorkflowService;

  static contextType = ApplicationContext;

  state = {
    selectedTab: 0,
    workflows: [],
    isLoading: false,
    organizationId: 0,
    isDialogOpen: false,
    newWorkflow: {
      id: 0,
      name: '',
      displayName: '',
      isActive: true,
      organizationOwnerId: 0,
      organizationOwner: null,
      caseType: null,
      caseTypeId: 0,
      translations: [],
      workflowParameters: [],
      workflowSections: [],
      workflowSteps: [],
      workflowPartners: [],
      workflowTariffs: [],
      workflowSuppliers: [],
      isPrcIntegration: false,
      isMvoIntegration: false,
      isCarfixIntegration: false,
      isDgpciIntegration: false
    } as unknown as Workflow,
    caseTypeList: []
  } as IWorkflowsState;

  componentDidMount = () => {
    this.setState(
      {
        isLoading: true
      },
      () => {
        this.loadData(0);
      }
    );
  };

  public hasRole = (roleCode: string): boolean => {
    return this.props.appState.appUser?.userRole?.code === roleCode;
  };
  
  loadData = async (selectedTab: number) => {
    const hoId = Number.parseInt(this.props.match.params.id);

    if (Number.isNaN(hoId)) {
      return;
    }

    try {
      const ref = Referentials.referential.find((item) => item.code === ReferentialCode.CaseType);
      const [data, hoList, orgCaseTypes] = await Promise.all([
        this.workflowService.GetWorkflows(hoId),
        this.organizationService.GetHOOrganizations(this.hasRight(RoleClaimsEnum.AdminCountryOrganization) ? this.props.appState.appUser!.countryId : null),
        this.appReferentialService.Get(ref!.baseUrl)
      ]);

      data.forEach((value) => {
        const ho = hoList.find((org) => org.id === value.organizationOwnerId);
        value.organizationOwner = isNullOrUndefined(ho) ? null : ho;

        const workflowCaseType = orgCaseTypes.find((item) => item.id === value.caseTypeId);
        value.caseType = isNullOrUndefined(workflowCaseType) ? null : workflowCaseType;
      });

      const newWorkflows = data.filter(
        (workflow: Workflow) =>
          (selectedTab === 0 && workflow.organizationOwnerId === hoId) ||
          (selectedTab === 1 && workflow.organizationOwnerId !== hoId)
      );

      this.setState({
        workflows: newWorkflows,
        isLoading: false,
        organizationId: hoId
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  public hasRight = (right: string): boolean => {
    return this.props.appState!.user!.profile.role.includes(right);
  };

  newWorkflow = async () => {
    const newWorkflow = { ...this.state.newWorkflow };
    newWorkflow.organizationOwnerId = this.state.organizationId;

    const ref = Referentials.referential.find((item) => item.code === ReferentialCode.CaseType);
    const [owner, parameters, orgCaseList, orgCaseTypes] = await Promise.all([
      this.organizationService.GetOrganization(newWorkflow.organizationOwnerId),
      this.organizationService.GetOrganizationParameters(newWorkflow.organizationOwnerId),
      this.organizationService.GetOrganizationCaseType(newWorkflow.organizationOwnerId),
      this.appReferentialService.Get(ref!.baseUrl)
    ]);

    newWorkflow.organizationOwner = owner !== undefined ? owner : ({} as Organization);

    const languages = this.activeLanguages();

    let organizationCases = orgCaseList.sort(function (a, b) {
      return a.order - b.order;
    });
    organizationCases = organizationCases.map((item: OrganizationCaseType) => {
      item.caseType = orgCaseTypes.find(
        (caseType: IReferential) => caseType.id === item.caseTypeId
      )!;
      return item;
    });

    const workflowTranslations = newWorkflow.translations;
    languages.forEach((language: Language, index: number) => {
      if (workflowTranslations.length === 0) {
        workflowTranslations.push({
          id: 0,
          referentialTypeId: 0,
          name: newWorkflow.organizationOwner!.name + ' - ',
          language: language.code
        });
      } else {
        const indexTrans = workflowTranslations.findIndex((trans: ITranslation) => {
          return language.code === trans.language;
        });
        if (indexTrans === -1) {
          workflowTranslations.push({
            id: 0,
            referentialTypeId: 0,
            name: newWorkflow.organizationOwner!.name + ' - ',
            language: language.code
          });
        }
      }
    });
    newWorkflow.translations = [...workflowTranslations];
    const activeLanguage = this.translatorService.GetLanguage();

    const workflowParametersData = parameters.map((item: OrganizationParameter) => {
      const workflowParameter = {
        id: 0,
        parameterId: item.parameterId,
        date: item.date,
        workflowId: 0,
        value: item.value
      } as WorkflowParameter;
      return workflowParameter;
    });
    newWorkflow.name =
      workflowTranslations.length === 0
        ? ''
        : workflowTranslations.find((item) => item.language === activeLanguage)!.name!;
    newWorkflow.displayName = newWorkflow.name;
    newWorkflow.workflowParameters = workflowParametersData;

    this.setState({
      newWorkflow: newWorkflow,
      caseTypeList: organizationCases.map((item) => item.caseType!),
      isDialogOpen: true
    });
  };

  onRowClick = async (e: any, workflowId: number, value: string) => {
    const workflow = this.state.workflows.find((item) => item.id === workflowId);
    if (workflow === undefined) {
      return;
    }
    this.props.history.push(
      '/admin/hoorganizations/' + workflow.organizationOwnerId + '/workflow/' + workflowId,
      value
    );
  };

  handleIsActiveChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    tableMeta: MUIDataTableMeta
  ) => {
    const id = tableMeta.rowData[0];
    const newWorkflows = [...this.state.workflows];
    const updatedWorkflowIndex = newWorkflows.findIndex((o) => o.id === id);

    if (updatedWorkflowIndex >= 0) {
      newWorkflows[updatedWorkflowIndex] = {
        ...newWorkflows[updatedWorkflowIndex],
        isActive: event.target.checked
      };

      try {
        await this.workflowService.UpdateWorkflow(newWorkflows[updatedWorkflowIndex]);

        this.setState({ workflows: newWorkflows });

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };

  handleIsPrcIntegrationChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    tableMeta: MUIDataTableMeta
  ) => {
    const id = tableMeta.rowData[0];
    const newWorkflows = [...this.state.workflows];
    const updatedWorkflowIndex = newWorkflows.findIndex((o) => o.id === id);

    if (updatedWorkflowIndex >= 0) {
      newWorkflows[updatedWorkflowIndex] = {
        ...newWorkflows[updatedWorkflowIndex],
        isPrcIntegration: event.target.checked
      };

      try {
        await this.workflowService.UpdateWorkflow(newWorkflows[updatedWorkflowIndex]);

        this.setState({ workflows: newWorkflows });

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };

  handleIsMvoIntegrationChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    tableMeta: MUIDataTableMeta
  ) => {
    const id = tableMeta.rowData[0];
    const newWorkflows = [...this.state.workflows];
    const updatedWorkflowIndex = newWorkflows.findIndex((o) => o.id === id);

    if (updatedWorkflowIndex >= 0) {
      newWorkflows[updatedWorkflowIndex] = {
        ...newWorkflows[updatedWorkflowIndex],
        isMvoIntegration: event.target.checked
      };

      try {
        await this.workflowService.UpdateWorkflow(newWorkflows[updatedWorkflowIndex]);

        this.setState({ workflows: newWorkflows });

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };

  handleIsCarfixIntegrationChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    tableMeta: MUIDataTableMeta
  ) => {
    const id = tableMeta.rowData[0];
    const newWorkflows = [...this.state.workflows];
    const updatedWorkflowIndex = newWorkflows.findIndex((o) => o.id === id);

    if (updatedWorkflowIndex >= 0) {
      newWorkflows[updatedWorkflowIndex] = {
        ...newWorkflows[updatedWorkflowIndex],
        isCarfixIntegration: event.target.checked
      };

      try {
        await this.workflowService.UpdateWorkflow(newWorkflows[updatedWorkflowIndex]);

        this.setState({ workflows: newWorkflows });

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };

  handleIsDgpciIntegrationChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    tableMeta: MUIDataTableMeta
  ) => {
    const id = tableMeta.rowData[0];
    const newWorkflows = [...this.state.workflows];
    const updatedWorkflowIndex = newWorkflows.findIndex((o) => o.id === id);

    if (updatedWorkflowIndex >= 0) {
      newWorkflows[updatedWorkflowIndex] = {
        ...newWorkflows[updatedWorkflowIndex],
        isDgpciIntegration: event.target.checked
      };

      try {
        await this.workflowService.UpdateWorkflow(newWorkflows[updatedWorkflowIndex]);

        this.setState({ workflows: newWorkflows });

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };

  handleUseRoundUpChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    tableMeta: MUIDataTableMeta
  ) => {
    const id = tableMeta.rowData[0];
    const newWorkflows = [...this.state.workflows];
    const updatedWorkflowIndex = newWorkflows.findIndex((o) => o.id === id);

    if (updatedWorkflowIndex >= 0) {
      newWorkflows[updatedWorkflowIndex] = {
        ...newWorkflows[updatedWorkflowIndex],
        useRoundUp: event.target.checked
      };

      try {
        await this.workflowService.UpdateWorkflow(newWorkflows[updatedWorkflowIndex]);

        this.setState({ workflows: newWorkflows });

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };

  handleAutomatePlaceOrderChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    tableMeta: MUIDataTableMeta
  ) => {
    const id = tableMeta.rowData[0];
    const newWorkflows = [...this.state.workflows];
    const updatedWorkflowIndex = newWorkflows.findIndex((o) => o.id === id);

    if (updatedWorkflowIndex >= 0) {
      newWorkflows[updatedWorkflowIndex] = {
        ...newWorkflows[updatedWorkflowIndex],
        automatePlaceOrder: event.target.checked
      };

      try {
        await this.workflowService.UpdateWorkflow(newWorkflows[updatedWorkflowIndex]);

        this.setState({ workflows: newWorkflows });

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };

  handleAutomateGenerateOrderChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    tableMeta: MUIDataTableMeta
  ) => {
    const id = tableMeta.rowData[0];
    const newWorkflows = [...this.state.workflows];
    const updatedWorkflowIndex = newWorkflows.findIndex((o) => o.id === id);

    if (updatedWorkflowIndex >= 0) {
      newWorkflows[updatedWorkflowIndex] = {
        ...newWorkflows[updatedWorkflowIndex],
        automateGenerateOrder: event.target.checked
      };

      try {
        await this.workflowService.UpdateWorkflow(newWorkflows[updatedWorkflowIndex]);

        this.setState({ workflows: newWorkflows });

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };

  handleIsEvaluationWorklfowChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    tableMeta: MUIDataTableMeta
  ) => {
    const id = tableMeta.rowData[0];
    const newWorkflows = [...this.state.workflows];
    const updatedWorkflowIndex = newWorkflows.findIndex((o) => o.id === id);

    if (updatedWorkflowIndex >= 0) {
      newWorkflows[updatedWorkflowIndex] = {
        ...newWorkflows[updatedWorkflowIndex],
        isEvaluationWorkflow: event.target.checked
      };

      try {
        await this.workflowService.UpdateWorkflow(newWorkflows[updatedWorkflowIndex]);

        this.setState({ workflows: newWorkflows });

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };

  handleHideRenewAdvertChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    tableMeta: MUIDataTableMeta
  ) => {
    const id = tableMeta.rowData[0];
    const newWorkflows = [...this.state.workflows];
    const updatedWorkflowIndex = newWorkflows.findIndex((o) => o.id === id);

    if (updatedWorkflowIndex >= 0) {
      newWorkflows[updatedWorkflowIndex] = {
        ...newWorkflows[updatedWorkflowIndex],
        hideRenewAdvert: event.target.checked
      };

      try {
        await this.workflowService.UpdateWorkflow(newWorkflows[updatedWorkflowIndex]);

        this.setState({ workflows: newWorkflows });

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };


  handleHideCommentsChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    tableMeta: MUIDataTableMeta
  ) => {
    const id = tableMeta.rowData[0];
    const newWorkflows = [...this.state.workflows];
    const updatedWorkflowIndex = newWorkflows.findIndex((o) => o.id === id);

    if (updatedWorkflowIndex >= 0) {
      newWorkflows[updatedWorkflowIndex] = {
        ...newWorkflows[updatedWorkflowIndex],
        hideComments: event.target.checked
      };

      try {
        await this.workflowService.UpdateWorkflow(newWorkflows[updatedWorkflowIndex]);

        this.setState({ workflows: newWorkflows });

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };

  handleHasNonStockValidationChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    tableMeta: MUIDataTableMeta
  ) => {
    const id = tableMeta.rowData[0];
    const newWorkflows = [...this.state.workflows];
    const updatedWorkflowIndex = newWorkflows.findIndex((o) => o.id === id);

    if (updatedWorkflowIndex >= 0) {
      newWorkflows[updatedWorkflowIndex] = {
        ...newWorkflows[updatedWorkflowIndex],
        hasNonStockValidation: event.target.checked
      };

      try {
        await this.workflowService.UpdateWorkflow(newWorkflows[updatedWorkflowIndex]);

        this.setState({ workflows: newWorkflows });

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };

  handleVQInterogation = async (
    value: string | null,
    tableMeta: MUIDataTableMeta
  ) => {
    const id = tableMeta.rowData[0];
    const newWorkflows = [...this.state.workflows];
    const updatedWorkflowIndex = newWorkflows.findIndex((o) => o.id === id);

    if (updatedWorkflowIndex >= 0) {
      newWorkflows[updatedWorkflowIndex] = {
        ...newWorkflows[updatedWorkflowIndex],
        vqInterogationValue: value === null ? null : parseFloat(value)
      };

      this.setState({ workflows: newWorkflows });

      try {
        await this.workflowService.UpdateWorkflow(newWorkflows[updatedWorkflowIndex]);

        this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
          variant: 'success'
        });
      } catch (error) {
        this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
          variant: 'error'
        });
      }
    }
  };


  saveWorkflow = async () => {
    try {
      let workflowExist = -1;
      this.state.workflows.forEach((item: Workflow, index: number) => {
        if (
          item.caseTypeId === this.state.newWorkflow.caseTypeId &&
          item.organizationOwnerId === this.state.newWorkflow.organizationOwnerId
        ) {
          let indexFinded = -1;
          for (const i of item.translations) {
            indexFinded = Math.max(
              indexFinded,
              this.state.newWorkflow.translations.findIndex(
                (item) => item.language === i.language && item.name === i.name
              )
            );
          }
          workflowExist = indexFinded;
          return;
        } else {
          workflowExist = Math.max(workflowExist, -1);
        }
      });

      if (workflowExist !== -1) {
        this.props.enqueueSnackbar(
          this.translatorService.Tranlate('ERROR_MSG_WORKFLOW_EXISTS', 'Fluxul exista deja!'),
          { variant: 'error' }
        );
        return;
      }

      await this.workflowService.AddWorkflow(this.state.newWorkflow);

      this.closeDialog();

      await this.loadData(this.state.selectedTab);

      this.props.enqueueSnackbar(this.translatorService.Tranlate('SUCCES_MSG', 'OK'), {
        variant: 'success'
      });
    } catch (error) {
      this.props.enqueueSnackbar(this.translatorService.Tranlate('ERROR_MSG', 'Eroare'), {
        variant: 'error'
      });
    }
  };

  getColumns = () => {
    const columns = [
      {
        name: 'id',
        options: { display: 'excluded', filter: false } as MUIDataTableColumnOptions
      },
      {
        name: 'displayName',
        label: this.translatorService.Tranlate('WORKFLOWS_WORKFLOW_NAME_HEADER', 'Flux'),
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => {
            return (
              <Button
                color="primary"
                className="text-primary"
                onClick={(e) => this.onRowClick(e, tableMeta.rowData[0], value)}
              >
                <span>{value}</span>
              </Button>
            );
          }
        }
      },
      {
        name: 'organizationOwner',
        label: this.translatorService.Tranlate(
          'WORKFLOWS_WORKFLOW_OWNER_HEADER',
          'Proprietar Flux'
        ),
        options: {
          sort: false,
          customBodyRender: (value: Organization) => {
            return isNullOrUndefined(value) ? '' : value.name;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'caseType',
        label: this.translatorService.Tranlate('WORKFLOWS_WORKFLOW_CASE_TYPE_HEADER', 'Tip Dosar'),
        options: {
          sort: false,
          customBodyRender: (value: IReferential) => {
            return isNullOrUndefined(value) ? '' : value.displayName;
          }
        } as MUIDataTableColumnOptions
      },
      {
        name: 'dateModification',
        label: this.translatorService.Tranlate(
          'WORKFLOWS_WORKFLOW_DATE_MODIFICATION_HEADER',
          'Data Modificare'
        ),
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value: Date) =>
            moment(value).toDate().toLocaleString(this.props.appState.language)
        } as MUIDataTableColumnOptions
      },
      {
        name: 'isActive',
        label: this.translatorService.Tranlate('USERS_IS_ACTIVE_HEADER', 'Activ'),
        options: {
          sort: false,
          filterOptions: {
            names: [
              this.translatorService.Tranlate('TABLE_IS_ACTIVE_COLUMN_ACTIVE', 'Activ'),
              this.translatorService.Tranlate('TABLE_IS_ACTIVE_COLUMN_INACTIVE', 'Inactiv')
            ],
            logic(isActive: any, filterVal: string[]) {
              const show =
                (filterVal.indexOf(this.names[0]) !== -1 && isActive === true) ||
                (filterVal.indexOf(this.names[1]) !== -1 && isActive === false);
              return !show;
            }
          },
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <Switch
                checked={value}
                onChange={(e) => this.handleIsActiveChange(e, tableMeta)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            );
          }
        }
      },
      {
        name: 'isEvaluationWorkflow',
        label: this.translatorService.Tranlate(
          'EVALUATION_WORKFLOW_HEADER',
          'Flux Evaluare'
        ),
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <Switch
                checked={value}
                onChange={(e) => this.handleIsEvaluationWorklfowChange(e, tableMeta)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            );
          }
        }
      },
      {
        name: 'hideRenewAdvert',
        label: this.translatorService.Tranlate(
          'HIDE_RENEW_ADVERT_WORKFLOW_HEADER',
          'Ascunde anunturi Renew'
        ),
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <Switch
                checked={value}
                onChange={(e) => this.handleHideRenewAdvertChange(e, tableMeta)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            );
          }
        }
      },
      {
        name: 'isPrcIntegration',
        label: this.translatorService.Tranlate(
          'PRC_INTEGRATION_WORKFLOW_HEADER',
          'Flux Integrare PRC'
        ),
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <Switch
                checked={value}
                onChange={(e) => this.handleIsPrcIntegrationChange(e, tableMeta)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            );
          }
        }
      },
      {
        name: 'isMvoIntegration',
        label: this.translatorService.Tranlate(
          'MVO_INTEGRATION_WORKFLOW_HEADER',
          'Flux MVO'
        ),
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <Switch
                checked={value}
                onChange={(e) => this.handleIsMvoIntegrationChange(e, tableMeta)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            );
          }
        }
      },
      {
        name: 'isCarfixIntegration',
        label: this.translatorService.Tranlate(
          'REPAIR_INTEGRATION_CARFIX_WORKFLOW_HEADER',
          'Flux Integrare Carfix'
        ),
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <Switch
                checked={value}
                onChange={(e) => this.handleIsCarfixIntegrationChange(e, tableMeta)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            );
          }
        }
      },
      {
        name: 'isDgpciIntegration',
        label: this.translatorService.Tranlate(
          'DGPCI_INTEGRATION_WORKFLOW_HEADER',
          'Flux Integrare DGPCI'
        ),
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <Switch
                checked={value}
                onChange={(e) => this.handleIsDgpciIntegrationChange(e, tableMeta)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            );
          }
        }
      },
      {
        name: 'useRoundUp',
        label: this.translatorService.Tranlate(
          'USER_ROUND_UP_WORKFLOW_HEADER',
          'Cu/fara rotunjire ulei'
        ),
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <Switch
                checked={value}
                onChange={(e) => this.handleUseRoundUpChange(e, tableMeta)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            );
          }
        }
      },
      {
        name: 'automateGenerateOrder',
        label: this.translatorService.Tranlate(
          'AUTOMATE_GENERATE_ORDER_WORKFLOW_HEADER',
          'Automatizare generare comenzi'
        ),
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <Switch
                checked={value}
                onChange={(e) => this.handleAutomateGenerateOrderChange(e, tableMeta)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            );
          }
        }
      },
      {
        name: 'automatePlaceOrder',
        label: this.translatorService.Tranlate(
          'AUTOMATE_PLACE_ORDER_WORKFLOW_HEADER',
          'Automatizare comanda piese'
        ),
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <Switch
                checked={value}
                onChange={(e) => this.handleAutomatePlaceOrderChange(e, tableMeta)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            );
          }
        }
      },
      {
        name: 'hideComments',
        label: this.translatorService.Tranlate(
          'HIDE_COMMENTS_WORKFLOW_HEADER',
          'Ascunde tab comentarii'
        ),
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <Switch
                checked={value}
                onChange={(e) => this.handleHideCommentsChange(e, tableMeta)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            );
          }
        }
      },
      {
        name: 'hasNonStockValidation',
        label: this.translatorService.Tranlate(
          'HAS_NON_STOCK_VALIDATION_WORKFLOW_HEADER',
          'Verificare non-stock furnizori'
        ),
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value: boolean, tableMeta: MUIDataTableMeta) => {
            return (
              <Switch
                checked={value}
                onChange={(e) => this.handleHasNonStockValidationChange(e, tableMeta)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            );
          }
        }
      },
      {
        name: 'vqInterogationValue',
        label: this.translatorService.Tranlate(
          'VQ_INTEROGATION_VALUE_WORKFLOW_HEADER',
          'Nr. apelari VQ'
        ),  options: {
          sort: false,
          filter: false,
          customBodyRender: (value: number | null, tableMeta: MUIDataTableMeta) => {
            return (
              <TextField
                fullWidth
                className="m-2"
                value={value}
                onBlur={(e) => this.handleVQInterogation(e.target.value, tableMeta)}
                InputProps={{
                  inputComponent: this.NumberFormatCustom
                }}
              />
            );
          }
        }
      }
    ];

    return columns;
  };

  NumberFormatCustom = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values: any) => {
          onChange({
            target: {
              value: values.value
            }
          });
        }}
        thousandSeparator={false}
        decimalScale={2}
        isNumericString
      />
    );
  };


  onTabChange = (event: any, newValue: number) => {
    this.setState(
      {
        selectedTab: newValue,
        isLoading: true
      },
      () => {
        this.loadData(newValue);
      }
    );
  };

  closeDialog = async () => {
    this.setState({ isDialogOpen: false });
  };

  activeLanguages = () => {
    const activeLanguages =
      this.props.appState.appUser!.organization!.gtOrganization!.languages!.filter(
        (language: any) => {
          if (language.isActive === true) {
            return language;
          }
        }
      );
    activeLanguages.forEach((language: any) => {
      language.displayName =
        language.displayName.charAt(0).toUpperCase() + language.displayName.slice(1);
    });
    return activeLanguages;
  };

  renderNameTextFields = () => {
    if (Object.keys(this.state.newWorkflow).length === 0) {
      return null;
    } else {
      const nameTextFieldsList = [] as any[];
      this.state.newWorkflow!.translations.forEach((item: ITranslation, index: number) => {
        nameTextFieldsList.push(
          <Grid key={index} item xs={6}>
            <TextValidator
              fullWidth
              name={'name_' + index}
              className="m-2"
              value={item.name || ''}
              onChange={(e) => this.handleWorkflowNameChange(e, item.language)}
              label={
                this.translatorService.Tranlate('EDIT_WORKFLOW_GUI_NAME_LABEL', 'Nume GUI') +
                ' - ' +
                item.language
              }
              validators={['required']}
              errorMessages={[
                this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
              ]}
            />
          </Grid>
        );
      });
      return nameTextFieldsList;
    }
  };

  handleWorkflowNameChange = (event: any, code: string | null) => {
    let newWorkflowTransList = [] as ITranslation[];
    if (this.state.newWorkflow.translations) {
      newWorkflowTransList = this.state.newWorkflow.translations.map((loc: any) => {
        if (loc.language === code) {
          loc.name = event.target.value;
        }
        return loc;
      });
    }
    this.setState({
      newWorkflow: {
        ...this.state.newWorkflow,
        translations: newWorkflowTransList,
        displayName: newWorkflowTransList[0].name!
      }
    });
  };

  handleWorkflowCaseTypeChange = (newValue: IReferential | null) => {
    this.setState({
      newWorkflow: {
        ...this.state.newWorkflow,
        caseType: newValue,
        caseTypeId: newValue !== null ? newValue!.id : 0
      }
    });
  };

  public render() {
    this.translatorService = (this.context as AppContext).translatorService;
    this.organizationService = (this.context as AppContext).organizationService;

    this.appReferentialService = (this.context as AppContext).referentialService;
    this.workflowService = (this.context as AppContext).workflowService;

    const options = {
      filter: true,
      selectableRows: 'none' as SelectableRows,
      selectableRowsOnClick: false,
      print: false,
      download: false,
      textLabels: MUITranslations.GetTranslations(this.translatorService),
      sort: false,
      // responsive: "stacked",
      customToolbar: () => {
        return (
          <Tooltip title={this.translatorService.Tranlate('Add', 'Adauga')}>
            <IconButton aria-label="add" color="primary" className="m-2" onClick={this.newWorkflow}>
              <AddCircleTwoToneIcon />
            </IconButton>
          </Tooltip>
        );
      }
    };

    return (
      <Fragment>
        <Card>
          <CardContent>
            <Tabs
              value={this.state.selectedTab}
              indicatorColor="primary"
              textColor="secondary"
              variant="standard"
              onChange={this.onTabChange}
            >
              <Tab
                className="text-capitalize "
                label={this.translatorService.Tranlate('TARIFFS_TAB_OWN', 'Proprii')}
              />
              <Tab
                className="text-capitalize "
                label={this.translatorService.Tranlate('TARIFFS_TAB_PARTNERS', 'Parteneri')}
              />
            </Tabs>
          </CardContent>
        </Card>
        <Box mt={1} pt={1}>
          <div className="d-flex flex-row text-center flex-wrap justify-content-center">
            <ScaleLoader color={'var(--primary)'} loading={this.state.isLoading} />
          </div>
          {!this.state.isLoading ? (
            <MUIDataTable
              title={''}
              data={this.state.workflows}
              columns={this.getColumns()}
              options={options}
            />
          ) : null}
        </Box>

        <Dialog
          onClose={this.closeDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.isDialogOpen}
          fullWidth={true}
          maxWidth={'sm'}
        >
          <DialogTitle id="customized-dialog-title">
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="h4">
                  {this.translatorService.Tranlate(
                    'WORKFLORS_NEW_WORKFLOW_MODAL_TITLE',
                    'Adauga flux'
                  )}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton aria-label="close" onClick={this.closeDialog} size={'small'}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <ValidatorForm onSubmit={this.saveWorkflow} instantValidate={true}>
              <TextValidator
                fullWidth
                name="organziationOwner"
                className="m-2"
                id="organziationOwner"
                disabled={true}
                value={
                  this.state.newWorkflow.organizationOwner === null
                    ? ''
                    : this.state.newWorkflow.organizationOwner!.name || ''
                }
                label={this.translatorService.Tranlate(
                  'EDIT_WORKFLOW_OWNER_LABEL',
                  'Proprietar flux'
                )}
                validators={['required']}
                errorMessages={[
                  this.translatorService.Tranlate('VALIDATORS_REQUIRED', 'Campul este obligatoriu')
                ]}
              />

              <Autocomplete
                id="caseType"
                className="m-2"
                options={this.state.caseTypeList}
                value={this.state.newWorkflow.caseType}
                onChange={(e: any, newValue: IReferential | null) =>
                  this.handleWorkflowCaseTypeChange(newValue)
                }
                getOptionLabel={(option: IReferential) => option.displayName || ''}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    name="casetype"
                    value={this.state.newWorkflow.caseType}
                    label={this.translatorService.Tranlate(
                      'EDIT_WORKFLOW_CASE_TYPE_LABEL',
                      'Tip dosar'
                    )}
                    fullWidth
                    validators={['required']}
                    errorMessages={[
                      this.translatorService.Tranlate(
                        'VALIDATORS_REQUIRED',
                        'Campul este obligatoriu'
                      )
                    ]}
                  />
                )}
              />

              <Grid container spacing={2} className="mt-2">
                {this.renderNameTextFields()}
              </Grid>

              <div className="text-right">
                <Button
                  className="m-2"
                  variant="outlined"
                  color="primary"
                  onClick={this.closeDialog}
                >
                  {this.translatorService.Tranlate('EDIT_ORGANIZATION_CANCEL_BTN', 'Anuleaza')}
                </Button>
                <Button className="m-2" variant="contained" color="primary" type="submit">
                  {this.translatorService.Tranlate('EDIT_ORGANIZATION_SAVE_BTN', 'Salveaza')}
                </Button>
              </div>
            </ValidatorForm>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

export default connect((state: ApplicationState) => ({ appState: state.app }), {
  ...AppUserAdminActionCreators,
  ...AppActionCreators
})(withSnackbar(Workflows as any));
