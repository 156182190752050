import { ITariffService } from './Interfaces/ITariffService';
import { IOrganizationService } from '../services/Interfaces/IOrganizationService';
import { TariffValue } from '../interfaces/Tariff';
import { API } from '../helpers/API';
import { ICaseService } from './Interfaces/ICaseService';
import {
  Notice,
  CaseComment,
  Case,
  CaseStatusHistory,
  CaseWorkflowHistory,
  CasePartnerHistory,
  CaseParameterHistory,
  CaseTariffHistory,
  CaseTariffValueHistory,
  CaseAttachment,
  CaseVehicleHistory,
  CaseEventHistory,
  CaseCalculationHistory,
  CaseCalculationPart,
  Model,
  VinDecodeResponse,
  CaseRequest,
  CasePaymentHistory,
  CaseCalculationAlternativePart,
  CaseFilters,
  CaseList,
  CaseFiltersResult,
  ValuationVehicles,
  ValuationVehicleOptions,
  ValuationRequest,
  CaseValuationHistory,
  CaseServiceAppointmentHistory,
  VehicleDto,
  CaseClient,
  ClientDto,
  ValuationModel,
  ValuationBody,
  ClientOffer,
  CaseByVehicleIdSearchResult,
  ChangeCaseStatusRequest,
  UndocumentedModel,
  CarException,
  CarClassRentPrice,
  Offer,
  ValuationVehicle,
  BillingFilters,
  DgpciBillingFilters,
  PlateNumber,
  ClaimServiceRequest,
  ClaimServiceResponse,
  ClaimServiceCalculationRequest,
  ClaimServiceCalculationResponse,
  CalculationDifferencesRequest,
  CalculationDifferencesResponse,
  MaintenanceCupiOptionalPart,
  ClaimStatusResponse,
  ClaimStatusRequest,
  ClaimServiceAlternativeCalculationRequest,
  ClaimServiceAlternativeCalculationResponse,
  ModelData,
  ManipulationTariffPercentResponse,
  VinRequestResponse,
  CarfixAlternativeCalculationRequest,
  CarfixAlternativeCalculationResponse,
  CarfixAttachmentNotificationRequest,
  CarfixAttachmentNotificationResponse,
  CaseVehicleDetails,
  ValuationHistory,
  TariffPercentResponse,
  TariffPercentRequest,
  MaintenanceProducer,
  ValuationGeneration,
  valuationVehiclesInfoRequest,
  VQVehicle,
  Appointment,
  AppointmentFilters,
  AppointmentList,
  ValuationExcludeOptions,
  ValuationIncludeOptions,
  ValuationRequiredOptions,
  GTWSBillingFilters,
  AutomateAppointmentRequest,
  AcquisitionCaseRequest,
  CaseResultDto,
  ChangeCaseStatusBasedCodeRequest,
  NonStockBillingFilters,
  GenerateInvoiceRequest,
  GenerateInvoiceResponse,
  PartsHistoryInterogationRequest,
  UpdateOrderInfoRequest,
  AppointmentPaymentStatusRequest,
  AppointmentPaymentStatusResponse
} from '../interfaces/Case';
import { AddVehicleParams, Vehicle as VehicleExt } from '../interfaces/Vehicle';
import { ReferentialCode } from '../helpers/Constants';
import Referentials from '../helpers/Referentials.json';
import { IReferentialService } from './Interfaces/IReferentialService';
import { OrganizationType } from '../interfaces/Organization';
import { IAppUserService } from './Interfaces/IAppUserService';
import { CalculationRequest, CalculationResponse } from '../interfaces/GtService';
import { AppUser } from '../interfaces/AppUser';
import * as R from 'ramda';
import moment from 'moment';
import { IReferential } from '../interfaces/IReferential';
import { Utils } from '../helpers/Utils';
import { isUndefined } from 'util';

export class CaseService implements ICaseService {
  private organizationService: IOrganizationService;
  private tariffService: ITariffService;
  private appReferentialService: IReferentialService;
  private appUserService: IAppUserService;

  private baseController = '/case';

  constructor(
    tariffService: ITariffService,
    appReferentialService: IReferentialService,
    organizationService: IOrganizationService,
    appUserService: IAppUserService
  ) {
    this.tariffService = tariffService;
    this.appReferentialService = appReferentialService;
    this.organizationService = organizationService;
    this.appUserService = appUserService;
  }

  public async SearchNotice(
    vin: string,
    plateNumber: string,
    noticeNumber: string,
    caseNumber: string
  ): Promise<Notice[]> {
    return (
      await API.GetAsync<Notice[]>(
        `${this.baseController}/searchnotice?vin=${vin}&platenumber=${plateNumber}&noticeNumber=${noticeNumber}&caseNumber=${caseNumber}`
      )
    ).data;
  }

  public async SearchVehicle(
    vin: string,
    plateNumber: string,
    searchInVehicleStock: boolean
  ): Promise<VehicleDto[]> {
    return (
      await API.GetAsync<VehicleDto[]>(
        `${this.baseController}/searchvehicle?vin=${R.isNil(vin) ? '' : vin}&platenumber=${
          R.isNil(plateNumber) ? '' : plateNumber
        }&searchInVehicleStock=${searchInVehicleStock}`
      )
    ).data;
  }

  public async SearchClient(
    personTypeId: number,
    pin_cui: string,
    name: string
  ): Promise<ClientDto[]> {
    return (
      await API.GetAsync<ClientDto[]>(
        `${this.baseController}/searchclient?personTypeId=${personTypeId}&pin_cui=${
          R.isNil(pin_cui) ? '' : pin_cui
        }&name=${R.isNil(name) ? '' : name}`
      )
    ).data;
  }

  public async DecodeVin(vin: string): Promise<VinDecodeResponse> {
    return (await API.GetAsync<VinDecodeResponse>(`${this.baseController}/decodevin/${vin}`)).data;
  }

  public async SyncModels(): Promise<void> {
    await API.PostAsync<any>(`${this.baseController}/syncmodels`, {});
  }

  public async GetValuationVehicles(
    caseId: number,
    calculationFormId: number
  ): Promise<ValuationVehicles> {
    return (
      await API.Post2Async<any, ValuationVehicles>(
        `${this.baseController}/${caseId}/valuationVehicles/${calculationFormId}`,
        {}
      )
    ).data;
  }

  public async GetValuationHistory(caseId: number): Promise<ValuationHistory[]> {
    return (
      await API.GetAsync<ValuationHistory[]>(`${this.baseController}/valuation-history/${caseId}`)
    ).data;
  }

  public async GetValuationVehiclesInfo(
    vin: string,
    calculationFormId: number | null,
    valuationVehiclesInfoRequest: valuationVehiclesInfoRequest
  ): Promise<ValuationVehicles> {
    return (
      await API.Post2Async<any, ValuationVehicles>(
        `${this.baseController}/valuationVehicles/${vin}/${
          R.isNil(calculationFormId) ? '' : calculationFormId
        }`,
        {
          ...valuationVehiclesInfoRequest,
          registration_date: valuationVehiclesInfoRequest.registration_date
            ? moment(valuationVehiclesInfoRequest.registration_date).format('YYYY-MM-DD')
            : null
        }
      )
    ).data;
  }

  public async GetValuationVehiclesWithoutVin(
    make: string,
    model: string,
    body: string,
    firstRegistrationDate: Date,
    generation: number
  ): Promise<ValuationVehicles> {
    return (
      await API.Post2Async<any, ValuationVehicles>(
        `${this.baseController}/valuationVehicles/${make}/${model}/${body}/${moment(
          firstRegistrationDate
        ).format('YYYY-MM-DD')}${R.isNil(generation) ? '' : '/' + generation}`,
        {}
      )
    ).data;
  }

  public async ValuationSaveMapping(vin: string, vehicleId: number): Promise<any> {
    return (
      await API.Post2Async<any, any>(`${this.baseController}/valuation/saveMapping`, {
        Vin: vin,
        VehicleId: vehicleId
      })
    ).data;
  }

  public async GetValuationVehicleOptions(vehicleId: number): Promise<ValuationVehicleOptions> {
    return (
      await API.GetAsync<ValuationVehicleOptions>(
        `${this.baseController}/valuationOptions/${vehicleId}`
      )
    ).data;
  }

  public async GetValuationModels(
    make: string | null | undefined,
    firstRegistrationDate: Date | null
  ): Promise<ValuationModel[]> {
    return (
      await API.GetAsync<ValuationModel[]>(
        `${this.baseController}/valuationmodels/${make}/${moment(firstRegistrationDate).format(
          'YYYY-MM-DD'
        )}`
      )
    ).data;
  }

  public async GetValuationBodies(
    make: string | null | undefined,
    model: string | null | undefined,
    firstRegistrationDate: Date | null
  ): Promise<ValuationBody[]> {
    return (
      await API.GetAsync<ValuationBody[]>(
        `${this.baseController}/valuationbodies/${make}/${model}/${moment(
          firstRegistrationDate
        ).format('YYYY-MM-DD')}`
      )
    ).data;
  }

  public async GetValuationGenerations(
    make: string | null | undefined,
    model: string | null | undefined,
    body: string | null | undefined,
    doorsNo: number | null | undefined,
    firstRegistrationDate: Date | null
  ): Promise<ValuationGeneration[]> {
    return (
      await API.GetAsync<ValuationGeneration[]>(
        `${this.baseController}/valuationGenerations/${make}/${model}/${body}/${doorsNo}/${moment(
          firstRegistrationDate
        ).format('YYYY-MM-DD')}`
      )
    ).data;
  }

  public async GetExcludeOptions(
    optionsId: number,
    vehicleId: number
  ): Promise<ValuationExcludeOptions> {
    return (
      await API.GetAsync<ValuationExcludeOptions>(
        `${this.baseController}/valuationOptions/excludes/${optionsId}/${vehicleId}`
      )
    ).data;
  }

  public async GetIncludeOptions(
    optionsId: number,
    vehicleId: number
  ): Promise<ValuationIncludeOptions> {
    return (
      await API.GetAsync<ValuationIncludeOptions>(
        `${this.baseController}/valuationOptions/includes/${optionsId}/${vehicleId}`
      )
    ).data;
  }

  public async GetRequiredOptions(
    optionsId: number,
    vehicleId: number
  ): Promise<ValuationRequiredOptions> {
    return (
      await API.GetAsync<ValuationRequiredOptions>(
        `${this.baseController}/valuationOptions/requires/${optionsId}/${vehicleId}`
      )
    ).data;
  }

  public async PerformValuation(
    valuationRequest: ValuationRequest,
    caseId: number | null
  ): Promise<CaseValuationHistory> {
    let url = `${this.baseController}/performvaluation`;
    if (caseId !== null) {
      url += `/${caseId}`;
    }
    return (await API.Post2Async<ValuationRequest, CaseValuationHistory>(url, valuationRequest))
      .data;
  }

  public async CreateCalculationHistory(
    calculationRequest: CalculationRequest
  ): Promise<CaseCalculationHistory> {
    return (
      await API.Post2Async<CalculationRequest, CaseCalculationHistory>(
        `${this.baseController}/calculationHistory/`,
        calculationRequest
      )
    ).data;
  }

  public async CreateCalculation(calculationRequest: CalculationRequest): Promise<any> {
    return (
      await API.Post2Async<CalculationRequest, string>(
        `${this.baseController}/calculation/`,
        calculationRequest
      )
    ).data;
  }

  public async UpdateCalculationTariff(
    calculationRequest: CalculationRequest
  ): Promise<CalculationResponse> {
    return (
      await API.Post2Async<CalculationRequest, CalculationResponse>(
        `${this.baseController}/calculation/update-tariff`,
        calculationRequest
      )
    ).data;
  }
  // public async CopyCalculation(calculationRequest: CalculationRequest): Promise<any> {
  //     const user = await this.authService.getUserAsync();
  //     if (user === null) {
  //         throw Error('user is not logged in')
  //     }

  //     return (await API.Post2Async<CalculationRequest, string>(`${this.baseController}/copycalculation/`, calculationRequest)).data;
  // }

  public async UpdateCalculationCallback(
    id: number,
    workflowId: number,
    workflowOrganizationOwnerId: number
  ): Promise<any> {
    return (
      await API.PostAsync<any>(
        `${this.baseController}/calculationUpdateCallback/${id}/${workflowId}/${workflowOrganizationOwnerId}`,
        null
      )
    ).data;
  }

  public async UpdateCalculationPdfCallback(
    id: number,
    workflowId: number,
    workflowOrganizationOwnerId: number
  ): Promise<any> {
    return (
      await API.PostAsync<any>(
        `${this.baseController}/calculationUpdatePdfCallback/${id}/${workflowId}/${workflowOrganizationOwnerId}`,
        null
      )
    ).data;
  }

  // public async UpdateGtCalculation(calculationRequest: CalculationRequest): Promise<any> {
  //     const user = await this.authService.getUserAsync();
  //     if (user === null) {
  //         throw Error('user is not logged in')
  //     }

  //     return (await API.Post2Async<CalculationRequest, string>(`${this.baseController}/updateGtCalculation/`, calculationRequest)).data;
  // }

  public async AddVehicle(param: AddVehicleParams, caseId: number): Promise<VehicleExt> {
    return (
      await API.PostAsync<AddVehicleParams, any>(
        `${this.baseController}/${caseId}/addvehicle`,
        param
      )
    ).data;
  }

  public async GetCases(caseFilters: CaseFilters | null): Promise<CaseList> {
    if (caseFilters === null) {
      caseFilters = {
        assignedToList: [],
        createdByList: [],
        caseTypeIds: [],
        partnerIds: [],
        statusIds: [],
        workflowIds: [],
        organizationIds: [],
        page: 1,
        pageSize: null,
        isInternal: null,
        text: '',
        isNew: false,
        dateFilterCode: '',
        dateFilterStartDate: null,
        datefilterEndDate: null,
        vehicleIds: [],
        policyTypeIds: [],
        plateNumberSearchText: '',
        vinSearchText: '',
        caseNumberSearchText: '',
        countryCode: null,
        isAssignedToId: true,
        makeSearchText: '',
        modelSearchText: ''
      } as CaseFilters;
    }

    return (await API.Post2Async<CaseFilters, CaseList>(`${this.baseController}`, caseFilters))
      .data;
  }

  public async SearchByCaseNumber(id: number, caseNumber: string): Promise<Case[]> {
    return (
      await API.GetAsync<Case[]>(
        `${this.baseController}/SearchByCaseNumber?id=${id}&caseNumber=${caseNumber}`,
        {}
      )
    ).data;
  }

  public async GetCaseFilters(caseFilters: CaseFilters): Promise<CaseFiltersResult> {
    return (await API.Post2Async<CaseFilters, CaseFiltersResult>(`${this.baseController}/filters`, caseFilters))
      .data;
  }

  public async SearchByVehicle(vehicleId: number): Promise<Case[]> {
    return (await API.GetAsync<Case[]>(`${this.baseController}/SearchByVehicle/${vehicleId}`)).data;
  }

  public async GetLastCaseByVehicleId(vehicleId: number): Promise<CaseByVehicleIdSearchResult> {
    return (
      await API.GetAsync<CaseByVehicleIdSearchResult>(
        `${this.baseController}/SearchByVehicleId/${vehicleId}/lastCase`
      )
    ).data;
  }

  public async ChangeCaseStatus(request: ChangeCaseStatusRequest): Promise<CaseStatusHistory> {
    return (
      await API.Post2Async<ChangeCaseStatusRequest, CaseStatusHistory>(
        `${this.baseController}/status`,
        request
      )
    ).data;
  }

  public async GetCaseDetails(caseId: number): Promise<Case> {
    const refCaseType = Referentials.referential.find(
      (item) => item.code === ReferentialCode.CaseType
    );
    const refParam = Referentials.referential.find(
      (item) => item.code === ReferentialCode.CompanyParameter
    );
    const orgTypeRef = Referentials.referential.find(
      (item) => item.code === ReferentialCode.OrganizationType
    );
    const brandsRef = Referentials.referential.find(
      (item) => item.code === ReferentialCode.CarBrands
    );

    const [casee, caseTypeList, paramList, orgTypes, brands] = await Promise.all([
      this.GetCaseEntity(caseId),
      this.appReferentialService.Get(refCaseType!.baseUrl),
      this.appReferentialService.Get(refParam!.baseUrl),
      this.appReferentialService.Get(orgTypeRef!.baseUrl),
      this.appReferentialService.Get(brandsRef!.baseUrl)
    ]);

    const orgIds = casee.casePartners.map((item) => item.organizationId);
    orgIds.push(casee.organizationOwnerId);

    const [partners, tariff, tariffTypeList, createdByUser, assignedUser] = await Promise.all([
      this.organizationService.GetUserOrganizationsByIds(Array.from(new Set(orgIds))),
      R.isNil(casee.caseTariff)
        ? null
        : this.tariffService.GetUserTariff(casee.caseTariff.tariffId),
      this.tariffService.GetTariffTypes(null),
      this.appUserService.GetUserInfo(casee.createdBy),
      this.appUserService.GetUserInfo(casee.caseStatus.assignedToId)
    ]);

    casee.casePartners.forEach((wpItem) => {
      const partner = partners.find((item) => item.id === wpItem.organizationId);
      wpItem.organization = R.isNil(partner) ? null : partner;

      const orgType = orgTypes.find((item) => item.id === wpItem.organization!.organizationTypeId);
      wpItem.organization!.organizationType = (R.isNil(orgType)
        ? null
        : orgType) as any as OrganizationType;
    });

    casee.caseStatus.assignedTo = assignedUser;
    casee.createdByUser = createdByUser;
    const orgOwner = partners.find((item) => item.id === casee.organizationOwnerId);
    casee.organizationOwner = R.isNil(orgOwner) ? null : orgOwner;

    if (!R.isNil(tariff)) {
      casee.caseTariff.tariff = tariff;
      casee.caseTariff.caseTariffValuesHistory.forEach((tv) => {
        const tt = tariffTypeList.find((p) => tv.tariffTypeId === p.id);
        tv.tariffType = R.isNil(tt) ? null : tt;

        const brand = brands.find((p) => p.id === tv.brandId);
        tv.brand = R.isNil(brand) ? null : brand;
      });
    }
    const caseType = caseTypeList.find((item) => item.id === casee.caseTypeId);
    casee.caseType = R.isNil(caseType) ? null : caseType;
    casee.caseParameters.forEach((item) => {
      const param = paramList.find((p) => item.parameterId === p.id);
      item.parameter = R.isNil(param) ? null : param;
    });

    return casee;
  }

  public async GetCaseEntity(caseId: number): Promise<Case> {
    return (await API.GetAsync<Case>(`${this.baseController}/${caseId}`)).data;
  }

  public async CreateCase(
    workflowId: number,
    noticeId: number | null,
    vehicle: VehicleDto | null,
    clientId: number | null
  ): Promise<Case> {
    return (
      await API.Post2Async<
        {
          workflowId: number;
          noticeId: number | null;
          vehicle: VehicleDto | null;
          clientId: number | null;
        },
        Case
      >(`${this.baseController}/newcase`, {
        workflowId: workflowId,
        vehicle: vehicle,
        noticeId: noticeId,
        clientId: clientId
      })
    ).data;
  }

  public async CopyCase(
    caseId: number,
    workflowId: number,
    noticeId: number | null,
    vehicle: VehicleDto | null,
    clientId: number | null
  ): Promise<Case> {
    return (
      await API.Post2Async<
        {
          caseId: number;
          workflowId: number;
          noticeId: number | null;
          vehicle: VehicleDto | null;
          clientId: number | null;
        },
        Case
      >(`${this.baseController}/copyCase`, {
        caseId: caseId,
        workflowId: workflowId,
        vehicle: vehicle,
        noticeId: noticeId,
        clientId: clientId
      })
    ).data;
  }

  public async ChangeCaseWorkflow(
    workflowId: number,
    caseId: number,
    userId: string,
    caseStatusId: number
  ): Promise<void> {
    await API.PostAsync<{
      workflowId: number;
      caseId: number;
      userId: string;
      caseStatusId: number;
    }>(`${this.baseController}/changeworkflow`, {
      workflowId: workflowId,
      caseId: caseId,
      userId: userId,
      caseStatusId: caseStatusId
    });
  }

  public async UpdateCase(casee: Case): Promise<void> {
    await API.PutAsync<Case>(`${this.baseController}/Case`, casee);
  }

  public async RemoveCase(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/${id}`)).data;
  }

  public async GetCarModels(): Promise<Model[]> {
    return (await API.GetAsync<Model[]>(`${this.baseController}/model`)).data;
  }

  public async GetCarModelsByBrand(brandId: number): Promise<Model[]> {
    return (await API.GetAsync<Model[]>(`${this.baseController}/model/list/${brandId}`)).data;
  }

  public async GetCaseComments(caseId: number): Promise<CaseComment[]> {
    return (await API.GetAsync<CaseComment[]>(`${this.baseController}/CaseComment/list/${caseId}`))
      .data;
  }

  public async AddCaseComment(caseComment: CaseComment): Promise<CaseComment> {
    return (await API.PostAsync<CaseComment>(`${this.baseController}/Comment`, caseComment)).data;
  }

  public async UpdateCaseComment(caseComment: CaseComment): Promise<void> {
    await API.PutAsync<CaseComment>(`${this.baseController}/Comment`, caseComment);
  }

  public async RemoveCaseComment(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/CaseComment/${id}`)).data;
  }

  public async AddCaseRequest(caseRequest: CaseRequest): Promise<CaseRequest> {
    const formData = new FormData();
    formData.append('id', caseRequest.id.toString());
    formData.append('caseId', caseRequest.caseId.toString());
    formData.append('caseSectionStepFormId', caseRequest.caseSectionStepFormId.toString());
    if (!R.isNil(caseRequest.organizationId)) {
      formData.append('organizationId', caseRequest.organizationId.toString());
    }
    formData.append('subject', caseRequest.subject);
    formData.append('text', caseRequest.text);
    formData.append('to', caseRequest.to);

    if (!R.isNil(caseRequest.userIdTo)) {
      formData.append('userIdTo', caseRequest.userIdTo);
    }
    if (caseRequest.attachments.length !== 0) {
      formData.append('attachmentTypeId', caseRequest.attachments[0].attachmentTypeId.toString());
      formData.append('attachmentTypeCode', caseRequest.attachments[0].attachmentType!.code!);
    }
    caseRequest.attachments.forEach((attach) => {
      formData.append('files', attach.file);
    });

    if (!R.isNil(caseRequest.cc)) {
      formData.append('cc', caseRequest.cc);
    }
    if (!R.isNil(caseRequest.hasOffer)) {
      formData.append('hasOffer', caseRequest?.hasOffer!.toString());
    }
    if (!R.isNil(caseRequest.hasInvoice)) {
      formData.append('hasInvoice', caseRequest?.hasInvoice!.toString());
    }
    if (!R.isNil(caseRequest.calculationId)) {
      formData.append('calculationId', caseRequest?.calculationId!.toString());
    }
    if (!R.isNil(caseRequest.caseClientHistoryId)) {
      formData.append('caseClientHistoryId', caseRequest?.caseClientHistoryId!.toString());
    }
    if (!R.isNil(caseRequest.vehicleId)) {
      formData.append('vehicleId', caseRequest?.vehicleId!.toString());
    }
    if (!R.isNil(caseRequest.gatewayServiceUrl)) {
      formData.append('gatewayServiceUrl', caseRequest?.gatewayServiceUrl!.toString());
    }
    if (!R.isNil(caseRequest.offerId)) {
      formData.append('offerId', caseRequest?.offerId!.toString());
    }
    if (!R.isNil(caseRequest.userOrganizationId)) {
      formData.append('userOrganizationId', caseRequest?.userOrganizationId!.toString());
    }
    if (!R.isNil(caseRequest.organizationOwnerId)) {
      formData.append('organizationOwnerId', caseRequest?.organizationOwnerId!.toString());
    }
    if (!R.isNil(caseRequest.hideOrganizationLogo)) {
      formData.append('hideOrganizationLogo', caseRequest?.hideOrganizationLogo!.toString());
    }
    //if (caseRequest.additionalFilesParameters) {
    //  Utils.AddObjectToFormData(formData, caseRequest.additionalFilesParameters, "additionalFilesParameters");
    //}
    return (
      await API.Post2Async<FormData, CaseRequest>(`${this.baseController}/CaseRequest`, formData)
    ).data;
  }

  public async GetCaseRequests(caseId: number, formId: number | null): Promise<CaseRequest[]> {
    return (
      await API.GetAsync<CaseRequest[]>(
        `${this.baseController}/${caseId}/requests/${formId === null ? '' : formId}`
      )
    ).data;
  }

  public async GetCurrentCaseServiceAppointment(
    caseId: number,
    formId: number | null
  ): Promise<CaseServiceAppointmentHistory> {
    return (
      await API.GetAsync<CaseServiceAppointmentHistory>(
        `${this.baseController}/${caseId}/serviceappointment/${formId === null ? '' : formId}`
      )
    ).data;
  }

  public async AddCaseServiceAppointment(
    appointment: CaseServiceAppointmentHistory
  ): Promise<CaseServiceAppointmentHistory> {
    const formData = new FormData();
    formData.append('id', appointment.id.toString());
    formData.append('caseId', appointment.caseId.toString());
    formData.append('caseSectionStepFormId', appointment.caseSectionStepFormId.toString());

    formData.append('comment', appointment.comment);
    formData.append('serviceInDate', appointment.serviceInDate.toISOString());
    formData.append('serviceOutDate', appointment.serviceOutDate.toISOString());

    if (!R.isNil(appointment.userId)) {
      formData.append('userId', appointment.userId);
    }
    if (appointment.attachments.length !== 0) {
      formData.append('attachmentTypeId', appointment.attachments[0].attachmentTypeId.toString());
      formData.append('attachmentTypeCode', appointment.attachments[0].attachmentType!.code!);
    }
    appointment.attachments.forEach((attach) => {
      formData.append('files', attach.file);
    });

    return (
      await API.Post2Async<FormData, CaseServiceAppointmentHistory>(
        `${this.baseController}/serviceappointment`,
        formData
      )
    ).data;
  }

  public async GetCurrentCasePayment(caseId: number, formId: number): Promise<CasePaymentHistory> {
    return (
      await API.GetAsync<CasePaymentHistory>(
        `${this.baseController}/${caseId}/payment/${formId === null ? '' : formId}`
      )
    ).data;
  }
  public async GetCasePayments(caseId: number, formId: number): Promise<CasePaymentHistory[]> {
    return (
      await API.GetAsync<CasePaymentHistory[]>(
        `${this.baseController}/${caseId}/payments/${formId === null ? '' : formId}`
      )
    ).data;
  }
  public async AddCasePayment(casePayment: CasePaymentHistory): Promise<CasePaymentHistory> {
    const formData = new FormData();
    formData.append('id', casePayment.id.toString());
    formData.append('caseId', casePayment.caseId.toString());

    if (!R.isNil(casePayment.currency)) {
      formData.append('currency', casePayment.currency.toString());
    }
    if (!R.isNil(casePayment.currencyRate)) {
      formData.append('currencyRate', casePayment.currencyRate.toString());
    }

    formData.append('caseSectionStepFormId', casePayment.caseSectionStepFormId.toString());
    if (!R.isNil(casePayment.organizationId)) {
      formData.append('organizationId', casePayment.organizationId.toString());
    }
    formData.append('comment', casePayment.comment);
    if (!R.isNil(casePayment.paymentTypeId)) {
      formData.append('paymentTypeId', casePayment.paymentTypeId.toString());
    }
    if (!R.isNil(casePayment.paymentRequestNoId)) {
      formData.append('paymentRequestNoId', casePayment.paymentRequestNoId.toString());
    }
    if (!R.isNil(casePayment.hasFransiza)) {
      formData.append('hasFransiza', casePayment.hasFransiza.toString());
    }
    if (!R.isNil(casePayment.paymentAmount)) {
      formData.append('paymentAmount', casePayment.paymentAmount.toString());
    }
    if (!R.isNil(casePayment.paymentAmountWithoutVAT)) {
      formData.append('paymentAmountWithoutVAT', casePayment.paymentAmountWithoutVAT.toString());
    }
    if (!R.isNil(casePayment.bankAccount)) {
      formData.append('bankAccount', casePayment.bankAccount.toString());
    }
    if (!R.isNil(casePayment.installments)) {
      formData.append('installments', casePayment.installments.toString());
    }

    if (!R.isNil(casePayment.userToId)) {
      formData.append('userIdTo', casePayment.userToId);
    }
    if (casePayment.attachments.length !== 0) {
      formData.append('attachmentTypeId', casePayment.attachments[0].attachmentTypeId.toString());
      formData.append('attachmentTypeCode', casePayment.attachments[0].attachmentType!.code!);
    }
    casePayment.attachments.forEach((attach) => {
      formData.append('files', attach.file);
    });

    if (!R.isNil(casePayment.secondCurrency)) {
      formData.append('secondCurrency', casePayment.secondCurrency.toString());
    }
    if (!R.isNil(casePayment.secondPaymentAmount)) {
      formData.append('secondPaymentAmount', casePayment.secondPaymentAmount.toString());
    }

    if (!R.isNil(casePayment.secondPaymentAmountWithoutVAT)) {
      formData.append(
        'secondPaymentAmountWithoutVAT',
        casePayment.secondPaymentAmountWithoutVAT.toString()
      );
    }

    return (
      await API.Post2Async<FormData, CasePaymentHistory>(
        `${this.baseController}/casepayment`,
        formData
      )
    ).data;
  }

  public async GetAllCaseStatusHistory(): Promise<CaseStatusHistory[]> {
    return (await API.GetAsync<CaseStatusHistory[]>(`${this.baseController}/StatusHistory`)).data;
  }

  public async GetCaseStatusHistory(caseId: number): Promise<CaseStatusHistory[]> {
    return (
      await API.GetAsync<CaseStatusHistory[]>(`${this.baseController}/${caseId}/StatusHistory`)
    ).data;
  }

  public async AddCaseStatusHistory(
    caseStatusHistory: CaseStatusHistory
  ): Promise<CaseStatusHistory> {
    return (
      await API.PostAsync<CaseStatusHistory>(
        `${this.baseController}/CaseStatusHistory`,
        caseStatusHistory
      )
    ).data;
  }

  public async GetCaseWorkflowHistory(caseId: number): Promise<CaseWorkflowHistory[]> {
    return (
      await API.GetAsync<CaseWorkflowHistory[]>(`${this.baseController}/${caseId}/workflowHistory`)
    ).data;
  }

  public async GetCasePartnerHistory(caseId: number): Promise<CasePartnerHistory[]> {
    return (
      await API.GetAsync<CasePartnerHistory[]>(`${this.baseController}/${caseId}/partnerHistory`)
    ).data;
  }

  public async AddCasePartnerHistory(
    casePartnerHistory: CasePartnerHistory
  ): Promise<CasePartnerHistory> {
    return (
      await API.PostAsync<CasePartnerHistory>(
        `${this.baseController}/CasePartnerHistory`,
        casePartnerHistory
      )
    ).data;
  }

  public async GetCaseParameterHistory(caseId: number): Promise<CaseParameterHistory[]> {
    return (
      await API.GetAsync<CaseParameterHistory[]>(
        `${this.baseController}/${caseId}/parameterHistory`
      )
    ).data;
  }

  public async SaveParameterHistory(caseId: number, values: CaseParameterHistory[]): Promise<void> {
    await API.PutAsync<CaseParameterHistory[]>(
      `${this.baseController}/${caseId}/parameterHistory`,
      values
    );
  }

  public async GetCaseTariffHistory(caseId: number): Promise<CaseTariffHistory[]> {
    return (
      await API.GetAsync<CaseTariffHistory[]>(`${this.baseController}/${caseId}/tariffHistory`)
    ).data;
  }

  public async SaveCaseTariffHistory(
    caseId: number,
    tariffValues: CaseTariffValueHistory[]
  ): Promise<void> {
    await API.PutAsync<CaseTariffValueHistory[]>(
      `${this.baseController}/${caseId}/tariffHistory`,
      tariffValues
    );
  }

  public async GetCaseTariffValueHistory(
    caseTariffHistoryId: number
  ): Promise<CaseTariffValueHistory[]> {
    // return (await API.GetAsync<CaseTariffValueHistory[]>(`${this.baseController}/${caseTariffHistoryId}/CaseTariffValueHistory`)).data;
    const caseTariffValueHistory = [] as CaseTariffValueHistory[];

    const tariffGroupTypeRef = Referentials.referential.find(
      (item) => item.code === ReferentialCode.TariffTypeGroup
    );
    const [tariffValues, tariffGroupTypeList] = await Promise.all([
      this.tariffService.GetTariffValues(20, null),
      this.appReferentialService.Get(tariffGroupTypeRef!.baseUrl)
    ]);

    const mostRecentCaseTariffValues = [] as TariffValue[];
    for (const tariffGroupType of tariffGroupTypeList) {
      const tariffValuesForTariffGroupType = tariffValues.filter(
        (item) => item.tariffType.tariffTypeGroupId === tariffGroupType.id
      );
      const mostRecentTariffValuesDate = tariffValuesForTariffGroupType.sort(function (
        a: any,
        b: any
      ) {
        return b.date - a.date;
      })[0].date;
      mostRecentCaseTariffValues.push(
        ...tariffValuesForTariffGroupType.filter(
          (item: TariffValue) => item.date === mostRecentTariffValuesDate
        )
      );
    }

    mostRecentCaseTariffValues.forEach((item, index) => {
      caseTariffValueHistory.push({
        id: index,
        caseTariffHistoryId: 1,
        tariffTypeId: item.tariffTypeId,
        tariffType: item.tariffType,
        value: item.value,
        brandId: item.brandId,
        brand: item.brand
      } as CaseTariffValueHistory);
    });
    return caseTariffValueHistory;
  }

  public async AddCaseTariffValueHistory(
    caseTariffValueHistory: CaseTariffValueHistory
  ): Promise<CaseTariffValueHistory> {
    return (
      await API.PostAsync<CaseTariffValueHistory>(
        `${this.baseController}/CaseTariffValueHistory`,
        caseTariffValueHistory
      )
    ).data;
  }

  public async GetCaseAttachments(caseId: number, types: string[]): Promise<CaseAttachment[]> {
    const idLIst = types.join(',');
    return (
      await API.GetAsync<CaseAttachment[]>(
        `${this.baseController}/${caseId}/caseAttachment/${idLIst}`
      )
    ).data;
  }

  public async GetCaseAttachment(fileName: string): Promise<Blob> {
    return (await API.GetFileAsync<Blob>(`${this.baseController}/caseAttachment/${fileName}`)).data;
  }

  public async GetCaseAttachmentsArchive(
    caseId: number,
    formId: number | null,
    types: string[]
  ): Promise<Blob> {
    const idLIst = types.join(',');
    return (
      await API.GetFileAsync<Blob>(
        `${this.baseController}/${caseId}/caseAttachments/${idLIst}/${
          formId === null ? '' : formId
        }`
      )
    ).data;
  }

  public async AddCaseAttachment(caseAttachments: CaseAttachment[]): Promise<CaseAttachment[]> {
    const formData = new FormData();

    formData.append('id', caseAttachments[0].id.toString());
    formData.append('caseId', caseAttachments[0].caseId.toString());
    formData.append('attachmentTypeId', caseAttachments[0].attachmentTypeId.toString());
    formData.append('attachmentTypeCode', caseAttachments[0].attachmentType!.code!);
    formData.append('isSelection', caseAttachments[0].isSelection.toString());
    if (caseAttachments[0].appointmentId) {
      formData.append('appointmentId', caseAttachments[0].appointmentId.toString());
    }
    if (caseAttachments[0].includeInOtherTab) {
      formData.append('includeInOtherTab', caseAttachments[0].includeInOtherTab.toString());
    }
    caseAttachments.forEach((item) => {
      formData.append('files', item.file);
    });
    return (
      await API.Post2Async<FormData, CaseAttachment[]>(
        `${this.baseController}/CaseAttachment`,
        formData
      )
    ).data;
  }

  public async RemoveCaseAttachment(fileName: string): Promise<void> {
    await API.DeleteAsync<void>(`${this.baseController}/CaseAttachment/${fileName}`);
  }

  public async AddCaseVehicle(vehicle: CaseVehicleHistory): Promise<CaseVehicleHistory> {
    return (await API.PostAsync<CaseVehicleHistory>(`${this.baseController}/CaseVehicle`, vehicle))
      .data;
  }
  public async UpdateCaseVehicle(vehicle: CaseVehicleHistory): Promise<void> {
    await API.PutAsync<CaseVehicleHistory>(`${this.baseController}/CaseVehicle`, vehicle);
  }
  public async AddCaseEvent(event: CaseEventHistory): Promise<CaseEventHistory> {
    return (await API.PostAsync<CaseEventHistory>(`${this.baseController}/CaseEvent`, event)).data;
  }
  public async UpdateCaseEvent(event: CaseEventHistory): Promise<void> {
    await API.PutAsync<CaseEventHistory>(`${this.baseController}/CaseEvent`, event);
  }

  public async GetCurrentEvent(caseId: number): Promise<CaseEventHistory> {
    return (await API.GetAsync<CaseEventHistory>(`${this.baseController}/CaseEvent/${caseId}`))
      .data;
  }

  public async AddCaseClient(client: CaseClient): Promise<CaseClient> {
    const formData = new FormData();
    formData.append('id', client.id.toString());
    formData.append('clientId', client.clientId.toString());
    formData.append('caseId', client.caseId.toString());
    formData.append('caseSectionStepFormId', client.caseSectionStepFormId.toString());

    formData.append('personTypeId', client.personTypeId!.toString());
    formData.append('companyNumber', client.companyNumber);
    formData.append('pin', R.isNil(client.pin) ? '' : client.pin);
    formData.append('lastName', R.isNil(client.lastName) ? '' : client.lastName);
    formData.append('firstName', R.isNil(client.firstName) ? '' : client.firstName);
    formData.append('contactPerson', client.contactPerson);
    formData.append('companyName', R.isNil(client.companyName) ? '' : client.companyName);
    formData.append('phone', client.phone);
    formData.append('email', client.email);
    formData.append('address', R.isNil(client.address) ? '' : client.address);
    formData.append('city', R.isNil(client.city) ? '' : client.city);
    formData.append('region', R.isNil(client.region) ? '' : client.region);
    formData.append('district', R.isNil(client.district) ? '' : client.district);
    formData.append('gdprAgreement', client.gdprAgreement ? 'true' : 'false');
    formData.append('bankAccount', client.bankAccount ? client.bankAccount : '');

    if (client.attachments.length !== 0) {
      formData.append('attachmentTypeId', client.attachments[0].attachmentTypeId.toString());
      formData.append('attachmentTypeCode', client.attachments[0].attachmentType!.code!);
    }
    client.attachments.forEach((attach) => {
      formData.append('files', attach.file);
    });

    return (
      await API.Post2Async<FormData, CaseClient>(`${this.baseController}/CaseClient`, formData)
    ).data;
  }
  public async UpdateCaseClient(client: CaseClient): Promise<void> {
    const formData = new FormData();
    formData.append('id', client.id.toString());
    formData.append('clientId', client.clientId.toString());
    formData.append('caseId', client.caseId.toString());
    formData.append('caseSectionStepFormId', client.caseSectionStepFormId.toString());

    formData.append('personTypeId', client.personTypeId!.toString());
    formData.append('companyNumber', client.companyNumber);
    formData.append('pin', R.isNil(client.pin) ? '' : client.pin);
    formData.append('lastName', R.isNil(client.lastName) ? '' : client.lastName);
    formData.append('firstName', R.isNil(client.firstName) ? '' : client.firstName);
    formData.append('contactPerson', client.contactPerson);
    formData.append('companyName', R.isNil(client.companyName) ? '' : client.companyName);
    formData.append('phone', client.phone);
    formData.append('email', client.email);
    formData.append('address', R.isNil(client.address) ? '' : client.address);
    formData.append('city', R.isNil(client.city) ? '' : client.city);
    formData.append('region', R.isNil(client.region) ? '' : client.region);
    formData.append('district', R.isNil(client.district) ? '' : client.district);
    formData.append('gdprAgreement', client.gdprAgreement ? 'true' : 'false');
    formData.append('bankAccount', client.bankAccount ? client.bankAccount : '');

    if (client.attachments.length !== 0) {
      formData.append('attachmentTypeId', client.attachments[0].attachmentTypeId.toString());
      formData.append('attachmentTypeCode', client.attachments[0].attachmentType!.code!);
    }
    client.attachments.forEach((attach) => {
      formData.append('files', attach.file);
    });

    await API.PutAsync<FormData>(`${this.baseController}/CaseClient`, formData);
  }

  public async GetCaseCalculationHistories(caseId: number): Promise<CaseCalculationHistory[]> {
    return (
      await API.GetAsync<CaseCalculationHistory[]>(
        `${this.baseController}/${caseId}/casecalculations`
      )
    ).data;
  }

  public async GetAdminCaseCalculationHistories(caseId: number): Promise<CaseCalculationHistory[]> {
    return (
      await API.GetAsync<CaseCalculationHistory[]>(
        `${this.baseController}/${caseId}/adminCasecalculations`
      )
    ).data;
  }

  public async GetCaseCalculationHistoryDetails(
    calculationHistoryId: number
  ): Promise<CaseCalculationHistory> {
    return (
      await API.GetAsync<CaseCalculationHistory>(
        `${this.baseController}/casecalculation/${calculationHistoryId}`
      )
    ).data;
  }

  public async GetCaseCalculationReport(caseId: number, calculationId: number): Promise<Blob> {
    return (
      await API.GetFileAsync<Blob>(
        `${this.baseController}/${caseId}/calculationreport/${calculationId}`
      )
    ).data;
  }

  public async GetCaseCalculationXML(caseId: number, calculationId: number): Promise<Blob> {
    return (
      await API.GetFileAsync<Blob>(
        `${this.baseController}/${caseId}/calculationXML/${calculationId}`
      )
    ).data;
  }

  public async GetCalculationSections(): Promise<IReferential[]> {
    return (
      await API.GetAsync<IReferential[]>(`${this.baseController}/referential/calculationSection`)
    ).data;
  }

  public async GetCaseCalculationParts(
    calculationHistoryId: number
  ): Promise<CaseCalculationPart[]> {
    return (
      await API.GetAsync<CaseCalculationPart[]>(
        `${this.baseController}/CaseCalculationPart/list/${calculationHistoryId}`
      )
    ).data;
  }

  public async AddCaseCalculationPart(
    caseCalculationPart: CaseCalculationPart
  ): Promise<CaseCalculationPart> {
    return (
      await API.PostAsync<CaseCalculationPart>(
        `${this.baseController}/CaseCalculationPart`,
        caseCalculationPart
      )
    ).data;
  }

  public async UpdateCaseCalculationPart(caseCalculationPart: CaseCalculationPart): Promise<void> {
    await API.PutAsync<CaseCalculationPart>(
      `${this.baseController}/CaseCalculationPart`,
      caseCalculationPart
    );
  }

  public async UpdateCaseCalculationParts(
    caseCalculationParts: CaseCalculationPart[],
    comment: string,
    offerNumber: string,
    calculationId: number,
    partsLocationOrganizationId: number | null,
    partsOrganizationId: number | null,
    organizationId: number | null,
    organizationName: string | null,
    isUpdate: boolean,
    manipulationPercent: number,
    additionInsurer: number | null,
    formId: number
  ): Promise<void> {
    await API.PutAsync<{
      parts: CaseCalculationPart[];
      comment: string;
      offerNumber: string;
      partsLocationOrganizationId: number | null;
      partsOrganizationId: number | null;
      organizationId: number | null;
      organizationName: string | null;
      isUpdate: boolean;
      manipulationPercent: number;
      additionInsurer: number | null;
      formId: number;
    }>(`${this.baseController}/calculation/${calculationId}/calculationParts`, {
      parts: caseCalculationParts,
      comment: comment,
      offerNumber: offerNumber,
      partsLocationOrganizationId: partsLocationOrganizationId,
      partsOrganizationId: partsOrganizationId,
      organizationId: organizationId,
      organizationName: organizationName,
      isUpdate: isUpdate,
      manipulationPercent: manipulationPercent,
      additionInsurer: additionInsurer,
      formId: formId
    });
  }

  public async RemoveCaseCalculationPart(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/CaseCalculationPart/${id}`)).data;
  }

  public async SubmitForm(
    caseId: number,
    formId: number,
    assignedTo: AppUser | null
  ): Promise<CaseStatusHistory> {
    let assigned = {};

    if (assignedTo !== null) {
      assigned = {
        userId: assignedTo.id,
        organizationId: assignedTo.organizationId,
        hoId: assignedTo.hoId === null ? assignedTo.id : assignedTo.hoId
      };
    } else {
      assigned = {
        userId: '',
        organizationId: null,
        hoId: null
      };
    }

    return (
      await API.Post2Async<any, CaseStatusHistory>(
        `${this.baseController}/${caseId}/submitForm/${formId}`,
        assigned
      )
    ).data;
  }

  public async InterogateCaseCalculationsAlternativeParts(
    originalPartId: number,
    partNUmber: string,
    addition: number,
    discount: number,
    workflowId: number,
    organizationId?: number | null
  ): Promise<CaseCalculationAlternativePart[]> {
    const organizationIdParam = organizationId ? `/${organizationId}` : '';
    const url =
      `${this.baseController}/alternativeParts/${encodeURIComponent(
        partNUmber
      )}/${originalPartId}/${addition}/${discount}/${workflowId}` + organizationIdParam;
    return (await API.GetAsync<CaseCalculationAlternativePart[]>(url)).data;
  }

  public async InterogateCaseCalculationsAlternativePartsBySupplier(
    originalPartId: number,
    partNumber: string,
    addition: number,
    discount: number,
    workflowId: number,
    supplierId: number,
    organizationId?: number | null
  ): Promise<CaseCalculationAlternativePart[]> {
    const organizationIdParam = organizationId ? `/${organizationId}` : '';
    const url =
      `${this.baseController}/supplierAlternativeParts/${encodeURIComponent(
        partNumber
      )}/${originalPartId}/${addition}/${discount}/${workflowId}/${supplierId}` + organizationIdParam;
    return (await API.GetAsync<CaseCalculationAlternativePart[]>(url)).data;
  }

  public async SetCaseNotNew(
    caseId: number,
    caseCalculationHistId: number,
    moveToTasks: boolean,
    moveToViewed: boolean,
    notNew: boolean
  ): Promise<void> {
    await API.PutAsync<any>(
      `${this.baseController}/${caseId}/IsNotNew/${caseCalculationHistId}?moveToTasks=${moveToTasks}&moveToViewed=${moveToViewed}&notNew=${notNew}`,
      null
    );
  }

  public async GetTaskList(): Promise<Case[]> {
    return (await API.GetAsync<Case[]>(`${this.baseController}/usertasks`)).data;
  }

  public async RemoveUserTask(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/usertask/${id}`)).data;
  }

  public async GetUserComments(): Promise<CaseComment[]> {
    return (await API.GetAsync<CaseComment[]>(`${this.baseController}/usercomments`)).data;
  }

  public async RemoveUserComment(id: number, addtask: boolean): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/usercomment/${id}/${addtask}`))
      .data;
  }
  public async GetClientOffers(caseId: number, vehicleId: number): Promise<ClientOffer[]> {
    return (
      await API.GetAsync<ClientOffer[]>(
        `${this.baseController}/ClientOffers/${caseId}/${vehicleId}`
      )
    ).data;
  }

  public async BuildFilledInEmailTemplate(
    id: number,
    formId: number,
    assignedTo: AppUser | null
  ): Promise<string[]> {
    return (
      await API.Post2Async<any, string[]>(
        `${this.baseController}/${id}/fillInEmailTemplate/${formId}`,
        {
          assignedTo
        }
      )
    ).data;
  }

  public async SendConfirmedOrderNotification(caseId: number, orderId: number): Promise<any> {
    return (
      await API.PostAsync<any>(
        `${this.baseController}/${caseId}/${orderId}/sendOrderNotification`,
        null
      )
    ).data;
  }

  public async SyncUndocumentedModels(): Promise<void> {
    await API.PostAsync<any>(`${this.baseController}/syncUndocumentedModels`, {});
  }

  public async GetCarUndocumentedModelsByBrand(brandId: number): Promise<UndocumentedModel[]> {
    return (
      await API.GetAsync<UndocumentedModel[]>(
        `${this.baseController}/undocumentedModel/list/${brandId}`
      )
    ).data;
  }

  public async GetCarExceptions(): Promise<CarException[]> {
    return (await API.GetAsync<CarException[]>(`${this.baseController}/Referential/CarException`))
      .data;
  }

  public async AddCarException(carException: CarException): Promise<CarException> {
    return (
      await API.PostAsync<CarException>(
        `${this.baseController}/Referential/CarException`,
        carException
      )
    ).data;
  }

  public async UpdateCarException(carException: CarException): Promise<void> {
    await API.PutAsync<CarException>(
      `${this.baseController}/Referential/CarException`,
      carException
    );
  }

  public async RemoveCarException(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/Referential/CarException/${id}`))
      .data;
  }

  public async CarExceptionSearch(vin: string, plateNumber: string): Promise<CarException[]> {
    return (
      await API.GetAsync<CarException[]>(
        `${this.baseController}/CarExceptions/search?vin=${R.isNil(vin) ? '' : vin}&platenumber=${
          R.isNil(plateNumber) ? '' : plateNumber
        }`
      )
    ).data;
  }

  public async GetCarClassRentPrices(): Promise<CarClassRentPrice[]> {
    return (
      await API.GetAsync<CarClassRentPrice[]>(`${this.baseController}/CarClassRentPrice/list`)
    ).data;
  }

  public async AddCarClassRentPrice(
    carClassRentPrice: CarClassRentPrice
  ): Promise<CarClassRentPrice> {
    return (
      await API.PostAsync<CarClassRentPrice>(
        `${this.baseController}/CarClassRentPrice`,
        carClassRentPrice
      )
    ).data;
  }

  public async RemoveCarClassRentPrice(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/CarClassRentPrice/${id}`)).data;
  }

  public async UpdateCarClassRentPrice(carClassRentPrice: CarClassRentPrice): Promise<void> {
    await API.PutAsync<CarClassRentPrice>(
      `${this.baseController}/CarClassRentPrice`,
      carClassRentPrice
    );
  }

  public async GetCarClassRentPriceByClassId(classId: number): Promise<CarClassRentPrice[]> {
    return (
      await API.GetAsync<CarClassRentPrice[]>(
        `${this.baseController}/CarClassRentPrice/list/${classId}`
      )
    ).data;
  }

  public async GetBestOffer(offer: Offer): Promise<Offer> {
    return (await API.PostAsync<Offer>(`${this.baseController}/getBestOffer`, offer)).data;
  }

  public async GetCaseBillingReport(billingFilters: BillingFilters): Promise<Blob> {
    return (
      await API.PostFileAsync<BillingFilters, Blob>(
        `${this.baseController}/getCaseReportExcel`,
        billingFilters
      )
    ).data;
  }

  public async GetCaseBillingReportDgpciRequest(
    dgpciBillingFilters: DgpciBillingFilters
  ): Promise<Blob> {
    return (
      await API.PostFileAsync<DgpciBillingFilters, Blob>(
        `${this.baseController}/DGPCI/getVinRequestReportExcel`,
        dgpciBillingFilters
      )
    ).data;
  }

  public async GetVinByPlateNumberRequest(payload: PlateNumber): Promise<VinRequestResponse> {
    return (
      await API.Post2Async<PlateNumber, VinRequestResponse>(
        `${this.baseController}/DGPCI/getVin`,
        payload
      )
    ).data;
  }

  public async GetValuationVehicle(vehicleId: number): Promise<ValuationVehicle> {
    return (
      await API.GetAsync<ValuationVehicle>(`${this.baseController}/valuationVehicle/${vehicleId}`)
    ).data;
  }

  public async UpdateCalculationPartsAndCasePartToBeOrdered(
    caseCalculationParts: CaseCalculationPart[],
    comment: string,
    offerNumber: string,
    calculationId: number,
    partsLocationOrganizationId: number | null,
    partsOrganizationId: number | null,
    organizationId: number | null,
    organizationName: string | null
  ): Promise<void> {
    await API.PutAsync<{
      parts: CaseCalculationPart[];
      comment: string;
      offerNumber: string;
      partsLocationOrganizationId: number | null;
      partsOrganizationId: number | null;
      organizationId: number | null;
      organizationName: string | null;
    }>(`${this.baseController}/calculation/${calculationId}/calculationParts-partToBeOrdered`, {
      parts: caseCalculationParts,
      comment: comment,
      offerNumber: offerNumber,
      partsLocationOrganizationId: partsLocationOrganizationId,
      partsOrganizationId: partsOrganizationId,
      organizationId: organizationId,
      organizationName: organizationName
    });
  }

  public async GetLatestCaseVehicleHistory(vin: string): Promise<CaseVehicleHistory> {
    return (await API.GetAsync<CaseVehicleHistory>(`${this.baseController}/latest-vehicle/${vin}`))
      .data;
  }

  public async PrcClaimService(request: ClaimServiceRequest): Promise<ClaimServiceResponse> {
    return (await API.PostAsync<any>(`${this.baseController}/prc/claimService`, request)).data;
  }

  public async CalculationDifferences(
    request: CalculationDifferencesRequest
  ): Promise<CalculationDifferencesResponse> {
    return (await API.PostAsync<any>(`${this.baseController}/prc/calculationDifferences`, request))
      .data;
  }

  public async PrcClaimServiceCalculation(
    request: ClaimServiceCalculationRequest
  ): Promise<ClaimServiceCalculationResponse> {
    return (await API.PostAsync<any>(`${this.baseController}/prc/claimServiceCalculation`, request))
      .data;
  }

  public async PrcClaimStatus(request: ClaimStatusRequest): Promise<ClaimStatusResponse> {
    return (await API.PostAsync<any>(`${this.baseController}/prc/claimStatus`, request)).data;
  }

  public async PrcClaimServiceAlternativeCalculation(
    request: ClaimServiceAlternativeCalculationRequest
  ): Promise<ClaimServiceAlternativeCalculationResponse> {
    return (
      await API.PostAsync<any>(
        `${this.baseController}/prc/claimServiceAlternativeCalculation`,
        request
      )
    ).data;
  }

  public async MaintenanceCupiOptionalPartList(): Promise<MaintenanceCupiOptionalPart[]> {
    return (
      await API.GetAsync<MaintenanceCupiOptionalPart[]>(
        `${this.baseController}/MaintenanceCupiOptionalPart`
      )
    ).data;
  }

  public async AddMaintenanceCupiOptionalPart(
    maintenanceCupiOptionalPart: MaintenanceCupiOptionalPart
  ): Promise<MaintenanceCupiOptionalPart> {
    return (
      await API.PostAsync<MaintenanceCupiOptionalPart>(
        `${this.baseController}/MaintenanceCupiOptionalPart`,
        maintenanceCupiOptionalPart
      )
    ).data;
  }

  public async RemoveMaintenanceCupiOptionalPart(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/MaintenanceCupiOptionalPart/${id}`))
      .data;
  }

  public async UpdateMaintenanceCupiOptionalPart(
    maintenanceCupiOptionalPart: MaintenanceCupiOptionalPart
  ): Promise<void> {
    await API.PutAsync<MaintenanceCupiOptionalPart>(
      `${this.baseController}/MaintenanceCupiOptionalPart`,
      maintenanceCupiOptionalPart
    );
  }

  public async GetModelList(): Promise<Model[]> {
    return (await API.GetAsync<Model[]>(`${this.baseController}/Model/list`)).data;
  }

  public async UpdateCarClassModel(model: Model): Promise<void> {
    await API.PutAsync<Model>(`${this.baseController}/Model`, model);
  }

  public async FleetSearchVehicle(vin: string, modelId: number): Promise<VehicleDto[]> {
    return (
      await API.GetAsync<VehicleDto[]>(
        `${this.baseController}/fleet/search-vehicle?vin=${
          R.isNil(vin) ? '' : vin
        }&modelId=${modelId}`
      )
    ).data;
  }

  public async GetManipulationPercent(
    calculationRequest: CalculationRequest
  ): Promise<ManipulationTariffPercentResponse> {
    return (
      await API.Post2Async<CalculationRequest, ManipulationTariffPercentResponse>(
        `${this.baseController}/calculation/manipulation-percent`,
        calculationRequest
      )
    ).data;
  }

  public async CarfixAlternativeCalculation(
    request: CarfixAlternativeCalculationRequest
  ): Promise<CarfixAlternativeCalculationResponse> {
    return (
      await API.PostAsync<any>(`${this.baseController}/carfix/alternativeCalculation`, request)
    ).data;
  }

  public async CarfixAttachmentNotification(
    request: CarfixAttachmentNotificationRequest
  ): Promise<CarfixAttachmentNotificationResponse> {
    return (
      await API.PostAsync<any>(`${this.baseController}/carfix/attachmentNotification`, request)
    ).data;
  }

  public async AddCaseVehicleDetails(
    vehicleDetails: CaseVehicleDetails
  ): Promise<CaseVehicleDetails> {
    return (
      await API.PostAsync<CaseVehicleDetails>(
        `${this.baseController}/vehicle-details`,
        vehicleDetails
      )
    ).data;
  }

  public async UpdateCaseVehicleDetails(vehicleDetails: CaseVehicleDetails): Promise<void> {
    await API.PutAsync<CaseVehicleDetails>(
      `${this.baseController}/vehicle-details`,
      vehicleDetails
    );
  }

  public async GetCurrentVehicleDetails(appointmentId: number): Promise<CaseVehicleDetails> {
    return (
      await API.GetAsync<CaseVehicleDetails>(`${this.baseController}/vehicle-details/${appointmentId}`)
    ).data;
  }

  public async GetTariffPercent(
    request: TariffPercentRequest
  ): Promise<TariffPercentResponse> {
    return (
      await API.Post2Async<TariffPercentRequest, TariffPercentResponse>(
        `${this.baseController}/calculation/tariff-value-percent`,
        request
      )
    ).data;
  }

  public async MaintenanceProducerList(): Promise<MaintenanceProducer[]> {
    return (await API.GetAsync<MaintenanceProducer[]>(`${this.baseController}/MaintenanceProducer`))
      .data;
  }
  public async AddMaintenanceProducer(
    maintenanceProducer: MaintenanceProducer
  ): Promise<MaintenanceProducer> {
    return (
      await API.PostAsync<MaintenanceProducer>(
        `${this.baseController}/MaintenanceProducer`,
        maintenanceProducer
      )
    ).data;
  }
  public async RemoveMaintenanceProducer(id: number): Promise<void> {
    return (await API.DeleteAsync<void>(`${this.baseController}/MaintenanceProducer/${id}`)).data;
  }

  public async UpdateMaintenanceProducer(maintenanceProducer: MaintenanceProducer): Promise<void> {
    await API.PutAsync<MaintenanceProducer>(
      `${this.baseController}/MaintenanceProducer`,
      maintenanceProducer
    );
  }

  public async GetVehicleFromVQRepo(vin: string): Promise<VQVehicle> {
    return (await API.GetAsync<VQVehicle>(`${this.baseController}/vehicle/${vin}`)).data;
  }

  public async GetAppointmentByCaseId(caseId: number): Promise<Appointment> {
    return (
      await API.GetAsync<Appointment>(`${this.baseController}/appointmentByCaseId?caseId=${caseId}`)
    ).data;
  }

  public async GetAppointments(appoitmentFilters: AppointmentFilters): Promise<AppointmentList> {
    return (
      await API.Post2Async<AppointmentFilters, AppointmentList>(
        `${this.baseController}/appointment`,
        appoitmentFilters
      )
    ).data;
  }

  public async SetAppointmentNotNew(
    appointmentId: number,
    appointmentStatusHistId: number,
    moveToViewed: boolean,
    notNew: boolean
  ): Promise<void> {
    await API.PutAsync<any>(
      `${this.baseController}/appointment/${appointmentId}/IsNotNew/${appointmentStatusHistId}?moveToViewed=${moveToViewed}&notNew=${notNew}`,
      null
    );
  }

  public async GetGTWSBillingReport(gtWsBillingFilters: GTWSBillingFilters): Promise<Blob> {
    return (
      await API.PostFileAsync<GTWSBillingFilters, Blob>(
        `${this.baseController}/getApiGTWSLogReportExcel`,
        gtWsBillingFilters
      )
    ).data;
  }

  public async GetNonStockPartBillingReport(nonStockBillingFilters: NonStockBillingFilters): Promise<Blob> {
    return (
      await API.PostFileAsync<NonStockBillingFilters, Blob>(
        `${this.baseController}/non-stock-part-report`,
        nonStockBillingFilters
      )
    ).data;
  }

  public async GetLastApprovedCaseCalculationHistory(
    caseId: number,
    type: string
  ): Promise<CaseCalculationHistory> {
    return (
      await API.GetAsync<CaseCalculationHistory>(
        `${this.baseController}/lastcasecalculation/${caseId}/${type}`
      )
    ).data;
  }

  public async GetAppointmentByType(
    caseId: number, 
    appointmentCategoryTypeId: number
  ): Promise<Appointment> {
    return (
      await API.GetAsync<Appointment>(`${this.baseController}/appointment-by-type/${caseId}/${appointmentCategoryTypeId}`)
    ).data;
  }

  public async SetCarInService(
    caseId: number,
    carInService: boolean
  ): Promise<Case> {
    return (await API.PostAsync<any, any>(
      `${this.baseController}/carInService/${caseId}?carInService=${carInService}`,
      {}
    )).data;
  }

  public async GetCaseAppointmentAttachments(
    caseId: number,
    appointmentId: number,
    types: string[]
  ): Promise<CaseAttachment[]> {
    const idLIst = types.join(',');
    return (
      await API.GetAsync<CaseAttachment[]>(
        `${this.baseController}/${caseId}/${appointmentId}/caseAttachment/${idLIst}`
      )
    ).data;
  }

  public async CreateAutomateAppointment(
    request: AutomateAppointmentRequest
  ): Promise<CaseStatusHistory> {
    return (
      await API.PostAsync<AutomateAppointmentRequest, any>(
        `${this.baseController}/automate-appointment`,
        request
      )
    ).data;
  }

  public async GetAcquisitionCases(
    request: AcquisitionCaseRequest
  ): Promise<CaseResultDto[]> {
    return (
      await API.PostAsync<AcquisitionCaseRequest, any>(
        `${this.baseController}/acquisition-case`,
        request
      )
    ).data;
  }

  public async ChangeCaseStatusBasedCode(request: ChangeCaseStatusBasedCodeRequest): Promise<CaseStatusHistory> {
    return (
      await API.Post2Async<ChangeCaseStatusBasedCodeRequest, CaseStatusHistory>(
        `${this.baseController}/change-status`,
        request
      )
    ).data;
  }

  public async GenerateSmartBillInvoice(request: GenerateInvoiceRequest): Promise<GenerateInvoiceResponse> {
    return (
      await API.Post2Async<GenerateInvoiceRequest, GenerateInvoiceResponse>(
        `${this.baseController}/smartBill/generate-invoice`,
        request
      )
    ).data;
  }

  public async GetPartsHistoryInterogationReport(request: PartsHistoryInterogationRequest): Promise<Blob> {
    return (
      await API.PostFileAsync<PartsHistoryInterogationRequest, Blob>(
        `${this.baseController}/calculation/parts-history-interogation`,
        request
      )
    ).data;
  }

  public async UpdateOrderInfo(request: UpdateOrderInfoRequest): Promise<any> {
    return (
      await API.Post2Async<UpdateOrderInfoRequest, any>(
        `${this.baseController}/update-order`,
        request
      )
    ).data;
  }

  public async GetAppointmentPaymentStatus(request: AppointmentPaymentStatusRequest): Promise<AppointmentPaymentStatusResponse> {
    return (
      await API.Post2Async<AppointmentPaymentStatusRequest, AppointmentPaymentStatusResponse>(
        `${this.baseController}/appointment/payment-status`,
        request
      )
    ).data;
  }
}
